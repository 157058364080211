import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { newBrandBranchSelectors } from '../redux/newBrandBranch';
import { getCuisines, getDistributors } from '../services/newBrandBranch/checkBrandExistApiCall';
import { addBrandBranchForSalesApicall } from './../services/brandActionCenter/createBrandActionCenter';
import { success as successNotificationAction, error as errorNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from './../config';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import MultiSelect from "react-multi-select-component";

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    cancelText = "Cancel",
    centered = false,
    modalData
}) => {

    const dispatch = useDispatch();
    const [brandDetailList, setBrandDetailList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const getDistributorsState = useSelector(newBrandBranchSelectors.getDistributorsState);
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [fileType, setFileType] = useState("");
    const [invalidDetail, setInvalidDetail] = useState({});
    const [allCuisinesList, setAllCuisinesList] = useState([]);
    const [brandCommonDetails, setBrandCommonDetails] = useState({
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        emailAddresses: "",
        comments: ""
    });
    let staticObj = {
        brandName: "",
        address: "",
        website: "",
        branchList: [
            {
                branch_name: "",
                amexMerchantID: "",
                mastercardMerchantID: "",
                postcode: "",
                address: "",
                cuisines_list: "",
                copy: ""
            }
        ]
    };

    const [overrideStrings, setOverrideStrings] = useState({
        "selectSomeItems": "Select Publisher",
        "allItemsAreSelected": "All Publisher are selected.",
        "selectAll": "All Publisher",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    useEffect(() => { 
        dispatch(getCuisines(response => { 
            setAllCuisinesList(response);
        }));
    }, []);

    const customValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Publisher selected" : "Select Publisher"
        }
    };

    const [overrideCuisinesStrings, setOverrideCuisinesStrings] = useState({
        "selectSomeItems": "Select Cuisines",
        "allItemsAreSelected": "All Cuisines are selected.",
        "selectAll": "All Cuisines",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const customValueCuisinesRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple cuisine selected" : "Select Cuisine"
        }
    };

    useEffect(() => {

        if (modalData.fromPage == "Edit" && modalData.brandDetailBySales) {
           
            let detail = JSON.parse(modalData.brandDetailBySales);
            detail.brandDetailList.forEach(e1 => {
                e1.offerStartDate = new Date(e1.offerStartDate);
                e1.offerEndDate = new Date(e1.offerEndDate);
                if(e1.selectedPublisher) {
                    e1.selectedPublisher.forEach(e2 => {
                        e2.startDate = new Date(e2.startDate);
                    });
                }
            });
            setBrandDetailList(detail.brandDetailList);
            // if (detail.commonDetails.offerStartDate) {
            //     let date = detail.commonDetails.offerStartDate.split("-");
            //     detail.commonDetails.offerStartDate = new Date(date[2] + "-" + date[1] + "-" + date[0]);
            // }
            // if (detail.commonDetails.offerEndDate) {
            //     let date = detail.commonDetails.offerEndDate.split("-");
            //     detail.commonDetails.offerEndDate = new Date(date[2] + "-" + date[1] + "-" + date[0]);
            // }
            if (!detail.commonDetails.fileType) {
                setFileType("unformatted");
            } else {
                setFileType(detail.commonDetails.fileType);
            }

            setBrandCommonDetails(detail.commonDetails);
        } else {
            let list = [staticObj];
            if (modalData.uploadFor == "brand") {
                list[0].brandName = modalData.brandName;
            }
            setBrandDetailList(list);
            setFileType("unformatted");
        }
        dispatch(getDistributors());
    }, []);

    const addBrand = () => {
        let list = JSON.parse(JSON.stringify(brandDetailList))
        list.push(staticObj);
        setBrandDetailList(list);
    }

    const deleteBrand = (index) => {
        let list = JSON.parse(JSON.stringify(brandDetailList))
        list.splice(index, 1);
        setBrandDetailList(list);
    }

    const addBranch = (index) => {
        let list = JSON.parse(JSON.stringify(brandDetailList));
        list[index].branchList.push({
            branch_name: "",
            amexMerchantID: "",
            mastercardMerchantID: "",
            postcode: "",
            address: "",
            cuisines_list: "",
            copy : ""
        });
        setBrandDetailList(list);
    }

    const deleteBranch = (index, subIndex) => {
        let list = JSON.parse(JSON.stringify(brandDetailList));
        list[index].branchList.splice(subIndex, 1);
        setBrandDetailList(list);
    }

    useEffect(() => {
        if (getDistributorsState.data && getDistributorsState.data.distributors) {
            getDistributorsState.data.distributors.forEach(e2 => {
                // if (brandCommonDetails.selectedPublisher && brandCommonDetails.selectedPublisher.includes(e2.distributors_name)) {
                //     e2.checked = true;
                // }
                // if (brandCommonDetails.distributorDealSetWithBrand) {
                //     brandCommonDetails.distributorDealSetWithBrand.forEach(e3 => {
                //         if (e3.distributors_name == e2.distributors_name) {
                //             e2.successFee = e3.successFee;
                //             e2.percentage = e3.percentage;
                //             e2.startDate = e3.startDate ? new Date(e3.startDate) : "";
                //         }
                //     });
                // }
                e2.label = e2.distributors_display_name;
                e2.value = e2.distributors_name;
            });
            setDistributorList(getDistributorsState.data.distributors);
        }
    }, [getDistributorsState.data]);

    const updatePublisher = (index, value) => {
        let temp = JSON.parse(JSON.stringify(distributorList));
        temp[index].checked = value;
        setDistributorList(temp);
    }

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
    }

    const clearErrorValue = e => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const removeSelectedFile = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const checkBranchLastIndex = (index) => {
        let flg = true;
        if (brandDetailList[index] && brandDetailList[index].branchList && brandDetailList[index].branchList.length == 1) {
            flg = false;
        }
        return flg;
    }

    const updateCommonValues = (Field, value) => {
        let values = brandCommonDetails;
        values = { ...values, [Field]: value };
        setBrandCommonDetails(values);
    }

    const updateBrandList = (Field, index, value) => {
        const data1 = [...brandDetailList]
        let data2 = { ...data1[index], [Field]: value };
        data1[index] = data2;
        setBrandDetailList(data1);
    }

    const updateBranchList = (Field, index, subIndex, value) => {
        const data1 = [...brandDetailList];
        if (data1 && data1[index] && data1[index].branchList && data1[index].branchList[subIndex]) {
            data1[index].branchList[subIndex][Field] = value;
        }
        setBrandDetailList(data1);
    }

    const handleDistributorValue = (value, index, subIndex, Field, ctrlType) => {
        const re = /^[0-9.\b]+$/;
        if (ctrlType == "date" || value === '' || (re.test(value) && parseInt(value) <= 100)) {
            const data1 = [...brandDetailList];
            let data2 = { ...data1[index].selectedPublisher[subIndex], [Field]: value };
            data1[index].selectedPublisher[subIndex] = data2;
            setBrandDetailList(data1);
        }
    };

    const saveBrandBranchBySales = async () => {
        var formdata = new FormData();
        formdata.append('excelData', selectedFile);
        formdata.append('brandName', modalData.brandName);
        formdata.append('comments', brandCommonDetails.comments);
        formdata.append('uploadFor', modalData.uploadFor);
        formdata.append('fileType', fileType);
        if (modalData._id) {
            formdata.append('id', modalData._id);
        }
        let brandDetailBySales = {};
        brandDetailBySales.commonDetails = brandCommonDetails;
        brandDetailBySales.commonDetails.fileType = fileType;  
        brandDetailBySales.brandDetailList = brandDetailList; 
        formdata.append('brandDetailBySales', JSON.stringify(brandDetailBySales));
        setIsLoader(true);
        dispatch(addBrandBranchForSalesApicall(formdata, response => {
            setIsLoader(false);
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = response.message;
                dispatch(successNotificationAction(notification));
                callback();
            } else if (response.errorCode) {
                setInvalidDetail(response);
                let notification = { ...notificationOpts };
                notification.message = "Invalid file submitted";
                dispatch(errorNotificationAction(notification));
            }
        }));
    }


    return (
        <>
            <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                <ModalComponent.Header closeButton>
                    <ModalComponent.Title>
                        Add Brand/Branch
                    </ModalComponent.Title>
                </ModalComponent.Header>
                <ModalComponent.Body>

                    <div className="row col-12 form add-brandBranchBySales-modal">

                        {isLoader && <div className="loading">Loading...</div>}

                        {brandDetailList.map((ele, index) =>
                            <React.Fragment>
                                <div key={index + "brand"} className="row col-12 m-t-20 m-l-20 brandbox">
                                    <div className="row col-12">
                                        <div className="col-12 m-t-10 brandtitle">
                                            <span> Brand Details  </span>
                                            {modalData.uploadFor == "group" &&
                                                <span className="addBrandIcon future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                    onClick={() => addBrand()}  >
                                                    <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Edit">add_circle</Icon>
                                                </span>
                                            }
                                        </div>

                                        <div className="row col-11">
                                            <div className="col-4 m-t-10">
                                                <FormControl className='w-100' variant="standard">
                                                    <TextField
                                                        id='brandName'
                                                        name='brandName'
                                                        value={ele.brandName}
                                                        type="text" label="Restaurant Name"
                                                        onChange={e => { updateBrandList("brandName", index, e.target.value) }}
                                                        className={`form-control mb-3`} />
                                                </FormControl>
                                            </div>

                                            <div className="col-4 m-t-10">
                                                <FormControl className='w-100' variant="standard">
                                                    <TextField
                                                        id='address'
                                                        name='address'
                                                        value={ele.address}
                                                        type="text" label="Restaurant Address"
                                                        onChange={e => { updateBrandList("address", index, e.target.value) }}
                                                        className={`form-control mb-3`} />
                                                </FormControl>
                                            </div>

                                            <div className="col-4 m-t-10">
                                                <FormControl className='w-100' variant="standard">
                                                    <TextField
                                                        id='website'
                                                        name='website'
                                                        value={ele.website}
                                                        type="text" label="Website"
                                                        onChange={e => { updateBrandList("website", index, e.target.value) }}
                                                        className={`form-control mb-3`} />
                                                </FormControl>
                                            </div>

                                            {/* <div className="col-4 m-t-10">
                                                <div className="form-group next-upload-date">
                                                    <label>Offer Start Date</label> <br />
                                                    {ele.offerStartDate && <span className='date-value'>
                                                        {moment(ele.offerStartDate).format('YYYY-MM-DD').toString()}</span>}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            inputVariant="outlined"
                                                            inputFormat="yyyy-MM-dd"
                                                            views={['year', 'month', 'date']}
                                                            value={ele.offerStartDate}
                                                            InputAdornmentProps={{
                                                                position: "start"
                                                            }}
                                                            onChange={(newValue, e) => {
                                                                updateBrandList("offerStartDate", index, newValue)
                                                            }}>
                                                        </KeyboardDatePicker>
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>

                                            <div className="col-4 m-t-10">
                                                <div className="form-group next-upload-date">
                                                    <label>Offer End Date</label> <br />
                                                    {ele.offerEndDate && <span className='date-value'>
                                                        {moment(ele.offerEndDate).format('YYYY-MM-DD').toString()}</span>}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            inputVariant="outlined"
                                                            inputFormat="yyyy-MM-dd"
                                                            views={['year', 'month', 'date']}
                                                            value={ele.offerEndDate}
                                                            InputAdornmentProps={{
                                                                position: "start"
                                                            }}
                                                            onChange={(newValue, e) => {
                                                                updateBrandList("offerEndDate", index, newValue)
                                                            }}>
                                                        </KeyboardDatePicker>
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div> */}

                                            <div className="d-flex col-4 m-t-10">
                                                <div className="form-group pl-0 mb-0 w-100 dropdown-1-1">
                                                    <label>Select Publisher</label>
                                                    <MultiSelect
                                                        className='multi-select'
                                                        options={distributorList || []}
                                                        value={ele.selectedPublisher || []}
                                                        onChange={(value) => {
                                                            updateBrandList("selectedPublisher", index, value);
                                                        }}
                                                        labelledBy={"Select Publisher"}
                                                        overrideStrings={overrideStrings}
                                                        valueRenderer={customValueRenderer} />
                                                </div>
                                            </div>

                                            {ele.selectedPublisher &&
                                                <div className="row col-12 mt-3 brand-d-s">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <td> Distributor name </td>
                                                                <td> Cashback % </td>
                                                                <td> Success Fee % </td>
                                                                <td> Start date </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ele.selectedPublisher.map((row, subIndex) => {
                                                                return (
                                                                    <tr key={subIndex + "distributors"} className="distributors-row">
                                                                        <td className="p-t-10"> {row.value}  </td>
                                                                        <td>
                                                                            <FormControl className='w-100' variant="standard">
                                                                                <TextField
                                                                                    value={row.percentage}
                                                                                    id={`distributors_name${subIndex}`}
                                                                                    name={`distributors_name${subIndex}`}
                                                                                    type="text"
                                                                                    className={`form-control mb-3`}
                                                                                    onChange={e => handleDistributorValue(e.target.value, index, subIndex, 'percentage', 'textBox')}
                                                                                />
                                                                            </FormControl>
                                                                        </td>
                                                                        <td>
                                                                            <FormControl className='w-100' variant="standard">
                                                                                <TextField
                                                                                    value={row.successFee}
                                                                                    id={`successFee${subIndex}`}
                                                                                    name={`successFee${subIndex}`}
                                                                                    type="text"
                                                                                    className={`form-control mb-3`}
                                                                                    onChange={e => handleDistributorValue(e.target.value, index, subIndex, 'successFee', 'textBox')}
                                                                                />
                                                                            </FormControl>
                                                                        </td>
                                                                        <td className='date-rang-box'>
                                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                <DatePicker label="From"
                                                                                    value={row.startDate}
                                                                                    onChange={(newValue) => handleDistributorValue(newValue, index, subIndex, 'startDate', 'date')}
                                                                                    format="dd-MM-yyyy"
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-1">
                                            {brandDetailList.length != 1 &&
                                                <span className="future-action p-t-20" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                    onClick={() => deleteBrand(index)}>
                                                    <Icon style={{ fontSize: "2rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                </span>
                                            }
                                        </div>

                                    </div>

                                    {ele.branchList &&
                                        <div className="row col-12 m-t-10">
                                            <div className="col-12 m-t-10 brandtitle">
                                                <span> Branch Details </span>
                                                <span className="addBrandIcon future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                    onClick={() => addBranch(index)} >
                                                    <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Edit">add_circle</Icon>
                                                </span>
                                            </div>

                                            {ele.branchList.map((ele2, index2) =>
                                                <React.Fragment>
                                                    <div key={index + index2 + "brandBranch"} className="row col-12 branchbox">
                                                        <div className="row col-11">

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='branch_name'
                                                                        name='branch_name'
                                                                        value={ele2.branch_name}
                                                                        type="text" label="Branch Name"
                                                                        onChange={e => { updateBranchList("branch_name", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='amexMerchantID'
                                                                        name='amexMerchantID'
                                                                        value={ele2.amexMerchantID}
                                                                        type="text" label="Amex MID"
                                                                        onChange={e => { updateBranchList("amexMerchantID", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='mastercardMerchantID'
                                                                        name='mastercardMerchantID'
                                                                        value={ele2.mastercardMerchantID}
                                                                        type="text" label="MasterCard/Visa MID"
                                                                        onChange={e => { updateBranchList("mastercardMerchantID", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='address'
                                                                        name='address'
                                                                        value={ele2.address}
                                                                        type="text" label="Restaurant Address"
                                                                        onChange={e => { updateBranchList("address", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='postcode'
                                                                        name='postcode'
                                                                        value={ele2.postcode}
                                                                        type="text" label="Post Code"
                                                                        onChange={e => { updateBranchList("postcode", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>

                                                            <div className="p-2 col-4 input-group justify-content-center dropdown-1-1"> 

                                                                <label>Select Cuisines  </label>
                                                                <MultiSelect
                                                                    className='multi-select'
                                                                    options={allCuisinesList}
                                                                    value={ele2.cuisines_list || []}
                                                                    onChange={(value) => {
                                                                        updateBranchList("cuisines_list", index, index2, value);
                                                                    }}
                                                                    labelledBy={"Select Cuisines"} required={true}
                                                                    overrideStrings={overrideCuisinesStrings}
                                                                    valueRenderer={customValueCuisinesRenderer} />
                                                            </div> 

                                                            <div className="col-4 m-t-10">
                                                                <FormControl className='w-100' variant="standard">
                                                                    <TextField
                                                                        id='copy'
                                                                        name='copy'
                                                                        value={ele2.copy}
                                                                        type="text" label="Copy"
                                                                        onChange={e => { updateBranchList("copy", index, index2, e.target.value) }}
                                                                        className={`form-control mb-3`} />
                                                                </FormControl>
                                                            </div>


                                                        </div>
                                                        <div className="col-1">
                                                            {checkBranchLastIndex(index) &&
                                                                <span className="future-action p-t-20" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                                    onClick={() => deleteBranch(index, index2)}>
                                                                    <Icon style={{ fontSize: "2rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                                </span>
                                                            }
                                                        </div>


                                                    </div>
                                                </React.Fragment>
                                            )}

                                        </div>
                                    }


                                </div>
                            </React.Fragment>
                        )}

                        <div className="row col-12 m-l-20">

                            <div className="col-4 m-t-10">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='contactName'
                                        name='contactName'
                                        value={brandCommonDetails.contactName}
                                        type="text" label="Contact Name"
                                        onChange={e => { updateCommonValues("contactName", e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div>

                            <div className="col-4 m-t-10">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='contactEmail'
                                        name='contactEmail'
                                        value={brandCommonDetails.contactEmail}
                                        type="text" label="Contact Email"
                                        onChange={e => { updateCommonValues("contactEmail", e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div>

                            <div className="col-4 m-t-10">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='contactPhone'
                                        name='contactPhone'
                                        value={brandCommonDetails.contactPhone}
                                        type="text" label="Contact Phone"
                                        onChange={e => { updateCommonValues("contactPhone", e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div> 

                        </div>

                        <div className="row col-12 m-t-10 m-l-10 bulk-brand-upload-modal">

                            {/* <div className="col-12 m-t-10 brandtitle">
                                <span> Upload File  </span>
                            </div> */}

                            <div className="col-4">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='comments'
                                        name='comments'
                                        value={brandCommonDetails.comments}
                                        type="text" label="Comments"
                                        onChange={e => { updateCommonValues("comments", e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div>

                            {fileType &&
                                <div className="col-8 group-brand-rbt">
                                    <FormControl>
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Upload for</FormLabel> */}
                                        <RadioGroup row
                                            defaultValue={fileType}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group" onChange={(e) => { updateCommonValues("fileType", e.target.value) }} >
                                            <FormControlLabel value="unformatted" control={<Radio />} label="Unformatted" />
                                            <FormControlLabel value="formatted" control={<Radio />} label="Formatted" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            }

                            <div className="col-2 p-t-15">
                                <div className="bulk-upload-btn-wrapper">
                                    <button className="btn btn-primary">
                                        Upload
                                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                                    </button>
                                    <input
                                        name="bulk-upload"
                                        id="bulk-upload"
                                        ref={inputRef}
                                        accept=".xls,.xlsx"
                                        type='file'
                                        onClick={clearErrorValue}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>

                            {selectedFile && selectedFile.name &&
                                <div className="col-6 file-name p-t-15">
                                    <div className='file-label'> <span>{selectedFile.name}</span> </div>
                                    <div className='file-delete' onClick={removeSelectedFile} >
                                        <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                    </div>
                                </div>
                            }

                            {invalidDetail && invalidDetail.errorCode && invalidDetail.data && invalidDetail.isInValid &&
                                <>
                                    <div className="col-12 invalid-title">
                                        Invalid details
                                    </div>

                                    {Object.keys(invalidDetail.data).map((a, index) => {

                                        return (
                                            <div className="col-12 invalid-file" key={index + "invalidData"}>
                                                {invalidDetail.data[a]}
                                            </div>
                                        )
                                    })}

                                </>
                            }

                            {invalidDetail && (!invalidDetail.data || Object.keys(invalidDetail.data).length == 0) && invalidDetail.errorCode &&
                                <div className="col-12 invalid-file">
                                    {invalidDetail.errorMessage}
                                </div>
                            }

                        </div>
                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button variant="default" onClick={close}>
                        {cancelText}
                    </Button>
                    <Button variant="success" onClick={() => { saveBrandBranchBySales() }} >
                        Save
                    </Button>
                </ModalComponent.Footer>
            </ModalComponent>


        </>
    )
}

export default Modal