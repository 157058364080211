import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from "xlsx";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Icon from '@material-ui/core/Icon';
import { Field, Form } from 'formik';
import {
    CreateActionCenterApicall, GetActionCenterApicall, DeleteActionCenterApicall,
    submitUploadedFileApicall, updateBrandIdApicall, getCAMLinkDetailsAction,
    addBrandBranchFromActionCenterAction, releaseBrandBranchAction,
    getActionCenterAnalytics, exportAmexPhaseBrand, skipCampaignStepApiCall
} from '../../services/brandActionCenter/createBrandActionCenter';
import { brandActionCenterSelectors } from '../../redux/brandActionCenter';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import { CREATE_ACTIONS_CENTER } from '../../redux/brandActionCenter/actionTypes';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ModalComponent from '../../components/Modal'
import { GetSessionValue } from '../../utils/sessionStorage';
import ReBrandUploadModal from '../../components/ReBrandUploadModal';
import EditActionCenterModal from '../../components/EditActionCenterModal';
import AddBrandBranchBySalesModal from '../../components/AddBrandBranchBySalesModal';
import ActionCenterActivitiesModal from '../../components/ActionCenterActivitiesModal';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import axiosMethodRequest from '../../config/service';
import { CreateBranchApiCall } from '../../services/newBrandBranch/createBranchApiCall';
import { createUserBulkUploadApiCall, createGroupUserAction, createGroupAction } from '../../services/newBrandBranch/createUserApiCall';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
    generateId, groupColumnList, brandWithoutGroup, brandWithGroup, branchesColumnList, usersColumnList,
    legendColumnForBrand, legendColumnForGroup, whiteLabelBrandColumnList, whiteLabelBranchColumnList
} from '../../utils/helpers';
import config from '../../config/config';
import moment from 'moment';
import { getAllDistributor, getWhitelabels } from '../../services/newBrandBranch/checkBrandExistApiCall';

export default function BrandActionCenter() {

    const [comments, setComments] = useState("");
    const [brandName, setBrandName] = useState("");
    const [uploadFor, setUploadFor] = useState("group");
    const [uploadForSales, setUploadForSales] = useState("group");
    const [brandNameForSales, setBrandNameForSales] = useState("");
    const [userDetails, setUserDetails] = useState({});
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [brandActionList, setBrandActionList] = useState([]);
    const [deleteModalData, setDeleteModalData] = useState({ showDeleteModal: false, selectedEntity: {} });
    const [confirmUploadData, setConfirmUploadData] = useState({ showDeleteModal: false, selectedEntity: {} });
    const [showCommentsData, setShowCommentsData] = useState({ showCommentsModal: false, selectedEntity: {} });
    const [brandErrorModal, setBrandErrorModal] = useState({ showBrandErrorModal: false, selectedEntity: {}, response: {} });
    const [publisherOnboardedModal, setPublisherOnboardedModal] = useState({ showPublisherOnboardedModalModal: false, selectedEntity: {} });
    const [uploadBrandData, setUploadBrandData] = useState({});
    const [editActionCenterData, setEditActionCenterData] = useState({ showEditActionCenterModal: false, selectedData: {} });
    const [sendMail, setSendMail] = useState({ showSendMailModal: false, selectedData: {} });
    const [actionCenterActivitiesData, setActionCenterActivitiesData] = useState({ showActionCenterActivitiesModal: false, selectedData: {} });
    const [searchField, setSearchField] = useState("");
    const [analytics, setAnalytics] = useState({});
    const [distributorList, setDistributorList] = useState([]);
    const [tempDistributorList, setTempDistributorList] = useState([]);
    const [exportSelectedPublisher, setExportSelectedPublisher] = useState("All");
    const [exportSelectedWhiteLabel, setExportSelectedWhiteLabel] = useState("");
    const [addBranchBranchBySalesData, setAddBranchBranchBySalesData] = useState({ showAddBranchBranchBySalesDataModal: false, selectedData: {} });
    
    const [selectedAmexPhase, setSelectedAmexPhase] = useState("All");
    const [whitelabels, setWhitelabels] = useState([]);

    let brandCameFrom = "";
    let uploadedDataDetail = {};
    const [pageAndLimitValue, setPageAndLimitValue] = useState({
        limit: 10,
        page: 1,
        totalCount: 10
    });
    const [pagination, setPagination] = useState();
    const dispatch = useDispatch();

    const createActionCenterState = useSelector(
        brandActionCenterSelectors.createActionCenterState
    );

    const getBrandActionCenterState = useSelector(
        brandActionCenterSelectors.getBrandActionCenterState
    );

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
    }

    const clearErrorValue = e => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const removeSelectedFile = () => {
        // id="bulk-upload"
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
        clearData();
    }

    const downloadTemplate = () => {

        const workbook = XLSX.utils.book_new();
        if (uploadFor == "group") {
            let groupColumn = groupColumnList;
            const sheet0 = XLSX.utils.json_to_sheet(groupColumn);
            XLSX.utils.book_append_sheet(workbook, sheet0, "Group");
        }

        let brandsColumn = [];
        if (uploadFor == "brand") {
            brandsColumn = brandWithoutGroup;
        } else {
            brandsColumn = brandWithGroup;
        }

        tempDistributorList.forEach(e3 => {
            brandsColumn[0][e3.distributors_display_name] = "No";
            brandsColumn[0][e3.distributors_display_name + " Cashback"] = "";
            brandsColumn[0][e3.distributors_display_name + " SuccessFee"] = "";
            brandsColumn[0][e3.distributors_display_name + " Start Date"] = "";
        });

        const sheet = XLSX.utils.json_to_sheet(brandsColumn);
        XLSX.utils.book_append_sheet(workbook, sheet, "Brand");
        console.log("XLSX.utils", XLSX.utils);

        const branchesColumn = branchesColumnList;
        const sheet2 = XLSX.utils.json_to_sheet(branchesColumn);
        XLSX.utils.book_append_sheet(workbook, sheet2, "Branch");

        if (whitelabels && whitelabels.length > 0) {
            let list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBrandColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4)
            });
            const sheet6 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBranchColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4);
            });
            const sheet7 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");

        } else {
            const whiteLabelBrandColumn = whiteLabelBrandColumnList;
            const sheet6 = XLSX.utils.json_to_sheet(whiteLabelBrandColumn);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            const whiteLabelBranchColumn = whiteLabelBranchColumnList;
            const sheet7 = XLSX.utils.json_to_sheet(whiteLabelBranchColumn);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");
        }

        if (uploadFor == "brand") {
            const usersColumn = usersColumnList;
            const sheet3 = XLSX.utils.json_to_sheet(usersColumn);
            XLSX.utils.book_append_sheet(workbook, sheet3, "User");
        }

        let legendColumn = [];
        if (uploadFor == "brand") {
            legendColumn = legendColumnForBrand;
        } else {
            legendColumn = legendColumnForGroup;
        }

        const sheet4 = XLSX.utils.json_to_sheet(legendColumn);
        XLSX.utils.book_append_sheet(workbook, sheet4, "Legend");
        XLSX.writeFile(workbook, new Date().getTime() + "-" + uploadFor + "-BulkUploadSample.xlsx");
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group pt-1" role="group">
            <div className="dropdown">
                <button className="btn btn-primary dropbtn">{pageAndLimitValue.limit}  <ExpandMoreIcon /></button>
                <div className="dropdown-content">
                    {
                        options.map((option, index) => {
                            const isSelect = currSizePerPage === `${option.page}`;
                            return (
                                <a href="#" onClick={() => setPageLimit(option.page)} key={index}> {option.text}</a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        let userDetails = GetSessionValue('user');
        setUserDetails(userDetails);
        getActionCenter(true);
        dispatch(getAllDistributor(response => {
            if (response && response.length > 0) {
                setTempDistributorList(response);
                response = [{ distributors_name: "All", distributors_display_name: "All" }].concat(response);
                setDistributorList(response);
            }
        }));

        dispatch(getWhitelabels(response => {
            setWhitelabels(response);
        }));
    }, []);

    const getActionCenter = (showLoader, searchField) => {
        searchField = searchField || "";
        let filterCriteria = { limit: pageAndLimitValue.limit, page: pageAndLimitValue.page, searchField: searchField };
        if (showLoader) { setIsLoader(true); }
        dispatch(GetActionCenterApicall(filterCriteria));

        let userDetails = GetSessionValue('user');
        if ((userDetails.role == "opsAdmin" || userDetails.role == "superAdmin") && !searchField) {
            dispatch(getActionCenterAnalytics(response => {
                if (response.respCode && response.analytics) {
                    setAnalytics(response.analytics);
                }
            }));
        }
    }

    const pageChange = (page) => {
        let pageData = pageAndLimitValue;
        pageData.page = page
        setPageAndLimitValue(pageData);
        getActionCenter(true);
    }

    const setPageLimit = (limit) => {
        let pageData = pageAndLimitValue;
        pageData.limit = limit
        setPageAndLimitValue(pageData);
        getActionCenter(true);
    }

    useEffect(() => {
        if (getBrandActionCenterState && getBrandActionCenterState.data.length) {
            setBrandActionList(getBrandActionCenterState.data);
            setPageAndLimitValue(getBrandActionCenterState.pagination);
            setPagination(paginationFactory(
                {
                    sizePerPageRenderer: sizePerPageRenderer,
                    page: getBrandActionCenterState.pagination.page,
                    sizePerPage: getBrandActionCenterState.pagination.limit,
                    totalSize: getBrandActionCenterState.pagination.totalCount,
                    hideSizePerPage: false,
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    alwaysShowAllBtns: true,
                    show: true,
                    onPageChange: (page, sizePerPage) => {
                        pageChange(page)
                    }
                }
            ));
            setIsLoader(false);
        } else if (getBrandActionCenterState && getBrandActionCenterState.data.length == 0) {
            setIsLoader(false);
        }

        return () => {
            setBrandActionList([]);
        };

    }, [getBrandActionCenterState && getBrandActionCenterState.data]);

    const saveUploadedFile = async () => {
        var formdata = new FormData();
        formdata.append('excelData', selectedFile);
        formdata.append('brandName', brandName);
        formdata.append('comments', comments);
        formdata.append('role', userDetails.role);
        formdata.append('uploadFor', uploadFor);
        setIsLoader(true);
        dispatch(CreateActionCenterApicall(formdata, CREATE_ACTIONS_CENTER));
    }

    useEffect(() => {

        if (createActionCenterState && createActionCenterState.data && createActionCenterState.data.respCode) {
            // let notification = { ...notificationOpts };
            // notification.message = createActionCenterState.data.message;
            // dispatch(successNotificationAction(notification));
            setBrandName("");
            setComments("");
            removeSelectedFile();
            clearData();
            // setPageAndLimitValue({ limit: 10, page: 1, totalCount: 10 });
            // getActionCenter(true); 
            setConfirmUploadData({ showUploadConfirmModal: false, selectedEntity: createActionCenterState.data.data });
            uploadedDataDetail = createActionCenterState.data.data;
            submitUploadedFile(createActionCenterState.data.data, "opsAdmin");
        } else if (createActionCenterState.error && createActionCenterState.error.errorCode) {
            setIsLoader(false);
            let notification = { ...notificationOpts };
            notification.message = "Invalid file submitted";
            dispatch(errorNotificationAction(notification));
        }
    }, [createActionCenterState && createActionCenterState.data]);

    const clearData = () => {
        dispatch({
            type: CREATE_ACTIONS_CENTER,
            payload: { data: {}, error: {}, loader: true }
        })
    }

    const setShowDeleteModal = (selectedEntity) => {
        setDeleteModalData({ showDeleteModal: true, selectedEntity });
    }

    const clearDeleteModal = () => {
        setDeleteModalData({ showDeleteModal: false, selectedEntity: {} });
    }

    const handleDeleteEntity = () => {
        dispatch(DeleteActionCenterApicall(deleteModalData.selectedEntity._id, response => {
            let notification = { ...notificationOpts };
            if (response.respCode == 200) {
                notification.message = response.message;
                dispatch(successNotificationAction(notification));
                setPageAndLimitValue({ limit: 10, page: 1, totalCount: 10 });
                getActionCenter(true);
                clearDeleteModal();
            } else {
                notification.message = response.errorMessage;
                dispatch(errorNotificationAction(notification));
                clearDeleteModal();
            }
        }));
    }

    const openCommentsModal = (selectedEntity) => {
        setShowCommentsData({ showCommentsModal: true, selectedEntity: selectedEntity });
    }

    const openUploadConfirmModal = (selectedEntity) => {
        setConfirmUploadData({ showUploadConfirmModal: true, selectedEntity });
    }

    let uploadGroups = {};
    const submitUploadedFile = (row, from) => {
        setConfirmUploadData({ showUploadConfirmModal: false, selectedEntity: row })
        setIsLoader(true);

        brandCameFrom = from;
        dispatch(submitUploadedFileApicall(row._id, response => {
            if (response.respCode) {
                uploadGroups = {
                    dataUploadFor: row.uploadFor,
                    list: response.groupDetails
                }
                setUploadBrandData(response);
                createBrand(response.matchedData, 0);
            } else if (response.errorCode) {
                setIsLoader(false);
                let notification = { ...notificationOpts };
                notification.message = "Invalid file submitted";
                dispatch(errorNotificationAction(notification));
                setBrandErrorModal({ showBrandErrorModal: true, selectedEntity: row, response: response });
            }
        }));
    }

    const reviewAndUploadFile = (row) => {
        setConfirmUploadData({ showUploadConfirmModal: false, selectedEntity: row })
        setBrandErrorModal({ showBrandErrorModal: true, selectedEntity: row, response: { errorCode: "900", isInValid: true, data: {} } });
    }

    const closeBrandErrorModal = () => {
        setBrandErrorModal({ showBrandErrorModal: false, selectedEntity: {}, response: {} });
    }

    const openPublisherOnboardedModal = (selectedEntity) => {
        setPublisherOnboardedModal({ showPublisherOnboardedModalModal: true, selectedEntity: selectedEntity });
    }

    const createBrand = async (matchedData, createBrandIndex) => {
        setIsLoader(true);
        let element = matchedData[createBrandIndex];

        if (typeof element.brandDetails["Brand Name"] == "number") {
            element.brandDetails["Brand Name"] = element.brandDetails["Brand Name"].toString();
        }

        let body = {
            "brandname": element.brandDetails["Brand Name"] || "",
            "name": element.brandDetails["Brand Name"] || "",
            "companyName": element.brandDetails["Company Name"] || "",
            "website": element.brandDetails["Website URL"] || "",
            "registrationId": "",
            "registeredAddress": element.brandDetails["Address"] || "",
            "description": element.brandDetails["Description"] || "",
            "sector_id": '16',
            "cuisines_list": [],
            "brand_id": "",
            "created_by": "ui",
            "isNew": true,
            "entity_url": "",
            // tp_rank: element.brandDetails["TP Rank"] || ""
            comments: element.brandDetails["Comments"],
            payment_terms_days: element.brandDetails["Payment Terms Days"]
        };

        if (element.brandDetails["Invoice Preference Level 1"] == "Group") {
            body.invoice_preference = "group";
        } else if (element.brandDetails["Invoice Preference Level 1"] == "Brand") {
            body.invoice_preference = "brand";
        }

        if (element.brandDetails["Invoice Preference Level 2"] == "Brand") {
            body.invoice_preference_level_2 = "brand";
        } else if (element.brandDetails["Invoice Preference Level 2"] == "Branch") {
            body.invoice_preference_level_2 = "branch";
        } else if (element.brandDetails["Invoice Preference Level 2"] == "Publisher") {
            body.invoice_preference_level_2 = "publisher";
        }

        if (element.brandDetails["Separate Invoice"] == "Brand" || element.brandDetails["Separate Invoice"] == "Publisher") {
            body.separateInvoice = "Yes";
        } else {
            body.separateInvoice = "No";
        }

        if (element.brandDetails["Auto Debit"] == "Optional (1%)") {
            body.isAutoDebit = "true";
        } else if (element.brandDetails["Auto Debit"] == "Optional") {
            body.isAutoDebit = "false";
        } else if (element.brandDetails["Auto Debit"] == "Mandatory") {
            body.isAutoDebit = "Mandatory";
        }

        let selectedPublisher = [];
        let distributorDealSetWithBrand = [];

        tempDistributorList.forEach(e8 => {
            if (element.brandDetails[e8.distributors_display_name] == "Yes" && !selectedPublisher.includes(e8.distributors_name)) {
                selectedPublisher.push(e8.distributors_name);
                distributorDealSetWithBrand.push({
                    "distributors_name": e8.distributors_name,
                    "successFee": element.brandDetails[e8.distributors_display_name + " SuccessFee"],
                    "percentage": element.brandDetails[e8.distributors_display_name + " Cashback"],
                    "startDate": element.brandDetails[e8.distributors_display_name + " Start Date"]
                });
            }
        });

        body.selectedPublisher = selectedPublisher.join(",");
        body.distributorDealSetWithBrand = distributorDealSetWithBrand;

        let x_api_key = "1t02ARoFco80EpL5r3MqQ1qypxwI8qMO7iRhFpi5";
        let url = "https://t2zociuop4.execute-api.eu-west-2.amazonaws.com/default/create_brand_node";
        let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);

        if (response && response.data && response.data[0]) {
            body.brand_id = response.data[0].brand_id;
            body.brandId = response.data[0].brand_id;
            if (uploadGroups.dataUploadFor == "brand") {
                let reqData = {
                    _id: confirmUploadData.selectedEntity._id || uploadedDataDetail._id,
                    brandId: response.data[0].brand_id
                };
                dispatch(updateBrandIdApicall(reqData, response => { }));
            }

            body.whitelabel = {};
            whitelabels.forEach(w_e => {
                let whiteLabelBrand = matchedData[createBrandIndex].whiteLabelBrand || [];
                let filter = whiteLabelBrand.filter(a => a["White Label Name"] == w_e.partnerName);
                if (filter && filter.length > 0) {
                    body.whitelabel[w_e.partnerSerialNo] = {
                        brandLogoUrl: filter[0]["Brand Logo Url"],
                        brandMapLogo: filter[0]["Brand Map Logo"],
                        brandName: filter[0]["Brand Name"],
                        forMap: filter[0]["Wet Venues"] == "Yes" ? true : false,
                        isGiftCardsAllowed: filter[0]["Gift Cards Allowed"] == "Yes" ? true : false,
                        offerCategory: filter[0]["Offer Category"],
                        offerStartDate: new Date(filter[0]["Offer Start date"]),
                        offerEndDate: new Date(filter[0]["Offer End date"]),
                        brandPhase: [
                            filter[0]["Phase"] ? filter[0]["Phase"].toString() : ""
                        ]
                    }
                }
            });

            createBrandIndex++;
            axiosMethodRequest("POST", "brands", body).then((response) => {
                // if (response && response.data && response.data.respCode) {
                if (response && response.data) {
                    body.brandObjId = response.data.brandId;
                    createBranch(element, body, createBrandIndex, matchedData);
                }
            });
        }
    }

    const createBranch = async (element, createBrandBody, createBrandIndex, matchedData) => {

        let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
        let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
        let createBranchIndex = 0;

        let branchDetails = [];
        async function createBranchOnNode(branches, index) {

            let e2 = branches[index];

            let body = {
                "branch_name": e2["Branch Name"] || "",
                "entity_url": e2["Tripadvisor Url"] || "",
                "brandname": createBrandBody.brandname || "",
                "brand_id": createBrandBody.brandId || "",
                "city": "",
                "description": "",
                "address": e2["Address"] || "",
                "merchantId": e2["Visa/Mastercard Merchant ID"] || "",
                "amexMerchantID": e2["Amex Merchant ID"] || "",
                "zettleMerchantID": e2["Zettle/Square Merchant ID"] || "",
                "postalTown": e2["Postal Town"] || "",
                "postcode": e2["Post code"] || "",
                "tripadvisorUrl": e2["Tripadvisor Url"] || "",
                "sector_id": "16" || "",
                "cuisines_list": [] || "",
                "kmid": "",
                "tp_rank": e2["TP Rank"] || "",
                "copy": e2["Copy"] || ""
            };

            let nodeBody = {
                "name": e2["Branch Name"],
                "brandName": createBrandBody.brandname,
                "brandId": createBrandBody.brandId,
                "created_by": "ui",
                "datasource": "ui",
                "email": e2["Email"],
                "contactEmail": e2["Email"],
                "entity_id": "",
                "latlong": "",
                "logo_url": "",
                "merchant_name": "",
                "phone_no": e2["Contact Phone"],
                "website_url": "",
                "isNew": true,
                "contactName": e2["Contact Name"],
                "contactPhone": e2["Contact Phone"],
                "created_date": new Date(),
                "brandObjId": createBrandBody.brandObjId,
                "cuisines_list": e2["Cuisine list"] || "",
                "num_reviews": e2["Number of reviews"] || "",
                "price_range": e2["Price range"] || "",
                "rating": e2["Restaurant rating"] || ""
            };

            let res = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
            createBranchIndex++;
            body.kmid = res.data && res.data[0] && res.data[0].kmid ? res.data[0].kmid : "";
            body.cuisines_list = e2["Cuisine list"];
            nodeBody = Object.assign(nodeBody, body);

            nodeBody.whitelabel = {};
            let temp_createBrandIndex = createBrandIndex - 1;
            whitelabels.forEach(w_e => {
                let whiteLabelBranch = matchedData[temp_createBrandIndex].whiteLabelBranch || [];
                let filter = whiteLabelBranch.filter(a => a["White Label Name"] == w_e.partnerName && a["CAM Branch Name"] == e2["Branch Name"]);
                if (filter && filter.length > 0) {
                    nodeBody.whitelabel[w_e.partnerSerialNo] = {
                        "branchName": filter[0]["Branch Name"],
                        "branchCopy": filter[0]["Branch Copy"],
                        "displayAddress": filter[0]["Display address"],
                        "gmap_address": filter[0]["Google Maps address"] || "",
                        // "branchStartDate": new Date(filter[0]["Branch Start Date"]),
                        // "branchEndDate": new Date(filter[0]["Branch End Date"]),
                        "bookingLink": filter[0]["Booking Link"],
                        "cuisines_list": filter[0]["Cuisine list"],
                        "isFeatured": filter[0]["Is Featured"] == "Yes" ? true : false,
                        "branchPhase": [
                            filter[0]["Phase"] ? filter[0]["Phase"].toString() : ""
                        ]
                    }

                    let branchImage = filter[0]["Branch Image"] ? filter[0]["Branch Image"].split(",") : "";
                    let branchVideo = filter[0]["Branch Video"] ? filter[0]["Branch Video"].split(",") : "";

                    nodeBody.whitelabel[w_e.partnerSerialNo]["branchAssets"] = [];
                    let aa_length = [branchImage.length, branchVideo.length];
                    aa_length = Math.max(...aa_length);
                    if (aa_length > 0) {
                        for (let index = 0; index < aa_length; index++) {
                            nodeBody.whitelabel[w_e.partnerSerialNo]["branchAssets"].push({
                                "branchImage": branchImage[index] || "",
                                "branchVideo": branchVideo[index] || ""
                            });
                        }
                    }


                    // if (filter[0]["Branch Experience"] == "Yes") {
                    //     nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"] = {
                    //         "expStartDate": filter[0]["Exp Start Date"],
                    //         "expEndDate": filter[0]["Exp End Date"],
                    //         "expName": filter[0]["Exp Name"],
                    //         "expCopy": filter[0]["Exp Copy"]
                    //     }

                    //     let expImageUrl = filter[0]["Exp Image Url"] ? filter[0]["Exp Image Url"].split(",") : "";
                    //     let expThumbnailUrl = filter[0]["Exp Thumbnail Url"] ? filter[0]["Exp Thumbnail Url"].split(",") : "";
                    //     let expVideoUrl = filter[0]["Exp Video Url"] ? filter[0]["Exp Video Url"].split(",") : "";
                    //     nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"]["expAssets"] = [];
                    //     let aa_length = [expImageUrl.length, expThumbnailUrl.length, expVideoUrl.length];
                    //     aa_length = Math.max(...aa_length);

                    //     if (aa_length > 0) {
                    //         for (let index = 0; index < aa_length; index++) {
                    //             nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"]["expAssets"].push({
                    //                 "branchExpImage": expImageUrl[index] || "",
                    //                 "branchExpThumbnail": expThumbnailUrl[index] || "",
                    //                 "branchExpVideo": expVideoUrl[index] || "",
                    //             });
                    //         }
                    //     }

                    // }
                }
            });

            nodeBody.merchantIds = {
                mastercardMerchantID: nodeBody.merchantId,
                amexMerchantID: nodeBody.amexMerchantID,
                zettleMerchantID: nodeBody.zettleMerchantID
            };
            delete nodeBody.merchantId;
            delete nodeBody.amexMerchantID;
            delete nodeBody.zettleMerchantID;

            branchDetails.push(nodeBody);

            if (createBranchIndex == branches.length) {
                dispatch(CreateBranchApiCall(branchDetails, "bulkUpload"));
                if (uploadGroups.dataUploadFor == "brand") {
                    createUser(element, createBrandBody, createBrandIndex, matchedData);
                } else if (uploadGroups.dataUploadFor == "group") {
                    createGroup(element, createBrandBody, createBrandIndex, matchedData);
                }
            } else {
                createBranchOnNode(branches, createBranchIndex);
            }

        }
        createBranchOnNode(element.branches, 0);
    }

    const createUser = (element, createBrandBody, createBrandIndex, matchedData) => {
        let bodyList = [];
        element.users.forEach(e2 => {
            bodyList.push({
                brandId: createBrandBody.brandId,
                contactPhone: e2["Phone"] || "",
                email: e2["Email"] || "",
                firstName: e2["First Name"] || "",
                guestUser: true,
                isNew: true,
                lastName: e2["Last Name"] || "",
                phone: e2["Phone"] || "",
                revenueCount: 0,
                role: "companyAdmin",
                billingEmail: e2["Invoice Email addresses"] || "",
            });
        });
        dispatch(createUserBulkUploadApiCall(bodyList, "bulkUpload", response => {
            // setTimeout(() => {
            //     dispatch(addBrandBranchFromActionCenterAction({ brandId: createBrandBody.brandId }));
            // }, 3000);
            if (matchedData.length !== createBrandIndex) {
                createBrand(matchedData, createBrandIndex);
            } else if (matchedData.length == createBrandIndex) {
                let notification = { ...notificationOpts };
                notification.message = "File Uploaded successfully";

                if (brandCameFrom == "salesAdmin") {
                    brandCameFrom = "";
                    dispatch(skipCampaignStepApiCall(confirmUploadData.selectedEntity._id, response => {

                    }));
                }
                dispatch(successNotificationAction(notification));
                setPageAndLimitValue({ limit: 10, page: 1, totalCount: 10 });
                getActionCenter(true);
                setBrandErrorModal({ showBrandErrorModal: true, isUploadedData: true, matchedData: matchedData, uploadFor: "brand" });
                setUploadBrandData({});
                // setTimeout(() => {
                //     dispatch(releaseBrandBranchAction());
                // }, 4000);
            }
        }));
    }

    let brandDetailsForTheGroup = [];
    const createGroup = (element, createBrandBody, createBrandIndex, matchedData) => {

        brandDetailsForTheGroup.push({
            value: createBrandBody.brandId,
            _id: createBrandBody.brandObjId,
            label: createBrandBody.brandname,
            group_name: element.brandDetails["Group Name"]
        });

        // setTimeout(() => {
        //     dispatch(addBrandBranchFromActionCenterAction({ brandId: createBrandBody.brandId }));
        // }, 3000);

        if (matchedData.length !== createBrandIndex) {
            createBrand(matchedData, createBrandIndex);
        } else if (matchedData.length == createBrandIndex) {
            if (uploadGroups.list) {
                let bodyList = [];
                uploadGroups.list.forEach((e2, index) => {
                    e2.brandId = generateId(9);
                    bodyList.push({
                        "firstName": e2["First Name"] || "",
                        "lastName": e2["Last Name"] || "",
                        "email": e2["Email"] || "",
                        "phone": e2["Phone"] || "",
                        "brandName": e2["Group Name"] || "",
                        "guestUser": true,
                        "isNew": true,
                        "revenueCount": "85",
                        "role": "groupAdmin",
                        brandId: e2.brandId,
                        "from": "Add"
                    });

                    dispatch(createGroupUserAction(bodyList, response => {
                        let brandListOfGroup = [];
                        brandDetailsForTheGroup.forEach(element => {
                            if (e2["Group Name"] == element.group_name) {
                                brandListOfGroup.push({
                                    value: element.value,
                                    _id: element._id,
                                    label: element.label,
                                });
                            }
                        });

                        if (e2["Invoice Preference Level 1"] == "Group") {
                            e2["Invoice Preference Level 1"] = "group";
                        } else if (e2["Invoice Preference Level 1"] == "Brand") {
                            e2["Invoice Preference Level 1"] = "brand";
                        }

                        if (e2["Invoice Preference Level 2"] == "Brand") {
                            e2["Invoice Preference Level 2"] = "brand";
                        } else if (e2["Invoice Preference Level 2"] == "Branch") {
                            e2["Invoice Preference Level 2"] = "branch";
                        } else if (e2["Invoice Preference Level 2"] == "Publisher") {
                            e2["Invoice Preference Level 2"] = "publisher";
                        }

                        if (e2["Separate Invoice"] == "Brand" || e2["Separate Invoice"] == "Publisher") {
                            e2["Separate Invoice"] = "Yes";
                        } else {
                            e2["Separate Invoice"] = "No";
                        }

                        let groupDetail = {
                            email: e2["Email"] || "",
                            firstName: e2["First Name"] || "",
                            groupId: e2.brandId,
                            group_brand_ids: brandListOfGroup,
                            group_name: e2["Group Name"] || "",
                            group_type: "ownBrands",
                            invoice_preference: e2["Invoice Preference Level 1"] || "",
                            invoice_preference_level_2: e2["Invoice Preference Level 2"] || "",
                            lastName: e2["Last Name"] || "",
                            phone: e2["Phone"] || "",
                            role: "groupAdmin",
                            separateInvoice: e2["Separate Invoice"] || "",
                            userObjId: response.saved_userId,
                            billingEmail: e2["Invoice Email addresses"] || "",
                        }
                        let reqData = {
                            _id: confirmUploadData.selectedEntity._id || uploadedDataDetail._id,
                            brandId: e2.brandId
                        };
                        dispatch(updateBrandIdApicall(reqData, response => { }));

                        dispatch(createGroupAction(groupDetail, response => {

                            if (uploadGroups.list.length == (index + 1)) {
                                let notification = { ...notificationOpts };
                                notification.message = "File Uploaded successfully";
                                if (brandCameFrom == "salesAdmin") {
                                    brandCameFrom = "";
                                    dispatch(skipCampaignStepApiCall(confirmUploadData.selectedEntity._id, response => {

                                    }));
                                }
                                dispatch(successNotificationAction(notification));
                                setPageAndLimitValue({ limit: 10, page: 1, totalCount: 10 });
                                getActionCenter(true);
                                setBrandErrorModal({
                                    showBrandErrorModal: true, isUploadedData: true, matchedData: matchedData,
                                    uploadFor: "group", groups: uploadGroups.list
                                });
                                setUploadBrandData({});
                                // setTimeout(() => {
                                //     dispatch(releaseBrandBranchAction());
                                // }, 4000);
                            }

                        }));

                    }));
                });

            }
        }
    }

    const downloadCAMLinks = (selectedEntity) => {
        dispatch(getCAMLinkDetailsAction(selectedEntity, response => {
            if (response && response.users) {
                let brandsColumn = [];
                response.users.forEach(element => {
                    let userName = element.firstName;
                    userName = element.lastName ? userName + " " + element.lastName : userName;

                    brandsColumn.push({
                        "Brand Name": element.brandName,
                        "User name": userName,
                        "Onboarding": `${config.guestUrl}${element.email}?type=campaign`,
                        "AMEX": `${config.guestUrl}${element.email}?type=campaign&user=amex`,
                    });
                });
                const sheet = XLSX.utils.json_to_sheet(brandsColumn);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, sheet, "Links");
                XLSX.writeFile(workbook, new Date().getTime() + "-CAMLinks.xlsx");
            }
        }));
    }

    const copyLink = (from, row) => {
        let urlLink = `${config.guestUrl}${row.email}?type=campaign`;
        if (from === "AMEX") {
            urlLink = urlLink + "&user=amex";
        }
        const el = document.createElement('textarea');
        el.value = urlLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        let notification = { ...notificationOpts };
        notification.message = "Copied..!";
        dispatch(successNotificationAction(notification));
    }

    const sentMailToUser = () => {
        setSendMail({ showSendMailModal: false, selectedData: {} });
        getActionCenter(true);
    }

    const handleSearchInputChange = (event) => {
        console.log(event.target.value);
        setSearchField(event.target.value);
        getActionCenter(false, event.target.value);
    }

    const openAddBrandModal = () => {
        if (!brandNameForSales) {
            let notification = { ...notificationOpts };
            notification.message = "Please add the brand/group Name";
            dispatch(errorNotificationAction(notification));
            return;
        }
        setAddBranchBranchBySalesData({
            showAddBranchBranchBySalesDataModal: true, selectedData: {
                brandName: brandNameForSales,
                uploadFor: uploadForSales
            }
        })
    }

    const openEditBrandModal = (row) => {
        row.fromPage = "Edit";
        setAddBranchBranchBySalesData({ showAddBranchBranchBySalesDataModal: true, selectedData: row })
    }

    const addedBrandBySales = () => {
        setAddBranchBranchBySalesData({ showAddBranchBranchBySalesDataModal: false, selectedData: {} });
        getActionCenter(true, "");
        setBrandName("");
        setUploadFor("group");
    }

    const OnExportAmexPhaseBrand = () => {
        setIsLoader(true);
        let body = {};
        if (exportSelectedWhiteLabel) {
            body.selectedWhiteLabel = exportSelectedWhiteLabel;
        }
        if (exportSelectedPublisher != "All") {
            body.selectedPublisher = exportSelectedPublisher;
        }
        if (selectedAmexPhase && selectedAmexPhase != "All") {
            body.is_amex_phase = selectedAmexPhase;
        }
        dispatch(exportAmexPhaseBrand(body, response => {
            if (response.Key) {
                window.open(`${config.apiUrl}brandActionCenter/downloadExportedAmexPhaseBrand/${response.Key}`, '_blank').focus();
                setIsLoader(false);
            }
        }));
    }

    const getSelectedWhiteLabelPhase = () => {
        let filter = whitelabels.filter(a => a.partnerSerialNo == exportSelectedWhiteLabel);
        if (filter && filter.length > 0) {
            filter = filter[0].phases;
        }
        return filter;
    }

    const columns = [
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Actions',
            classes: 'vhalign',
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        {/*  */}

                        {row.comments &&
                            <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => openCommentsModal(row)}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="View Comments">comment</Icon>
                            </div>}
                        {!row.isSetup && userDetails.role == "opsAdmin" &&
                            <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => reviewAndUploadFile(row)}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Upload">upload_file</Icon>
                            </div>}
                        {/* {row.isSetup && userDetails.role == "opsAdmin" &&
                        <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => downloadCAMLinks(row)}>
                            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Download CAM Link">download</Icon>
                        </div>} */}

                        {!row.isSetup &&
                            <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }}  >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit"
                                    onClick={() => { openEditBrandModal(row) }} >edit</Icon>
                            </div>
                        }

                        {!row.isSetup &&
                            <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setShowDeleteModal(row)} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                            </div>
                        }

                        {/* userDetails.role == "opsAdmin" && */}
                        {/* {row.isSetup &&
                            <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }}
                                onClick={() => setEditActionCenterData({ showEditActionCenterModal: true, selectedData: row })} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
                            </div>
                        } */}

                        {row.isSetup && userDetails.role == "opsAdmin" &&
                            <div className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                onClick={() => setSendMail({ showSendMailModal: true, selectedData: row })} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-success" data-toggle="tool-tip" title="Send Mail">email</Icon>
                            </div>
                        }

                        {row.isSetup &&
                            <div className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                onClick={() => setActionCenterActivitiesData({ showActionCenterActivitiesModal: true, selectedData: row })} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="OnBoarding Activitiy">alt_route</Icon>
                            </div>
                        }

                    </React.Fragment>
                )
            }
        },
        {
            dataField: 'brandName',
            sort: false,
            text: 'Brand/Group Name',
            classes: 'vhalign'
        },
        {
            dataField: 'isSetup',
            sort: false,
            text: 'Created',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.isSetup ? "Yes" : "No";
            }
        },
        {
            dataField: 'isOnboarded',
            sort: false,
            text: 'Onboarded',
            classes: 'vhalign',
            // formatter: (col, row) => {
            //     return row.isOnboarded ? "Yes" : "No";
            // }
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        {/* {row.isSetup &&
                        <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => openOnboardedModal(row)}>
                            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="View Onboarded">visibility</Icon>
                        </div>}
                    {!row.isSetup && "No"} */}
                        {(!row.isSetup || !row.proceesCompleted) && "No"}
                        {row.isSetup && row.proceesCompleted && "Yes"}
                    </React.Fragment>
                )
            }
        },
        {
            dataField: 'selectedPublisher',
            sort: false,
            text: 'Publisher',
            classes: 'vhalign',
            formatter: (col, row) => {
                if (row.selectedPublisher && Array.isArray(row.selectedPublisher)) {
                    let list = [];
                    row.selectedPublisher.forEach(element => {
                        if (element == "Fidel") {
                            element = "NextJump";
                        } else if (element == "LUX") {
                            element = "Mastercard";
                        }
                        list.push(element);
                    });
                    row.selectedPublisher = list.join(", ");
                }
                return (
                    <React.Fragment>
                        <span>{row.selectedPublisher}</span> <br />
                        {row.isSetup && row.is_sent_publisher_onboarding_request &&
                            <span className='view-publisher-status' onClick={() => openPublisherOnboardedModal(row)} > View Status </span>
                        }
                    </React.Fragment>
                )
            }
        },
        // {
        //     dataField: 'fileName',
        //     sort: false,
        //     text: 'File Name',
        //     classes: 'vhalign'
        // }
        {
            dataField: 'Onboarding',
            sort: false,
            text: 'Onboarding',
            classes: 'vhalign',
            formatter: (col, row) => {
                return (
                    <React.Fragment>
                        {row.isSetup && <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => copyLink("Onboarding", row)} >
                            <Icon style={{ fontSize: "1.5rem" }} className="text-success" title="Copy link">content_copy</Icon>
                        </span>}
                    </React.Fragment>
                )
            }
        },
        {
            dataField: 'AMEX',
            sort: false,
            text: 'AMEX',
            classes: 'vhalign',
            formatter: (col, row) => {
                return (
                    <React.Fragment>
                        {row.isSetup && <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => copyLink("AMEX", row)} >
                            <Icon style={{ fontSize: "1.5rem" }} className="text-success" title="Copy link">content_copy</Icon>
                        </span>}
                    </React.Fragment>
                )

            }
        },
        // {
        //     dataField: 'is_amex_phase',
        //     sort: false,
        //     text: 'Amex Phase',
        //     classes: 'vhalign',
        //     formatter: (col, row) => {
        //         let list_phase = [];
        //         if (row.is_amex_phase) {
        //             amexPhaseList.forEach(element => {
        //                 if (row.is_amex_phase.includes(element.value)) {
        //                     list_phase.push(element.label);
        //                 }
        //             });
        //         }
        //         list_phase = list_phase.join(", ");
        //         return (
        //             <React.Fragment>
        //                 {list_phase}
        //             </React.Fragment>
        //         )
        //     }
        // }
    ]

    const [whitelabelsStrings, setWhitelabelsStrings] = useState({
        "selectSomeItems": "Select Partner",
        "allItemsAreSelected": "All Partner are selected.",
        "selectAll": "All Publisher",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const whitelabelsRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Partner selected" : "Select Partner"
        }
    };

    return (
        <>
            <div>
                <div className="row action-center">
                    <div className="col-md-12">
                        <div className="heading">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h2 className="m-0 ">
                                        <span>
                                            <Icon className="heading-icon">pending_actions</Icon>
                                            Action Center
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        {(userDetails.role == "opsAdmin" || userDetails.role == "superAdmin") &&
                            <div className="row justify-content-center m-t-25">

                                <div className="col-xl-2 col-lg-2">
                                    <div className="card pill">
                                        <div className="card-body text-center pr-0 pl-0">
                                            <div className="icon-with-bg">
                                                <Icon className="text-primary" fontSize="inherit">today</Icon>
                                            </div>
                                            <p className="bold title">
                                                {analytics.totalBrandSetupPending}
                                            </p>
                                            <p className="sub-title">
                                                Brand Setup Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2">
                                    <div className="card pill">
                                        <div className="card-body text-center pr-0 pl-0">
                                            <div className="icon-with-bg">
                                                <Icon className="text-primary" fontSize="inherit">today</Icon>
                                            </div>
                                            <p className="bold title">
                                                {analytics.totalNewBrand}
                                            </p>
                                            <p className="sub-title">
                                                Brand/Group
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2">
                                    <div className="card pill">
                                        <div className="card-body text-center pr-0 pl-0">
                                            <div className="icon-with-bg">
                                                <Icon className="text-primary" fontSize="inherit">pending_actions</Icon>
                                            </div>
                                            <p className="bold title">
                                                {analytics.totalOnboardingPending}
                                            </p>
                                            <p className="sub-title">
                                                Onboarding Pending
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-2 col-lg-2">
                                    <div className="card pill">
                                        <div className="card-body text-center pr-0 pl-0">
                                            <div className="icon-with-bg">
                                                <Icon className="text-primary" fontSize="inherit">receipt</Icon>
                                            </div>
                                            <p className="bold title">
                                                {analytics.totalOnboarded}
                                            </p>
                                            <p className="sub-title">
                                                Onboarded
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-xl-2 col-lg-2">
                                    <div className="card pill">
                                        <div className="card-body text-center pr-0 pl-0">
                                            <div className="icon-with-bg">
                                                <Icon className="text-primary" fontSize="inherit">today</Icon>
                                            </div>
                                            <p className="bold title blurText">
                                                110
                                            </p>
                                            <p className="sub-title">
                                                Invoice Generated
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        }

                        <div className="card m-t-25">

                            {(userDetails.role == "opsAdmin" || userDetails.role == "salesAdmin") &&
                                <div className="row col-12 form bulk-brand-upload-modal">
                                    <div className="col-3 group-brand-rbt">
                                        <FormControl>
                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Upload for</FormLabel> */}
                                            <RadioGroup row
                                                defaultValue={uploadForSales}
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group" onChange={(e) => { setUploadForSales(e.target.value) }} >
                                                <FormControlLabel value="group" control={<Radio />} label="Group" />
                                                <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    <div className="col-3">
                                        <FormControl className='w-100' variant="standard">
                                            <TextField
                                                id='brandName'
                                                name='brandName'
                                                value={brandNameForSales}
                                                type="text" label={uploadForSales == "group" ? "Group Name" : "Brand Name"}
                                                onChange={e => { setBrandNameForSales(e.target.value) }}
                                                className={`form-control mb-3`} />
                                        </FormControl>
                                    </div>

                                    <div className="col-3">
                                        <button className="btn btn-primary m-t-10" style={{ verticalAlign: 'bottom' }}
                                            onClick={() => { openAddBrandModal() }} >Add</button>
                                    </div>
                                </div>
                            }

                            {userDetails.role == "opsAdmin" &&
                                <>
                                    <div className="row col-12 separate-box"></div>
                                    <div className="row col-12 form bulk-brand-upload-modal">

                                        <div className="col-3 group-brand-rbt">
                                            <FormControl>
                                                {/* <FormLabel id="demo-row-radio-buttons-group-label">Upload for</FormLabel> */}
                                                <RadioGroup row
                                                    defaultValue={uploadFor}
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group" onChange={(e) => { setUploadFor(e.target.value) }} >
                                                    <FormControlLabel value="group" control={<Radio />} label="Group" />
                                                    <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>

                                        <div className="col-3">
                                            <FormControl className='w-100' variant="standard">
                                                <TextField
                                                    id='brandName'
                                                    name='brandName'
                                                    value={brandName}
                                                    type="text" label={uploadFor == "group" ? "Group Name" : "Brand Name"}
                                                    onChange={e => { setBrandName(e.target.value) }}
                                                    className={`form-control mb-3`} />
                                            </FormControl>
                                        </div>

                                        <div className="col-3">
                                            <FormControl className='w-100' variant="standard">
                                                <TextField
                                                    id='comments'
                                                    name='comments'
                                                    value={comments}
                                                    type="text" label="Comments"
                                                    onChange={e => { setComments(e.target.value) }}
                                                    className={`form-control mb-3`} />
                                            </FormControl>
                                        </div>

                                        <div className="col-3">
                                            <button className="btn btn-primary m-t-10" style={{ verticalAlign: 'bottom' }} onClick={downloadTemplate} >
                                                Download {uploadFor == 'group' ? 'Group' : 'Brand'} Template
                                                <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                            </button>
                                        </div>

                                        <div className="row col-12 m-t-25">
                                            <div className="col-2">
                                                <div className="bulk-upload-btn-wrapper">
                                                    <button className="btn btn-primary">
                                                        Upload
                                                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                                                    </button>
                                                    <input
                                                        name="bulk-upload"
                                                        id="bulk-upload"
                                                        ref={inputRef}
                                                        // onBlur={onBlur}
                                                        accept=".xls,.xlsx"
                                                        type='file'
                                                        onClick={clearErrorValue}
                                                        onChange={handleFileChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-10 file-name">
                                                {selectedFile && selectedFile.name &&
                                                    <>
                                                        <div className='file-label'> <span>{selectedFile.name}</span> </div>
                                                        <div className='file-delete' onClick={removeSelectedFile} >
                                                            <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {createActionCenterState.error && createActionCenterState.error.errorCode && createActionCenterState.error.isInValid &&
                                            <>
                                                <div className="col-12 invalid-title">
                                                    Invalid details
                                                </div>

                                                {Object.keys(createActionCenterState.error.data).map((a, index) => {

                                                    return (
                                                        <div className="col-12 invalid-file" key={index + "invalidData"}>
                                                            {createActionCenterState.error.data[a]}
                                                        </div>
                                                    )
                                                })}

                                            </>
                                        }

                                        {createActionCenterState.error && createActionCenterState.error.errorCode &&
                                            <div className="col-12 invalid-file">
                                                {createActionCenterState.error.errorMessage}
                                            </div>
                                        }

                                    </div>

                                    <div className="row col-12 m-t-15">
                                        <div className="col-6"></div>
                                        <div className="col-6 save-upload">
                                            <button disabled={!brandName || !selectedFile || !selectedFile.name}
                                                onClick={() => saveUploadedFile()}
                                                className="btn btn-sm btn-primary newbrandBtn">  Save  </button>
                                        </div>
                                    </div>
                                </>
                            }


                            {(userDetails.role == "opsAdmin" || userDetails.role == "superAdmin") &&
                                <>
                                    <div className="row col-12 separate-box"></div>
                                    <div className="row col-12 m-t-15 p-r-0 m-r-0">
                                        <div className="row col-12 h-20">

                                            {userDetails.role == "opsAdmin" &&
                                                <div className="col-2">
                                                    <button onClick={() => setSendMail({ showSendMailModal: true, selectedData: {} })}
                                                        className="btn btn-primary">  Send Mail  </button>
                                                </div>
                                            }

                                            <div className="col-2">
                                                <div className="form-group">
                                                    <select name="selectedWhiteLabel" value={exportSelectedWhiteLabel} className="form-control"
                                                        onChange={(e) => { setExportSelectedWhiteLabel(e.target.value) }}>
                                                        <option value=""> Select White Label </option>
                                                        {whitelabels.map(res =>
                                                            <option value={res.value}>{res.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            {exportSelectedWhiteLabel ?
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <select name="selectedAmexPhase" value={selectedAmexPhase} className="form-control"
                                                            onChange={(e) => { setSelectedAmexPhase(e.target.value); }}>
                                                            <option value=""> Select Phase </option>
                                                            {getSelectedWhiteLabelPhase().map(res =>
                                                                <option value={res.phaseNumber}>{res.phaseNumber}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                : null}

                                            <div className="col-2">
                                                <div className="form-group">
                                                    <select name="selectedPublisher" value={exportSelectedPublisher} className="form-control"
                                                        onChange={(e) => { setExportSelectedPublisher(e.target.value) }}>
                                                        <option value=""> Select Publisher </option>
                                                        {distributorList.map(res =>
                                                            <option value={res.distributors_name}>{res.distributors_display_name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <button onClick={() => OnExportAmexPhaseBrand()} className="btn btn-primary">  Export  </button>
                                            </div>
                                        </div>
                                        {/* <div className="row col-3 action-center-search"></div> */}

                                    </div>
                                </>
                            }

                            <div className="row col-12 separate-box"></div>
                            <div className="row col-12 m-t-15 p-r-0 m-r-0">
                                <div className="row col-7 h-20">
                                    <span className='action-center-sub-t'> Setting Brand Deals </span>
                                </div>
                                <div className="row col-5 action-center-search">
                                    <div className="row col-6 action-center-search-box">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Search brand/group"
                                                onChange={(event) => { handleSearchInputChange(event) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        {searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                                    </div>
                                </div>
                            </div>

                            {/* </>
                            } */}

                            <div className="col-12 m-t-25 m-b-25">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField='id'
                                    data={brandActionList}
                                    columns={columns}
                                    wrapperClasses="table-responsive"
                                    remote
                                    // onTableChange={this.handleTableChange}
                                    noDataIndication={() => <span>No data found.</span>}
                                    pagination={pagination}
                                />
                            </div>


                        </div>
                    </div>

                </div>

                {isLoader &&
                    <div className="loading">Loading...</div>}

                <ModalComponent close={() => { clearDeleteModal() }}
                    callback={handleDeleteEntity}
                    show={deleteModalData.showDeleteModal}
                    title={<span>Delete <b>File</b></span>}
                    message={<span>Are you sure you want to delete the File.</span>}
                    action={"Delete"}
                    actionType={'danger'}
                />

                <ModalComponent close={() => { setConfirmUploadData({ showUploadConfirmModal: false, selectedEntity: {} }) }}
                    callback={() => { submitUploadedFile(confirmUploadData.selectedEntity, "salesAdmin"); }}
                    show={confirmUploadData.showUploadConfirmModal}
                    title={<span>Upload File</span>}
                    message={<span>Are you sure you want to upload the File.</span>}
                    action={"Yes"}
                    actionType={'success'}
                />

                <ModalComponent close={() => { setShowCommentsData({ showCommentsModal: false, selectedEntity: {} }) }}
                    show={showCommentsData.showCommentsModal}
                    title={<span>Comments</span>}
                    message={<span> {showCommentsData.selectedEntity.comments} </span>}
                    hideActionBtn={true}
                />

                {brandErrorModal.showBrandErrorModal ?
                    <ReBrandUploadModal
                        close={() => closeBrandErrorModal()}
                        callback={(entityData) => {
                            setConfirmUploadData({ showUploadConfirmModal: false, selectedEntity: entityData });
                            closeBrandErrorModal(); submitUploadedFile(entityData, "salesAdmin");
                        }}
                        show={brandErrorModal.showBrandErrorModal}
                        responseData={brandErrorModal.response}
                        brandErrorModal={brandErrorModal}
                        from={"ErrorMessage"}
                        whitelabels={whitelabels}
                        tempDistributorList={tempDistributorList}
                    />
                    : null}

                {publisherOnboardedModal.showPublisherOnboardedModalModal ?
                    <ReBrandUploadModal
                        size={'lg'}
                        close={() => setPublisherOnboardedModal({ showPublisherOnboardedModalModal: false, selectedEntity: {} })}
                        show={publisherOnboardedModal.showPublisherOnboardedModalModal}
                        brandErrorModal={publisherOnboardedModal}
                        from={"publisherOnboarded"}
                        tempDistributorList={tempDistributorList}
                    />
                    : null}

                {editActionCenterData.showEditActionCenterModal &&
                    <EditActionCenterModal
                        close={() => { setEditActionCenterData({ showEditActionCenterModal: false, selectedData: {} }) }}
                        callback={(entityData) => { getActionCenter(true); setEditActionCenterData({ showEditActionCenterModal: false, selectedData: {} }); }}
                        show={editActionCenterData.showEditActionCenterModal}
                        modalData={editActionCenterData.selectedData}
                        from={"editDetails"}
                    />
                }

                {sendMail.showSendMailModal &&
                    <EditActionCenterModal
                        close={() => { setSendMail({ showSendMailModal: false, selectedData: {} }) }}
                        callback={(entityData) => { sentMailToUser() }}
                        show={sendMail.showSendMailModal}
                        modalData={sendMail.selectedData}
                        from={"sendMail"}
                    />
                }

                {actionCenterActivitiesData.showActionCenterActivitiesModal &&
                    <ActionCenterActivitiesModal
                        close={() => { setActionCenterActivitiesData({ showActionCenterActivitiesModal: false, selectedData: {} }) }}
                        callback={(entityData) => { }}
                        show={actionCenterActivitiesData.showActionCenterActivitiesModal}
                        modalData={actionCenterActivitiesData.selectedData}
                    />
                }

                {addBranchBranchBySalesData.showAddBranchBranchBySalesDataModal &&
                    <AddBrandBranchBySalesModal
                        close={() => { setAddBranchBranchBySalesData({ showAddBranchBranchBySalesDataModal: false, selectedData: {} }) }}
                        callback={() => { addedBrandBySales() }}
                        show={addBranchBranchBySalesData.showAddBranchBranchBySalesDataModal}
                        modalData={addBranchBranchBySalesData.selectedData}
                    />
                }


            </div >

        </>
    )
};
