import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon';
import { getBrandActivityApicall, getBrandActivityByIdApicall } from '../../services/brandActionCenter/createBrandActionCenter';
import { brandActionCenterSelectors } from '../../redux/brandActionCenter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import BrandActivityModal from './BrandActivityModal';
import Select from 'react-select';
import { getDistributorListAction, getAllBrandListAction } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { useHistory } from "react-router-dom";
import { getWhitelabels } from '../../services/newBrandBranch/checkBrandExistApiCall';

export default function BrandActivity() {

    const history = useHistory();
    const [brandList, setBrandList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [selectedAmexPhase, setSelectedAmexPhase] = useState({ label: 'Phase (All)', value: null });
    const [brandActivityHistory, setBrandActivityHistory] = useState({ isOpenModal: false, data: {} });
    const [distributorList, setDistributorList] = useState([]);
    const [selectedDistributor, setSelectedDistributor] = useState({ label: 'Publisher (All)', value: null });
    const [whitelabels, setWhitelabels] = useState([]);
    const [amexPhaseList, setAmexPhaseList] = useState([
        { label: 'All', value: null },
        { label: 'Amex Phase 3', value: 'amex_phase_3' },
        { label: 'Amex Phase 3.5', value: 'amex_phase_3_5' },
        { label: 'Amex Phase 4', value: 'amex_phase_4' }
    ]);

    const [allBrandList, setAllBrandList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState({ label: 'Brand (All)', value: null });
    const [selectedWhiteLabel, setSelectedWhiteLabel] = useState({ label: 'White Label (All)', value: null });

    const [pageAndLimitValue, setPageAndLimitValue] = useState({
        limit: 10,
        page: 1,
        totalCount: 10
    });
    const [pagination, setPagination] = useState();
    const dispatch = useDispatch();

    const getBrandActivityState = useSelector(
        brandActionCenterSelectors.getBrandActivityState
    );


    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group pt-1" role="group">
            <div className="dropdown">
                <button className="btn btn-primary dropbtn">{pageAndLimitValue.limit}  <ExpandMoreIcon /></button>
                <div className="dropdown-content">
                    {
                        options.map((option, index) => {
                            const isSelect = currSizePerPage === `${option.page}`;
                            return (
                                <a href="#" onClick={() => setPageLimit(option.page)} key={index}> {option.text}</a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )

    useEffect(() => {

        getBrandActivity(true);

        dispatch(getDistributorListAction(response => {
            if (response.length) {
                let list = [{ label: 'Publisher (All)', value: null }];
                response.forEach(element => {
                    list.push({ label: element.distributors_display_name, value: element.distributors_name });
                });
                setDistributorList(list);
            }
        }));

        dispatch(getAllBrandListAction(response => {
            if (response.length) {
                let list = [{ label: 'All', value: null }];
                list = list.concat(response);
                setAllBrandList(list);
            }
        }));

        dispatch(getWhitelabels(response => {
            setWhitelabels(response);
        }));

    }, []);

    const getBrandActivity = (showLoader, field, value) => {
        let filterCriteria = { limit: pageAndLimitValue.limit, page: pageAndLimitValue.page };
        if (selectedDistributor && selectedDistributor.value) {
            filterCriteria.selectedPublisher = selectedDistributor.value;
        }
        if (selectedAmexPhase && selectedAmexPhase.value) {
            filterCriteria.is_amex_phase = selectedAmexPhase.value;
        }
        if (selectedBrand && selectedBrand.value) {
            filterCriteria.brandId = selectedBrand.value;
        }
        if (selectedWhiteLabel && selectedWhiteLabel.value) {
            filterCriteria.selectedWhiteLabel = selectedWhiteLabel.value;
        }

        if (field && value) {
            filterCriteria[field] = value;
        } else if (field && !value) {
            delete filterCriteria[field];
        }

        if (showLoader) { setIsLoader(true); }
        dispatch(getBrandActivityApicall(filterCriteria));
    }

    const pageChange = (page) => {
        let pageData = pageAndLimitValue;
        pageData.page = page
        setPageAndLimitValue(pageData);
        getBrandActivity(true);
    }

    const setPageLimit = (limit) => {
        let pageData = pageAndLimitValue;
        pageData.limit = limit
        setPageAndLimitValue(pageData);
        getBrandActivity(true);
    }

    useEffect(() => {
        if (getBrandActivityState && getBrandActivityState.data && getBrandActivityState.data.length) {
            setBrandList(getBrandActivityState.data);
            setPageAndLimitValue(getBrandActivityState.pagination);
            setPagination(paginationFactory(
                {
                    sizePerPageRenderer: sizePerPageRenderer,
                    page: getBrandActivityState.pagination.page,
                    sizePerPage: getBrandActivityState.pagination.limit,
                    totalSize: getBrandActivityState.pagination.totalCount,
                    hideSizePerPage: false,
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    alwaysShowAllBtns: true,
                    show: true,
                    onPageChange: (page, sizePerPage) => {
                        pageChange(page)
                    }
                }
            ));
            setIsLoader(false);
        } else if (getBrandActivityState && getBrandActivityState.data && getBrandActivityState.data.length == 0) {
            setIsLoader(false);
        }

        return () => {
            setBrandList([]);
        };

    }, [getBrandActivityState && getBrandActivityState.data]);


    const columns = [
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'View Details',
            classes: 'vhalign',
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        <div className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => { getBrandActivityByBrandId(row) }} >
                            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="View Details">visibility</Icon>
                        </div>

                        <div className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                            onClick={() => editBrandDetail(row)} >
                            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
                        </div>

                    </React.Fragment>
                )
            }
        },
        {
            dataField: 'name',
            sort: false,
            text: 'Brand Name',
            classes: 'vhalign'
        },
        // {
        //     dataField: 'registeredAddress',
        //     sort: false,
        //     text: 'Address',
        //     classes: 'vhalign'
        // },
        {
            dataField: 'selectedPublisher',
            sort: false,
            text: 'Publisher',
            classes: 'vhalign'
        },
        // {
        //     dataField: 'successFee', 
        //     sort: false,
        //     text: 'Success Fee',
        //     classes: 'vhalign'
        // },
        // {
        //     dataField: 'copy',
        //     sort: false,
        //     text: 'Copy',
        //     classes: 'vhalign'
        // },
        // {
        //     dataField: 'tp_rank',
        //     sort: false,
        //     text: 'TP Rank',
        //     classes: 'vhalign'
        // },
        // {
        //     dataField: 'is_amex_phase',
        //     sort: false,
        //     text: 'Amex Phase',
        //     classes: 'vhalign',
        //     formatter: (col, row) => {
        //         let list_phase = [];
        //         if (row.is_amex_phase) {
        //             amexPhaseList.forEach(element => {
        //                 if (row.is_amex_phase.includes(element.value)) {
        //                     list_phase.push(element.label);
        //                 }
        //             });
        //         }
        //         list_phase = list_phase.join(", ");
        //         return (
        //             <React.Fragment>
        //                 {list_phase}
        //             </React.Fragment>
        //         )
        //     }
        // },
        {
            dataField: 'created',
            sort: false,
            text: 'Brand Onboarded',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.created ? moment(row.created).format('YYYY-MM-DD') : "";
            }
        },
        {
            dataField: 'TandCAcceptedDate',
            sort: false,
            text: 'T & C Accepted',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.TandCAcceptedDate ? moment(row.TandCAcceptedDate).format('YYYY-MM-DD') : "";
            }
        },
        {
            dataField: 'activatedDate',
            sort: false,
            text: 'Client Onboarded',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.activatedDate ? moment(row.activatedDate).format('YYYY-MM-DD') : "";
            }
        }
    ];

    const getBrandActivityByBrandId = (data) => {
        dispatch(getBrandActivityByIdApicall(data.brandId, callBack => {
            callBack.brandDetail = data;
            setBrandActivityHistory({ isOpenModal: true, data: callBack });
        }));
    }

    const editBrandDetail = (row) => {
        history.push(`/user/edit/brand/${row._id}/${row.name}/${row.brandId}`);
    }

    const closeBrandActivity = () => {
        setBrandActivityHistory({ isOpenModal: false, data: {} });
    }


    const onChangeAmexPhase = async (selectedAmexPhase) => {
        setSelectedAmexPhase(selectedAmexPhase);
        getBrandActivity(true, "is_amex_phase", selectedAmexPhase.value);
    }

    const onChangeDistributor = async (selectedDistributor) => {
        setSelectedDistributor(selectedDistributor);
        getBrandActivity(true, "selectedPublisher", selectedDistributor.value);
    }

    const onChangeBrand = async (selectedBrand) => {
        setSelectedBrand(selectedBrand);
        getBrandActivity(true, "brandId", selectedBrand.value);
    }

    const onChangeWhiteLabel = async (selectedWhiteLabel) => {
        if (selectedWhiteLabel && selectedWhiteLabel.phases) {
            let phaseList = [{ label: 'Phase (All)', value: null }];
            selectedWhiteLabel.phases.forEach(element => {
                phaseList.push({
                    label: element.phaseNumber,
                    value: element.phaseNumber
                });
            });
            selectedWhiteLabel.phaseList = phaseList;
        }
        setSelectedWhiteLabel(selectedWhiteLabel);
        getBrandActivity(true, "selectedWhiteLabel", selectedWhiteLabel.value);
    }

    return (
        <div>
            {isLoader && <div className="loading">Loading...</div>}

            <div className="row">
                <div className="col-md-12">
                    <div className="heading">
                        <div className="row">
                            <div className="col-md-8 d-flex align-items-center">
                                <h2 className="m-0">
                                    <span>
                                        <Icon className="heading-icon">domain</Icon>
                                    </span> Brand Activity
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card">

                        <div className="content mb-3">

                            <div className='d-flex m-b-10'>

                                <div className='ml-auto m-r-10'
                                    style={{ width: '20%' }}>
                                    <Select
                                        options={allBrandList}
                                        onChange={(e) => { onChangeBrand(e) }}
                                        value={selectedBrand}
                                        className='action_Selecter' />
                                </div>
                                <div className='ml-auto m-l-0 m-r-10'
                                    style={{ width: '20%' }}>
                                    <Select
                                        options={whitelabels}
                                        onChange={(e) => { onChangeWhiteLabel(e) }}
                                        value={selectedWhiteLabel}
                                        className='action_Selecter' />
                                </div>

                                {selectedWhiteLabel.phaseList &&
                                    selectedWhiteLabel.phaseList.length > 0 ?
                                    <div className='ml-auto m-l-0 m-r-10'
                                        style={{ width: '15%' }}>
                                        <Select
                                            options={selectedWhiteLabel.phaseList}
                                            onChange={(e) => { onChangeAmexPhase(e) }}
                                            value={selectedAmexPhase}
                                            className='action_Selecter' />
                                    </div> : null}

                                <div className='ml-auto m-l-0'
                                    style={{ width: '15%' }}>
                                    <Select
                                        options={distributorList}
                                        onChange={(e) => { onChangeDistributor(e) }}
                                        value={selectedDistributor}
                                        className='action_Selecter'
                                    />
                                </div>

                            </div>



                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={brandList}
                                columns={columns}
                                wrapperClasses="table-responsive"
                                remote
                                noDataIndication={() => <span>No data found.</span>}
                                pagination={pagination}
                            />

                            {brandActivityHistory.isOpenModal &&
                                <BrandActivityModal close={() => { closeBrandActivity() }}
                                    show={brandActivityHistory.isOpenModal}
                                    data={brandActivityHistory.data} />
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
