
const pluralMap = {
  bank: 'banks',
  brand: 'brands',
  branch: 'branches',
  user: 'users',
  distributor: 'distributors',
  groupBrand: 'groupBrands',
  ingestionLog: 'ingestionLogs'
}


export const numberWithCommas = (x) => {
  if (x && x.toString)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return 0;
}

export const pluralize = text => {
  if (pluralMap[text])
    return pluralMap[text]
  return text;
}

export const hslToRgb = (h, s, l) => {
  // var r, g, b;

  // if (s == 0) {
  //   r = g = b = l; // achromatic
  // } else {
  //   function hue2rgb(p, q, t) {
  //     if (t < 0) t += 1;
  //     if (t > 1) t -= 1;
  //     if (t < 1/6) return p + (q - p) * 6 * t;
  //     if (t < 1/2) return q;
  //     if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  //     return p;
  //   }

  //   var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  //   var p = 2 * l - q;

  //   r = hue2rgb(p, q, h + 1/3);
  //   g = hue2rgb(p, q, h);
  //   b = hue2rgb(p, q, h - 1/3);
  // }

  // return [ r * 255, g * 255, b * 255 ];
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

export const getUniqueArray = (array, fieldName) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (item[fieldName] && !map.has(item[fieldName])) {
      map.set(item[fieldName], true);    // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export const generateId = (length) => {
  let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const groupColumnList = [{
  "Group Name": "Bulk Group 1",
  "First Name": "Chirantan",
  "Last Name": "Patel",
  "Email": "chirantan1324@yopmail.com",
  "Phone": "7897897898",
  "Invoice Preference Level 1": "Brand",
  "Invoice Preference Level 2": "Publisher",
  "Separate Invoice": "No",
  "Invoice Email addresses": "chirantan1@yopmail.com",
}]

export const brandWithoutGroup = [{
  "Brand Name": "BulkBrand1",
  "Company Name": "",
  "Address": "",
  "Website URL": "www.test.com",
  "Description": "",
  "Auto Debit": "Mandatory",
  "Invoice Preference Level 1": "Brand",
  "Invoice Preference Level 2": "Publisher",
  "Separate Invoice": "No",
  // "TP Rank": "",
  "Comments": "",
  "Payment Terms Days": ""
}];

export const brandWithGroup = [{
  "Group Name": "Bulk Group 1",
  "Brand Name": "BulkBrand1",
  "Company Name": "",
  "Address": "",
  "Website URL": "www.test.com",
  "Description": "",
  "Auto Debit": "Mandatory",
  "Comments": "",
  "Payment Terms Days": ""
}]

export const branchesColumnList = [{
  "Brand Name": "BulkBrand1",
  "Branch Name": "BulkBranch1",
  "Tripadvisor Url": "",
  "Amex Merchant ID": "TEST789",
  "Visa/Mastercard Merchant ID": "",
  "Zettle/Square Merchant ID": "",
  "Contact Name": "Chirantan",
  "Email": "chirantan@yopmail.com",
  "Contact Phone": "",
  "Address": "",
  "Postal Town": "",
  "Post code": "ESDFSDF",
  "Cuisine list": "",
  "Number of reviews": "",
  "Price range": "",
  "Restaurant rating": "",
  "TP Rank": "",
  "Copy": "",
}, {
  "Brand Name": "BulkBrand1",
  "Branch Name": "BulkBranch2",
  "Tripadvisor Url": "",
  "Amex Merchant ID": "EWRSDF789",
  "Visa/Mastercard Merchant ID": "",
  "Zettle/Square Merchant ID": "",
  "Contact Name": "Chirantan",
  "Email": "chirantan@yopmail.com",
  "Contact Phone": "",
  "Address": "",
  "Postal Town": "",
  "Post code": "ESDFSDFA",
  "Cuisine list": "",
  "Number of reviews": "",
  "Price range": "",
  "Restaurant rating": "",
  "TP Rank": "",
  "Copy": "",
}]

export const usersColumnList = [{
  "Brand Name": "BulkBrand1",
  "First Name": "Chirantan",
  "Last Name": "Patel",
  "Email": "chirantan1324@yopmail.com",
  "Phone": "7897897898",
  "Invoice Email addresses": "chirantan1@yopmail.com",
  "Is Guest User": "Yes"
}]

export const whiteLabelBrandColumnList = [{
  "CAM Brand Name": "",
  "White Label Name": "",
  "Brand Name": "",
  "Brand Logo Url": "",
  "Brand Map Logo": "",
  "Gift Cards Allowed": "No",
  "Phase": "",
  // "Offer Category": "",
  "Wet Venues": "No",
  "Offer Start date": "",
  "Offer End date": ""
}];

export const whiteLabelBranchColumnList = [{
  "CAM Brand Name" : "",
  "CAM Branch Name" : "",
  "White Label Name" : "",
  "Branch Name" : "",
  "Branch Copy" : "",
  "Display address" : "",
  "Google Maps address": "",
  // "Branch Ad" : "",
  // "Branch Start Date" : "",
  // "Branch End Date" : "",
  "Phase" : "",
  "Booking Link" : "",
  "Cuisine list" : "",
  "Is Featured" : "No",
  "Branch Image" : "",
  // "Branch Thumbnail" : "",
  "Branch Video" : "",
  // "Branch Experience" : "No",
  // "Exp Start Date" : "",
  // "Exp End Date" : "",
  // "Exp Name" : "",
  // "Exp Copy" : "",
  // "Exp Image Url" : "",
  // "Exp Thumbnail Url" : "",
  // "Exp Video Url" : ""
}];

export const legendColumnForBrand = [
  {
    "Field": "Brand Category",
    "Value": "Dining"
  },
  {
    "Field": "",
    "Value": "Health & Wellness"
  },
  {
    "Field": "",
    "Value": "Retail"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Auto Debit",
    "Value": "Optional (1%)"
  },
  {
    "Field": "",
    "Value": "Optional"
  },
  {
    "Field": "",
    "Value": "Mandatory"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Publisher",
    "Value": "Yes"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Invoice Preference",
    "Value": "Brand",
    "": "Publisher"
  },
  {
    "Field": "",
    "Value": "",
    "": "Branch"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Separate Invoice",
    "Value": "Publisher"
  },
  {
    "Field": "",
    "Value": "Brand"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Amex Phase",
    "Value": "3"
  },
  {
    "Field": "",
    "Value": "3.5"
  },
  {
    "Field": "",
    "Value": "4"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Wet venues",
    "Value": "Yes"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Cuisines",
    "Value": "Afternoon Tea"
  },
  {
    "Field": "",
    "Value": "American"
  },
  {
    "Field": "",
    "Value": "Argentinian"
  },
  {
    "Field": "",
    "Value": "Asian"
  },
  {
    "Field": "",
    "Value": "Bakery"
  },
  {
    "Field": "",
    "Value": "Bar"
  },
  {
    "Field": "",
    "Value": "Blurb"
  },
  {
    "Field": "",
    "Value": "British"
  },
  {
    "Field": "",
    "Value": "Brunch"
  },
  {
    "Field": "",
    "Value": "Burger"
  },
  {
    "Field": "",
    "Value": "Chinese"
  },
  {
    "Field": "",
    "Value": "Coffee"
  },
  {
    "Field": "",
    "Value": "European"
  },
  {
    "Field": "",
    "Value": "Fine Dining"
  },
  {
    "Field": "",
    "Value": "French"
  },
  {
    "Field": "",
    "Value": "Gastropub"
  },
  {
    "Field": "",
    "Value": "Healthy"
  },
  {
    "Field": "",
    "Value": "Indian"
  },
  {
    "Field": "",
    "Value": "International"
  },
  {
    "Field": "",
    "Value": "Italian"
  },
  {
    "Field": "",
    "Value": "Japanese"
  },
  {
    "Field": "",
    "Value": "Juice Bar"
  },
  {
    "Field": "",
    "Value": "Latin American"
  },
  {
    "Field": "",
    "Value": "Mediterranean"
  },
  {
    "Field": "",
    "Value": "Mexican"
  },
  {
    "Field": "",
    "Value": "Middle Eastern"
  },
  {
    "Field": "",
    "Value": "Peruvian"
  },
  {
    "Field": "",
    "Value": "Pizza"
  },
  {
    "Field": "",
    "Value": "Pub"
  },
  {
    "Field": "",
    "Value": "Quick Bites"
  },
  {
    "Field": "",
    "Value": "Scottish"
  },
  {
    "Field": "",
    "Value": "Seafood"
  },
  {
    "Field": "",
    "Value": "South American"
  },
  {
    "Field": "",
    "Value": "Spanish"
  },
  {
    "Field": "",
    "Value": "Steak"
  },
  {
    "Field": "",
    "Value": "Sunday Roast"
  },
  {
    "Field": "",
    "Value": "Sushi"
  },
  {
    "Field": "",
    "Value": "Sweet Treats"
  },
  {
    "Field": "",
    "Value": "Thai"
  },
  {
    "Field": "",
    "Value": "Vegetarian Friendly"
  },
  {
    "Field": "",
    "Value": "Online"
  }
];

export const legendColumnForGroup = [
  {
    "Field": "Brand Category",
    "Value": "Dining"
  },
  {
    "Field": "",
    "Value": "Health & Wellness"
  },
  {
    "Field": "",
    "Value": "Retail"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Auto Debit",
    "Value": "Optional (1%)"
  },
  {
    "Field": "",
    "Value": "Optional"
  },
  {
    "Field": "",
    "Value": "Mandatory"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Publisher",
    "Value": "Yes"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Invoice Preference",
    "Value": "Group",
    "": "Brand"
  },
  {
    "Field": "",
    "Value": "",
    "": "Publisher"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Invoice Preference",
    "Value": "Brand",
    "": "Publisher"
  },
  {
    "Field": "",
    "Value": "",
    "": "Branch"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Separate Invoice",
    "Value": "Publisher"
  },
  {
    "Field": "",
    "Value": "Brand"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Amex Phase",
    "Value": "3"
  },
  {
    "Field": "",
    "Value": "3.5"
  },
  {
    "Field": "",
    "Value": "4"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Wet venues",
    "Value": "Yes"
  },
  {
    "Field": "",
    "Value": "No"
  },
  {
    "Field": "",
    "Value": ""
  },
  {
    "Field": "Cuisines",
    "Value": "Afternoon Tea"
  },
  {
    "Field": "",
    "Value": "American"
  },
  {
    "Field": "",
    "Value": "Argentinian"
  },
  {
    "Field": "",
    "Value": "Asian"
  },
  {
    "Field": "",
    "Value": "Bakery"
  },
  {
    "Field": "",
    "Value": "Bar"
  },
  {
    "Field": "",
    "Value": "Blurb"
  },
  {
    "Field": "",
    "Value": "British"
  },
  {
    "Field": "",
    "Value": "Brunch"
  },
  {
    "Field": "",
    "Value": "Burger"
  },
  {
    "Field": "",
    "Value": "Chinese"
  },
  {
    "Field": "",
    "Value": "Coffee"
  },
  {
    "Field": "",
    "Value": "European"
  },
  {
    "Field": "",
    "Value": "Fine Dining"
  },
  {
    "Field": "",
    "Value": "French"
  },
  {
    "Field": "",
    "Value": "Gastropub"
  },
  {
    "Field": "",
    "Value": "Healthy"
  },
  {
    "Field": "",
    "Value": "Indian"
  },
  {
    "Field": "",
    "Value": "International"
  },
  {
    "Field": "",
    "Value": "Italian"
  },
  {
    "Field": "",
    "Value": "Japanese"
  },
  {
    "Field": "",
    "Value": "Juice Bar"
  },
  {
    "Field": "",
    "Value": "Latin American"
  },
  {
    "Field": "",
    "Value": "Mediterranean"
  },
  {
    "Field": "",
    "Value": "Mexican"
  },
  {
    "Field": "",
    "Value": "Middle Eastern"
  },
  {
    "Field": "",
    "Value": "Peruvian"
  },
  {
    "Field": "",
    "Value": "Pizza"
  },
  {
    "Field": "",
    "Value": "Pub"
  },
  {
    "Field": "",
    "Value": "Quick Bites"
  },
  {
    "Field": "",
    "Value": "Scottish"
  },
  {
    "Field": "",
    "Value": "Seafood"
  },
  {
    "Field": "",
    "Value": "South American"
  },
  {
    "Field": "",
    "Value": "Spanish"
  },
  {
    "Field": "",
    "Value": "Steak"
  },
  {
    "Field": "",
    "Value": "Sunday Roast"
  },
  {
    "Field": "",
    "Value": "Sushi"
  },
  {
    "Field": "",
    "Value": "Sweet Treats"
  },
  {
    "Field": "",
    "Value": "Thai"
  },
  {
    "Field": "",
    "Value": "Vegetarian Friendly"
  },
  {
    "Field": "",
    "Value": "Online"
  }
];