import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { getParentCampaignApiCall } from './../services/dashboard/getDashboardDataApiCall';
import { dashboardSelectors } from './../redux/dashboard';
import { useDispatch, useSelector } from 'react-redux';

const Modal = ({
    close,
    show,
    distributorModalData,
    allDistributors,
    callback,
    size = "lg",
    cancelText = "Cancel",
    hideSaveButton = false,
    centered = false
}) => {

    const dispatch = useDispatch();
    const [distibutorList, setDistibutorList] = useState(allDistributors);

    const [discountPercent, setDiscountPercent] = useState();
    const [successFee, setSuccessFee] = useState();
    const [max_campaign_cashback, setMax_campaign_cashback] = useState();
    const [max_transaction_cashback, setMax_transaction_cashback] = useState();
    const [recurring_campaign, setRecurring_campaign] = useState();
    const [is_apply_to_all, setIs_apply_to_all] = useState();
    const [parent_campaign_object_id, setParent_campaign_object_id] = useState();
    const [canEditData, setCanEditData] = useState(true);

    useEffect(() => {
        let todayDate = new Date();
        if (todayDate.getDate() >= 25) {
            setCanEditData(false);
        }
    }, []);

    const handleInputChangeNew = (e, index, filed) => {
        const { value } = e.target;
        const re = /^[0-9.\b]+$/;
        if (filed == "customer_type" || value === '' || (re.test(value) && parseInt(value) <= 100)) {
            const data1 = [...distibutorList]
            let data2 = { ...data1[index], [filed]: value };
            data1[index] = data2;
            setDistibutorList(data1);
        }
    };

    const parentCampaignState = useSelector(
        dashboardSelectors.getParentCampaignState
    );

    const handleCashbackChange = (e, filed) => {
        const { value } = e.target;
        const re = /^[0-9.\b]+$/;
        if (value === '' || (re.test(value) && parseInt(value) <= 100)) {

            if (filed == "discountPercent") {
                setDiscountPercent(value);
            } else if (filed == "successFee") {
                setSuccessFee(value);
            } else if (filed == "max_campaign_cashback") {
                setMax_campaign_cashback(value);
            } else if (filed == "max_transaction_cashback") {
                setMax_transaction_cashback(value);
            }
        }
    };

    const onFocusOutEvent = (e, filed) => {
        const { value } = e.target; 
        if (filed == "discountPercent") {            
            const data1 = [...distibutorList];
            data1.forEach(element => {
                element.percentage = value;
             });
            setDistibutorList(data1);
        } else if (filed == "successFee") {
            const data1 = [...distibutorList];
            data1.forEach(element => {
                element.successFee = value;
             });
            setDistibutorList(data1);
        }
    };

    const saveDistributor = () => {
        callback({
            rowData: distributorModalData, distibutorList: distibutorList, updataParam: {
                max_campaign_cashback: max_campaign_cashback,
                max_transaction_cashback: max_transaction_cashback,
                successFee: successFee,
                discountPercent: discountPercent,
                recurring_campaign: recurring_campaign,
                is_apply_to_all: is_apply_to_all,
                parent_campaign_object_id: parent_campaign_object_id
            }
        });
    }

    useEffect(() => {
        if (distributorModalData && distributorModalData.selectedEntity && distributorModalData.selectedEntity.distributors &&
            distributorModalData.selectedEntity.distributors.length > 0) {
            let list = [...distributorModalData.selectedEntity.distributors];
            list.forEach(element => {
                element.customer_type = element.customer_type || "All";
                if(!element.distributors_display_name) {
                    let index = allDistributors.filter(a => a.distributors_name == element.distributors_name);
                    if (index[0]) {
                        element.distributors_display_name = index[0].distributors_display_name;
                    }
                }                
            });
            allDistributors.forEach(element => {
                let index = list.findIndex(a => a.distributors_name == element.distributors_name);
                if (index == -1) {
                    element.customer_type = element.customer_type || "All";
                    element.percentage = distributorModalData.selectedEntity.discountPercent || "";
                    element.successFee = distributorModalData.selectedEntity.successFee || "";
                    list.push(element);
                }
            });
            console.log(list);
            setDistibutorList(list);
        } else if (distributorModalData && distributorModalData.selectedEntity) {
            let list = [...allDistributors];
            list.forEach(element => {
                element.customer_type = element.customer_type || "All";
                element.percentage = distributorModalData.selectedEntity.discountPercent || "";
                element.successFee = distributorModalData.selectedEntity.successFee || "";
            });
            setDistibutorList(list);
        }

        if (distributorModalData && distributorModalData.selectedEntity) {
            setDiscountPercent(distributorModalData.selectedEntity.discountPercent);
            setSuccessFee(distributorModalData.selectedEntity.successFee);
            setMax_campaign_cashback(distributorModalData.selectedEntity.max_campaign_cashback);
            setMax_transaction_cashback(distributorModalData.selectedEntity.max_transaction_cashback);
        }
        setIs_apply_to_all(true);

        if (distributorModalData && distributorModalData.selectedEntity && distributorModalData.selectedEntity.brandId) {
            dispatch(getParentCampaignApiCall(distributorModalData.selectedEntity.brandId));
        }

    }, [distributorModalData]);

    useEffect(() => {
        if (parentCampaignState.data && Object.keys(parentCampaignState.data).length > 0) {
            setRecurring_campaign(parentCampaignState.data.recurring);
            setParent_campaign_object_id(parentCampaignState.data._id);
        }
    }, [parentCampaignState.data]);

    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> Campaign Parameters </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>


                <div className="row col-12 form distributor-modal">
                    {/* {distibutorList.map(data =>
                        <div className="col-3 p-10">
                            <input type='checkbox' name={data.distributors_name}
                                defaultChecked={data.checked}
                                id={data.distributors_name}
                                onChange={(e) => {
                                    data.checked = e.target.checked;
                                }} 
                                />
                            <span className='m-l-5'> {data.distributors_name} </span>
                        </div>
                    )} */}

                    <div className="row col-12 form">
                        <div className="row col-4 cashback">
                            {/* Discount Percentage */}
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id={`discountPercent`}
                                    name={`discountPercent`}
                                    value={discountPercent}
                                    type="text" label="Discount Percentage"
                                    onChange={e => handleCashbackChange(e, "discountPercent")}
                                    onBlur={e => onFocusOutEvent(e, "discountPercent")}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>
                        <div className="row col-4 cashback">
                            {/* Success Fee */}
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id={`successFee`}
                                    name={`successFee`}
                                    value={successFee}
                                    type="text" label="Success Fee"
                                    onChange={e => handleCashbackChange(e, "successFee")}
                                    onBlur={e => onFocusOutEvent(e, "successFee")}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row col-12 form">
                        <div className="row col-4 cashback">
                            {/* Max Transaction Cashback */}
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id={`max_transaction_cashback`}
                                    name={`max_transaction_cashback`}
                                    value={max_transaction_cashback}
                                    type="text" label="Max Transaction Cashback"
                                    onChange={e => handleCashbackChange(e, "max_transaction_cashback")}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>
                        <div className="row col-4 cashback">
                            {/* Max Campaign Cashback */}
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id={`max_campaign_cashback`}
                                    name={`max_campaign_cashback`}
                                    value={max_campaign_cashback}
                                    type="text" label="Max Campaign Cashback"
                                    onChange={e => handleCashbackChange(e, "max_campaign_cashback")}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>
                    </div>

                    <div className="row col-12 form distributor-title">
                        Distributors :
                    </div>
                    <div className="row col-12 form distributor-box">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td> Distributor name </td>
                                    {parentCampaignState.is_two_customer &&
                                        <td> Customer type </td>
                                    }
                                    <td> Cashback % </td>
                                    <td> Success Fee % </td>
                                </tr>
                            </thead>
                            <tbody>
                                {distibutorList.map((row, index) => {
                                    return (
                                        <tr key={index + "distributors"} className="distributors-row">
                                            <td className="p-t-10"> {row.distributors_display_name}  </td>
                                            {parentCampaignState.is_two_customer &&
                                                <td className='p-t-10'>
                                                    {row.customer_type == "All" && "All"}
                                                    {row.customer_type == "NEW USER" && "New Customer"}
                                                    {row.customer_type == "REPEAT USER" && "Existing Customer"}
                                                </td>
                                                // <td> 
                                                //     <select
                                                //         name="customer_type"
                                                //         className="form-control dropdown"
                                                //         value={row.customer_type}
                                                //         onChange={(e) => {
                                                //             handleInputChangeNew(e, index, 'customer_type');
                                                //         }}>                                                    
                                                //         <option value={"All"} >All</option>
                                                //         <option value={"NEW USER"}>New Customer</option>
                                                //         <option value={"REPEAT USER"} >Existing Customer</option>
                                                //     </select>
                                                // </td>
                                            }
                                            <td>
                                                <FormControl className='w-100' variant="standard">
                                                    <TextField
                                                        value={row.percentage}
                                                        id={`distributors_name${index}`}
                                                        name={`distributors_name${index}`}
                                                        type="text"
                                                        className={`form-control mb-3`}
                                                        onChange={e => handleInputChangeNew(e, index, 'percentage')}
                                                    />
                                                </FormControl>
                                            </td>
                                            <td>
                                                <FormControl className='w-100' variant="standard">
                                                    <TextField
                                                        value={row.successFee}
                                                        id={`successFee${index}`}
                                                        name={`successFee${index}`}
                                                        type="text"
                                                        className={`form-control mb-3`}
                                                        onChange={e => handleInputChangeNew(e, index, 'successFee')}
                                                    />
                                                </FormControl>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>

                    {!hideSaveButton &&
                        <>
                            <div className="row col-12 form checkbox">
                                <input type='checkbox' name="recurring_campaign"
                                    defaultChecked={recurring_campaign}
                                    id="recurring_campaign"
                                    onChange={(e) => {
                                        setRecurring_campaign(e.target.checked);
                                    }}
                                />
                                <span className='m-l-5'> Recurring Campaign (Uncheck if brand has dropped off) </span>
                            </div>

                            <div className="row col-12 form checkbox">
                                <input type='checkbox' name="is_apply_to_all"
                                    defaultChecked={is_apply_to_all}
                                    id="is_apply_to_all"
                                    onChange={(e) => {
                                        setIs_apply_to_all(e.target.checked);
                                    }}
                                />
                                <span className='m-l-5'> Apply to all future campaigns </span>
                            </div>
                        </>
                    }


                </div>


            </ModalComponent.Body>
            {!hideSaveButton &&
                <ModalComponent.Footer>
                    <Button variant="default" onClick={close}>
                        {cancelText}
                    </Button>
                    <Button variant="success" disabled={!canEditData} onClick={(e) => { saveDistributor(); }}>
                        Save
                    </Button>
                </ModalComponent.Footer>
            }
        </ModalComponent>
    )
}

export default Modal