import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import config from './../config/config';
import Icon from '@material-ui/core/Icon';
import { checkPublisherActionCenterStatusApicall, CreateActionCenterApicall } from './../services/brandActionCenter/createBrandActionCenter';
import { brandActionCenterSelectors } from './../redux/brandActionCenter';
import { GetSessionValue } from './../utils/sessionStorage';
import { UPDATE_ACTIONS_CENTER } from '../redux/brandActionCenter/actionTypes';
import { error as errorNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from './../config';
import moment from 'moment';
import {
    groupColumnList, brandWithoutGroup, brandWithGroup, branchesColumnList, usersColumnList,
    legendColumnForBrand, legendColumnForGroup, whiteLabelBrandColumnList, whiteLabelBranchColumnList
} from './../utils/helpers';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    cancelText = "Cancel",
    centered = false,
    responseData,
    brandErrorModal,
    from = "",
    whitelabels,
    tempDistributorList
}) => {

    const dispatch = useDispatch();
    const [publisherOnboardedList, setPublisherOnboardedList] = useState([]);
    const inputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [response, setResponse] = useState(responseData);
    const [comments, setComments] = useState("");

    const updateActionCenterState = useSelector(
        brandActionCenterSelectors.updateActionCenterState
    );

    const downloadCAMLinks = () => {
        let brandsColumn = [];
        if (brandErrorModal.uploadFor == "brand" && brandErrorModal.matchedData) {
            brandErrorModal.matchedData.forEach(element => {
                if (element.users) {
                    let userDetails = element.users[0];
                    let userName = userDetails["First Name"];
                    userName = userDetails["Last Name"] ? userName + " " + userDetails["Last Name"] : userName;
                    let userEmail = userDetails["Email"];
                    brandsColumn.push({
                        "Brand Name": element.brandDetails["Brand Name"],
                        "User name": userName,
                        "Address": element.brandDetails["Address"],
                        "Onboarding": `${config.guestUrl}${userEmail}?type=campaign`,
                        "AMEX": `${config.guestUrl}${userEmail}?type=campaign&user=amex`,
                    });
                }
            });
        } else if (brandErrorModal.uploadFor == "group" && brandErrorModal.groups) {
            brandErrorModal.groups.forEach(element => {
                let userName = element["First Name"];
                userName = element["Last Name"] ? userName + " " + element["Last Name"] : userName;
                let userEmail = element["Email"];
                brandsColumn.push({
                    "Group Name": element["Group Name"],
                    "User name": userName,
                    "Onboarding": `${config.guestUrl}${userEmail}?type=campaign`,
                    "AMEX": `${config.guestUrl}${userEmail}?type=campaign&user=amex`,
                });
            });
        }
        const sheet = XLSX.utils.json_to_sheet(brandsColumn);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Links");
        XLSX.writeFile(workbook, new Date().getTime() + "-CAMLinks.xlsx");
    }

    useEffect(() => {

        if (from == "publisherOnboarded") {
            dispatch(checkPublisherActionCenterStatusApicall(brandErrorModal.selectedEntity, response => {
                if (response.respCode && response.list) {
                    setPublisherOnboardedList(response.list);
                }
            }));
        }
        if (from == "ErrorMessage" && brandErrorModal && brandErrorModal.selectedEntity && brandErrorModal.selectedEntity.comments) {
            setComments(brandErrorModal.selectedEntity.comments);
        }
    }, []);

    const downloadInvalidFile = () => {
        window.open(`${config.apiUrl}brandActionCenter/downloadFile/${brandErrorModal.selectedEntity._id}/invalidFile`, '_blank').focus();
    }

    const downloadBrandDetailFile = () => {
        window.open(`${config.apiUrl}brandActionCenter/downloadFile/${brandErrorModal.selectedEntity._id}/salesFile`, '_blank').focus();
    }

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
        let res = JSON.parse(JSON.stringify(response));
        res.errorCode = "";
        setResponse(res);
    }

    const clearErrorValue = e => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const removeSelectedFile = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const saveUploadedFile = () => {
        let userDetails = GetSessionValue('user');
        var formdata = new FormData();
        formdata.append('excelData', selectedFile);
        formdata.append('role', userDetails.role);
        formdata.append('uploadFor', brandErrorModal.selectedEntity.uploadFor);
        formdata.append('id', brandErrorModal.selectedEntity._id);
        formdata.append('comments', comments);
        setIsLoader(true);
        dispatch(CreateActionCenterApicall(formdata, UPDATE_ACTIONS_CENTER));
    }

    const clearData = () => {
        dispatch({
            type: UPDATE_ACTIONS_CENTER,
            payload: { data: {}, error: {}, loader: true }
        })
    }

    useEffect(() => {
        if (updateActionCenterState && updateActionCenterState.data && updateActionCenterState.data.respCode) {
            removeSelectedFile();
            clearData();
            callback(brandErrorModal.selectedEntity);
        } else if (updateActionCenterState.error && updateActionCenterState.error.errorCode) {
            setIsLoader(false);
            let notification = { ...notificationOpts };
            notification.message = "Invalid file submitted";
            dispatch(errorNotificationAction(notification));
            setResponse(updateActionCenterState.error);
        }
    }, [updateActionCenterState && updateActionCenterState.data]);

    const downloadTemplate = () => {

        let brandDetailBySales = {};
        if (brandErrorModal.selectedEntity.brandDetailBySales) {
            brandDetailBySales = JSON.parse(brandErrorModal.selectedEntity.brandDetailBySales);
        } else {
            brandDetailBySales.commonDetails = {};
        }

        const workbook = XLSX.utils.book_new();
        if (brandErrorModal.selectedEntity.uploadFor == "group") {
            let groupColumn = groupColumnList;
            groupColumn[0]["Group Name"] = brandErrorModal.selectedEntity.brandName;
            groupColumn[0]["First Name"] = brandDetailBySales.commonDetails.contactName;
            groupColumn[0]["Email"] = brandDetailBySales.commonDetails.contactEmail;
            groupColumn[0]["Phone"] = brandDetailBySales.commonDetails.contactPhone;
            groupColumn[0]["Invoice Email addresses"] = brandDetailBySales.commonDetails.emailAddresses;
            const sheet0 = XLSX.utils.json_to_sheet(groupColumn);
            XLSX.utils.book_append_sheet(workbook, sheet0, "Group");
        }

        let brandsColumn = [];
        if (brandErrorModal.selectedEntity.uploadFor == "brand") {
            brandsColumn = brandWithoutGroup;
        } else {
            brandsColumn = brandWithGroup;
        }

        tempDistributorList.forEach(e3 => {
            brandsColumn[0][e3.distributors_display_name] = "No";
            brandsColumn[0][e3.distributors_display_name + " Cashback"] = "";
            brandsColumn[0][e3.distributors_display_name + " SuccessFee"] = "";
            brandsColumn[0][e3.distributors_display_name + " Start Date"] = "";
        });

        if (brandDetailBySales.brandDetailList) {
            let obj = brandsColumn[0];
            let col = [];
            brandDetailBySales.brandDetailList.forEach(element => {
                let obj1 = JSON.parse(JSON.stringify(obj));
                if (brandErrorModal.selectedEntity.uploadFor == "group") {
                    obj1["Group Name"] = brandErrorModal.selectedEntity.brandName;
                }
                obj1["Address"] = element.address;
                obj1["Brand Name"] = element.brandName;
                obj1["Website URL"] = element.website;                 
                obj1["Comments"] = comments;

                if (element.selectedPublisher) {
                    element.selectedPublisher.forEach(e2 => {
                        let ds = tempDistributorList.filter(a => a.distributors_name == e2.distributors_name);
                        if(ds && ds.length > 0) {
                            obj1[ds[0].distributors_display_name + " SuccessFee"] = e2.successFee;
                            obj1[ds[0].distributors_display_name + " Cashback"] = e2.percentage;
                            obj1[ds[0].distributors_display_name + " Start Date"] = e2.startDate ? moment(new Date(e2.startDate)).format('DD-MM-YYYY') : "";
                            obj1[ds[0].distributors_display_name] = "Yes";
                        }
                    });
                }
                col.push(obj1);
            });
            brandsColumn = col;
        }

        const sheet = XLSX.utils.json_to_sheet(brandsColumn);
        XLSX.utils.book_append_sheet(workbook, sheet, "Brand");
        console.log("XLSX.utils", XLSX.utils);

        let branchesColumn = branchesColumnList;
        let brandNameForUser = "";
        if (brandDetailBySales.brandDetailList) {
            let obj = branchesColumnList[0];
            let col = [];
            brandDetailBySales.brandDetailList.forEach((element, index) => {
                element.branchList.forEach((e2, index2) => {

                    let cuisines = [];
                    if(e2.cuisines_list) {
                        e2.cuisines_list.forEach(element => {
                            cuisines.push(element.label);
                        });
                    }
                    let obj1 = JSON.parse(JSON.stringify(obj));
                    brandNameForUser = element.brandName;
                    obj1["Brand Name"] = element.brandName;
                    obj1["Branch Name"] = e2.branch_name || "BulkBranch" + index + index2;
                    obj1["Address"] = e2.address;
                    obj1["Amex Merchant ID"] = e2.amexMerchantID;
                    obj1["Visa/Mastercard Merchant ID"] = e2.merchantId;
                    obj1["Post code"] = e2.postcode;
                    obj1["Contact Name"] = brandDetailBySales.commonDetails.contactName;
                    obj1["Contact Phone"] = brandDetailBySales.commonDetails.contactPhone;
                    obj1["Email"] = brandDetailBySales.commonDetails.contactEmail;
                    obj1["Cuisine list"] = cuisines.join(", ");
                    obj1["Copy"] = e2.copy;
                    col.push(obj1);
                });
            });
            branchesColumn = col;
        }
        const sheet2 = XLSX.utils.json_to_sheet(branchesColumn);
        XLSX.utils.book_append_sheet(workbook, sheet2, "Branch");

        if (whitelabels && whitelabels.length > 0) {
            let list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBrandColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4)
            });
            const sheet6 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBranchColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4);
            });
            const sheet7 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");

        } else {
            const whiteLabelBrandColumn = whiteLabelBrandColumnList;
            const sheet6 = XLSX.utils.json_to_sheet(whiteLabelBrandColumn);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            const whiteLabelBranchColumn = whiteLabelBranchColumnList;
            const sheet7 = XLSX.utils.json_to_sheet(whiteLabelBranchColumn);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");
        }

        if (brandErrorModal.selectedEntity.uploadFor == "brand") {
            let usersColumn = usersColumnList;
            usersColumn[0]["Brand Name"] = brandNameForUser;
            usersColumn[0]["First Name"] = brandDetailBySales.commonDetails.contactName;
            usersColumn[0]["Email"] = brandDetailBySales.commonDetails.contactEmail;
            usersColumn[0]["Phone"] = brandDetailBySales.commonDetails.contactPhone;
            usersColumn[0]["Invoice Email addresses"] = brandDetailBySales.commonDetails.emailAddresses;
            const sheet3 = XLSX.utils.json_to_sheet(usersColumn);
            XLSX.utils.book_append_sheet(workbook, sheet3, "User");
        }

        let legendColumn = [];
        if (brandErrorModal.selectedEntity.uploadFor == "brand") {
            legendColumn = legendColumnForBrand;
        } else {
            legendColumn = legendColumnForGroup;
        }

        const sheet4 = XLSX.utils.json_to_sheet(legendColumn);
        XLSX.utils.book_append_sheet(workbook, sheet4, "Legend");
        XLSX.writeFile(workbook, new Date().getTime() + "-" + brandErrorModal.selectedEntity.uploadFor + "-BulkUploadSample.xlsx");
    }

    return (
        <>
            {from == "ErrorMessage" &&
                <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                    <ModalComponent.Header closeButton>
                        <ModalComponent.Title>
                            {brandErrorModal.isUploadedData && "Download"}
                            {/* {!brandErrorModal.isUploadedData && "Invalid file details"} */}
                            {!brandErrorModal.isUploadedData && "Review and Upload file"}
                        </ModalComponent.Title>
                    </ModalComponent.Header>
                    <ModalComponent.Body>

                        {isLoader && <div className="loading">Loading...</div>}
                        <div className="row col-12 form bulk-brand-upload-modal">

                            {!brandErrorModal.isUploadedData &&
                                <div className="row col-12 form">

                                    {response && response.isInValid &&
                                        <>
                                            <div className="row col-12">

                                                <div className="col-4">
                                                    <FormControl className='w-100' variant="standard">
                                                        <TextField
                                                            id='comments'
                                                            name='comments'
                                                            value={comments}
                                                            type="text" label="Comments"
                                                            onChange={e => { setComments(e.target.value) }}
                                                            className={`form-control mb-3`} />
                                                    </FormControl>
                                                </div>

                                                <div className="col-4 m-t-10">
                                                    <button className="btn btn-primary" style={{ verticalAlign: 'bottom' }} onClick={downloadTemplate} >
                                                        Download {brandErrorModal.selectedEntity.uploadFor == 'group' ? 'Group' : 'Brand'} Template
                                                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                                    </button>
                                                </div>

                                                {brandErrorModal.selectedEntity.salesFileName &&
                                                    <div className="col-4 m-t-10">
                                                        {/* <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={downloadInvalidFile} >
                                                    Download and Review File
                                                    <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                                </button> */}
                                                        <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={downloadBrandDetailFile} >
                                                            Download Detail File
                                                            <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                                        </button>
                                                    </div>
                                                }

                                            </div>

                                            <div className="row col-12 m-t-15">
                                                <div className="col-2">
                                                    <div class="bulk-upload-btn-wrapper">
                                                        <button className="btn btn-primary">
                                                            Upload
                                                            <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                                                        </button>
                                                        <input
                                                            name="bulk-upload" id="bulk-upload" ref={inputRef}
                                                            accept=".xls,.xlsx" type='file'
                                                            onClick={clearErrorValue} onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-10 file-name">
                                                    {selectedFile && selectedFile.name &&
                                                        <>
                                                            <div className='file-label'> <span>{selectedFile.name}</span> </div>
                                                            <div className='file-delete' onClick={removeSelectedFile} >
                                                                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {response && response.errorCode && response.isInValid &&
                                        <>
                                            {Object.keys(response.data).map((a, index) => {
                                                return (
                                                    <div className="col-12 invalid-file" key={index + "invalidData"}>
                                                        {response.data[a]}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                    {response && response.errorCode &&
                                        <div className="col-12 invalid-file">
                                            {response.errorMessage}
                                        </div>
                                    }

                                </div>
                            }

                            {brandErrorModal.isUploadedData &&
                                <div className="row col-12 form">

                                    <div className="col-4">
                                        <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={downloadCAMLinks} >
                                            Download CAM Links
                                            <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                        </button>
                                    </div>

                                </div>
                            }

                        </div>

                    </ModalComponent.Body>
                    <ModalComponent.Footer>
                        <Button variant="default" onClick={close}>
                            {cancelText}
                        </Button>
                        {!brandErrorModal.isUploadedData &&
                            <Button variant="success" onClick={() => saveUploadedFile()} disabled={!selectedFile || !selectedFile.name} >
                                Save
                            </Button>
                        }
                    </ModalComponent.Footer>
                </ModalComponent>
            }

            {from == "publisherOnboarded" &&
                <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                    <ModalComponent.Header closeButton>
                        <ModalComponent.Title>
                            Publisher Onboard Status
                        </ModalComponent.Title>
                    </ModalComponent.Header>
                    <ModalComponent.Body>

                        {isLoader && <div className="loading">Loading...</div>}
                        <div className="row col-12 form bulk-brand-upload-modal">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <td> Publisher Name </td>
                                        <td> Onboarded </td>
                                        <td> Requested Date </td>
                                        <td> Effective From </td>
                                        <td> Comments </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {publisherOnboardedList.map((row, index) => {
                                        return (
                                            <tr key={index + "distributors_name"} className="distributors-row">
                                                <td> {row.distributors_name}  </td>
                                                <td> {row.status == "New" ? "Pending" : "Completed"} </td>
                                                <td> {row.requestedDate ? moment(new Date(row.requestedDate)).format('YYYY-MM-DD hh:mm A') : ""}  </td>
                                                <td> {row.effectiveFrom ? moment(new Date(row.effectiveFrom)).format('YYYY-MM-DD hh:mm A') : ""}  </td>
                                                <td> {row.comments} </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </ModalComponent.Body>
                    <ModalComponent.Footer>
                        <Button variant="default" onClick={close}>
                            {cancelText}
                        </Button>
                    </ModalComponent.Footer>
                </ModalComponent>
            }


        </>
    )
}

export default Modal