import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { branchNameRegex, brandNameRegex, charandnumericRegex, contactNameRegex, emailRegex, onlycharRegex, phoneRegex, postcodeRegex } from '../../utils/constants/constant';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import configMessages from '../../config/configMessages';
import * as yup from 'yup';
import { CreateBranchApiCall } from '../../services/newBrandBranch/createBranchApiCall';
import { success as successNotificationAction, error as errorNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import { checkBrandExistApiCall, getCuisines, onboardUserAfterPublisherSubmit, sendCampaignCreatedEmail } from '../../services/newBrandBranch/checkBrandExistApiCall';
import Icon from '@material-ui/core/Icon';
import { deleteBranchByIdApiCall } from '../../services/newBrandBranch/deleteBranchByIdApiCall';
import ModalComponent from '../../components/Modal'
import BranchSelect from './BranchSelect';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import { GetSessionValue } from '../../utils/sessionStorage';
import CampaignApproveByPublisherModal from '../../components/CampaignApproveByPublisherModal';
import { useHistory } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from 'react-router-dom'

// export default function BranchCreate(props) {
const BranchCreate = (props) => {

    console.log("---------brandData-----------", props)
    const dispatch = useDispatch();
    const history = useHistory();

    const { brand_id, brandname } = useParams();

    const createBranchstate = useSelector(
        newBrandBranchSelectors.createBranchstate
    );

    const existingBrand = useSelector(
        newBrandBranchSelectors.getExistingBrandState
    );

    const deleteBranchstate = useSelector(
        newBrandBranchSelectors.deleteBranchstate
    );

    const [selectedBrand, setSelectedBrand] = useState(props.brandDetails);
    const [brandRowData, setBrandRowData] = useState(props.brandRowData);
    const [selectedBranchList, setSelectedBranchList] = useState([]);
    const [isValidate, setisValidate] = useState(false);
    const [deleteDialog, setdeleteDialog] = useState({ data: {}, isOpen: false });
    const [fieldError, setfieldError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const refList = useRef(selectedBranchList.map(() => createRef()));
    const [userDetails, setUserDetails] = useState({});
    const [approveModalData, setApproveModalData] = useState({ showApproveModal: false, data: {} });
    const [allCuisinesList, setAllCuisinesList] = useState([]);

    const [overrideStrings, setOverrideStrings] = useState({
        "selectSomeItems": "Select Cuisines",
        "allItemsAreSelected": "All Cuisines are selected.",
        "selectAll": "All Cuisines",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const [whitelabelsStrings, setWhitelabelsStrings] = useState({
        "selectSomeItems": "Select Partner",
        "allItemsAreSelected": "All Partner are selected.",
        "selectAll": "All Publisher",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    useEffect(() => {
        console.log("createBranchstate", createBranchstate)
        let userDetails = GetSessionValue('user');
        if (createBranchstate.error.errorCode) {
            showMessage(createBranchstate.error.errorMessage, "error")
        }
        if (userDetails.role != "bank" && createBranchstate.data.respCode) {
            showMessage(createBranchstate.data.respMessage, "success")
            if (existingBrand.data.brands.length > 0) {
                let { brandname, brandId } = existingBrand.data.brands[0]
                dispatch(checkBrandExistApiCall({ brandName: brandname, brandId: brandId }))
            }
        } else if (userDetails.role == "bank" && createBranchstate.data.respCode) {
            showMessage(createBranchstate.data.respMessage, "success");
            let { brandId } = existingBrand.data.brands[0];
            dispatch(onboardUserAfterPublisherSubmit({ brandId: brandId }, response => {
                if (response && response.data && response.data.respCode) {
                    setApproveModalData({ showApproveModal: true, data: response.data.data });
                }
            }));

        }
        setUserDetails(userDetails);
    }, [createBranchstate]);

    useEffect(() => {
        console.log("deleteBranchstate", deleteBranchstate)
        if (deleteBranchstate.error.errorCode) {
            showMessage(deleteBranchstate.error.errorMessage, "error")
        }
        if (deleteBranchstate.data.respCode) {
            showMessage(deleteBranchstate.data.respMessage, "success")
            if (existingBrand.data.brands.length > 0) {
                let { brandname, brandId } = existingBrand.data.brands[0]
                dispatch(checkBrandExistApiCall({ brandName: brandname, brandId: brandId }))
            }
        }
    }, [deleteBranchstate]);

    useEffect(() => { 
        dispatch(getCuisines(response => { 
            setAllCuisinesList(response);
        }));
    }, []);

    const handleInputChangeNew = (e, index, type) => {
        const { value } = e.target;
        const data1 = JSON.parse(JSON.stringify(selectedBranchList))
        const data2 = { ...data1[index], [type]: value }
        data1[index] = data2;
        // data1.forEach(element => {
        //     if (element.selectedWhiteLabelPlatforms) {
        //         element.selectedWhiteLabelPlatforms.forEach(e2 => {
                    // e2.branchStartDate = e2.branchStartDate ? new Date(e2.branchStartDate) : "";
                    // e2.branchEndDate = e2.branchEndDate ? new Date(e2.branchEndDate) : "";
                    // if (element.branchExperience && element.branchExperience.expStartDate) {
                    //     element.branchExperience.expStartDate = new Date(element.branchExperience.expStartDate);
                    // }
                    // if (element.branchExperience && element.branchExperience.expEndDate) {
                    //     element.branchExperience.expEndDate = new Date(element.branchExperience.expEndDate);
                    // }
        //         });
        //     }
        // });
        setSelectedBranchList(data1);
    };

    const handleCuisines = (value, index, type) => {
        const data1 = JSON.parse(JSON.stringify(selectedBranchList))
        const data2 = { ...data1[index], [type]: value }
        data1[index] = data2;
        // data1.forEach(element => {
            // if (element.selectedWhiteLabelPlatforms) {
                // element.selectedWhiteLabelPlatforms.forEach(e2 => {
                    // e2.branchStartDate = e2.branchStartDate ? new Date(e2.branchStartDate) : "";
                    // e2.branchEndDate = e2.branchEndDate ? new Date(e2.branchEndDate) : "";
                    // if (element.branchExperience && element.branchExperience.expStartDate) {
                    //     element.branchExperience.expStartDate = new Date(element.branchExperience.expStartDate);
                    // }
                    // if (element.branchExperience && element.branchExperience.expEndDate) {
                    //     element.branchExperience.expEndDate = new Date(element.branchExperience.expEndDate);
                    // }
                // });
            // }
        // });
        setSelectedBranchList(data1)
    };

    const inputField = (row, placeholder, type, regex, index, required, isdisabled) => {
        let iserror = false
        let helperText = ""
        if (fieldError[index]) {
            if (fieldError[index][type]) {
                iserror = true;
                helperText = fieldError[index][type]
            }
        }
        return <>
            <FormControl className='w-100' variant="standard">
                <TextField
                    autoComplete='off'
                    disabled={isdisabled}
                    value={row[type]}
                    id={`branchid${type}${index}`}
                    name={`branchname${type}${index}`}
                    type="text" label={placeholder}
                    required={required}
                    // error={isValidate && ((required ? !row[type] : false) || (regex && !regex.test(row[type])))}
                    error={isValidate && iserror}
                    className={`form-control mb-3`}
                    onChange={e => handleInputChangeNew(e, index, type)}
                // helperText={helperText}
                // aria-describedby={`branchid${type}${index}`}
                />
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
            </FormControl>
        </>
    }

    const scrollToBottom = (res, index) => {
        setTimeout(() => {
            refList.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }, 200);
    }

    const createEntity = async () => {
        let branchRowData = selectedBranchList;
        let schema =
            yup.object({
                items: yup.array().of(
                    yup.object().shape({
                        address: yup.string().required("Address is a required"),
                        contactEmail: yup.string()
                            // .required('Email Id is required')
                            .email(configMessages.validEmail),
                        name: yup.string()
                            // .matches(branchNameRegex, "Please enter valid Branch Name")
                            .required(`Branch ${configMessages.name}`),
                        // .min(3, `Branch ${configMessages.minName}`)
                        // .max(100, `Branch ${configMessages.maxName}`),
                        postcode: yup.string()
                            // .matches(postcodeRegex, `Invalid Postcode`)
                            .required(configMessages.postCode),
                        num_reviews: yup.string().required("Number of reviews is required"),
                        price_range: yup.string().required("Price range is required"),
                        rating: yup.string().required("Restaurant rating is required"),
                        tp_rank: yup.string(),
                        copy: yup.string(),
                        cuisines_list: yup.array().required(`Please select cuisines list`),
                        selectedWhiteLabelPlatforms: yup.array().of(
                            yup.object().shape({
                                branchName: yup.string().required("Branch name is required"),
                                branchCopy: yup.string().required("Branch copy is required"),
                                displayAddress: yup.string().required("Display address is required"),
                                gmap_address: yup.string().required("Google Maps address is required"),
                                // branchStartDate: yup.date().typeError('Branch Start Date is required').required("Branch Start Date is required"),
                                // branchEndDate: yup.date().typeError('Branch End Date is required').required("Branch End Date is required"),
                                branchPhase: yup.array().required("Phase is required"),
                                // bookingLink: yup.string().required("Booking Link is required"),
                                cuisines_list: yup.array().required(`Please select cuisines list`),
                            })
                        )
                    })
                )
            })

        let finalArr = []
        branchRowData.forEach(async (res, index) => {
            let obj = {
                address: res.address,
                tripadvisorUrl: res.tripadvisorUrl,
                contactName: res.contactName,
                contactPhone: res.contactPhone ? res.contactPhone : undefined,
                contactEmail: res.contactEmail || "",
                mastercardMerchantID: res.mastercardMerchantID,
                name: res.name,
                amexMerchantID: res.amexMerchantID,
                zettleMerchantID: res.zettleMerchantID,
                postalTown: res.postalTown,
                postcode: res.postcode,
                num_reviews: res.num_reviews,
                price_range: res.price_range,
                rating: res.rating,
                tp_rank: res.tp_rank || "",
                copy: res.copy || "",
                cuisines_list: res.cuisines_list,
                selectedWhiteLabelPlatforms: res.selectedWhiteLabelPlatforms
            }
            finalArr.push(obj);

            schema.isValid({ items: [obj] }).then(async (res1) => {
                if (!res1) {
                    console.log(res1, res)
                    scrollToBottom(res, index)
                }
            })
        })

        try {
            schema.validate({ items: finalArr }, { abortEarly: false }).then(res => {
                setfieldError({})
            }, (e) => {
                console.log(JSON.stringify(e));
                if (e.inner.length > 0) {
                    let errorMsg = {}
                    e.inner.forEach(res => {
                        let fieldName = res.path.split(".");
                        let indexValue = fieldName[0];
                        ["items", "[", "]"].forEach(res => {
                            indexValue = indexValue.replace(res, '')
                        })

                        if (fieldName[1] && fieldName[1].indexOf("[") != -1) {
                            let subFieldName = fieldName[1].split("[")[0];
                            let subIndexValue = fieldName[1];
                            ["selectedWhiteLabelPlatforms", "[", "]"].forEach(res => {
                                subIndexValue = subIndexValue.replace(res, '')
                            })

                            if (errorMsg[indexValue]) {
                                if (!errorMsg[indexValue][subFieldName]) errorMsg[indexValue][subFieldName] = {};
                                if (!errorMsg[indexValue][subFieldName][subIndexValue]) errorMsg[indexValue][subFieldName][subIndexValue] = {};
                                errorMsg[indexValue][subFieldName][subIndexValue][fieldName[2]] = res.message;
                            } else {
                                errorMsg[indexValue] = {}
                                errorMsg[indexValue][subFieldName] = {};
                                errorMsg[indexValue][subFieldName][subIndexValue] = {};
                                errorMsg[indexValue][subFieldName][subIndexValue][fieldName[2]] = res.message;
                            }
                        } else {
                            if (errorMsg[indexValue]) {
                                if (!errorMsg[indexValue][fieldName[1]]) {
                                    errorMsg[indexValue][fieldName[1]] = res.message
                                }
                            } else {
                                errorMsg[indexValue] = {}
                                errorMsg[indexValue][fieldName[1]] = res.message
                            }
                        }
                    })
                    setfieldError(errorMsg)
                }
            });

        } catch (e) {
            console.log(e)
        }
        
        let notFoundkBranchName = false;
        branchRowData.forEach(e7 => {
            if(e7.selectedWhiteLabelPlatforms) {
                e7.selectedWhiteLabelPlatforms.forEach(e8 => {
                    if(e8.branchAssets) {
                        let flag = false;
                        for (let index = 0; index < e8.branchAssets.length; index++) {
                            const e9 = e8.branchAssets[index];
                            if(e9.branchImage) {
                                flag = true;
                                break;
                            }
                        }
                        if(!flag) notFoundkBranchName = true;
                    }
                });
            }
        });

        if(notFoundkBranchName) {
            showMessage("Please add the branch image for the branch", "error");
            return;
        }
       
        await schema
            .isValid({ items: finalArr }).then(async (res) => {
                if (res) {
                    let selectedBranch = branchRowData.map(res => {
                        res.brandObjId = existingBrand.data.brands[0]._id
                        return res
                    })
                    setIsSubmitting(true);

                    async function createBranchOnNode(selectedBranch, index) {

                        // let url = "https://uo1ipaqymb.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
                        let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
                        // let x_api_key = "52SIj1lUmY4u4B6mhyp9p8zrZL3Ko8PtaJM0FcQ8"; 
                        let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";


                        let element = selectedBranch[index];

                        let body = {
                            "branch_name": element.name || "",
                            "entity_url": element.entity_url || "",
                            "brandname": element.brandName || "",
                            "brand_id": element.brandId || element.brand_id || "",
                            "city": element.city || "",
                            "description": element.description || "",
                            "address": element.address || "",
                            "merchantId": element.mastercardMerchantID || "",
                            "amexMerchantID": element.amexMerchantID || "",
                            "zettleMerchantID": element.zettleMerchantID || "",
                            "postalTown": element.postalTown || "",
                            "postcode": element.postcode || "",
                            "tripadvisorUrl": element.tripadvisorUrl || "",
                            "sector_id": '16',
                            "cuisines_list": [],
                            "kmid": element.kmid || "",
                            "num_reviews": element.num_reviews,
                            "price_range": element.price_range,
                            "rating": element.rating,
                            "tp_rank": element.tp_rank,
                            "copy": element.copy
                        };

                        let res = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
                        index++;
                        element.kmid = res.data && res.data[0] && res.data[0].kmid;
                        if (index == selectedBranch.length) {
                            selectedBranch.forEach(element => {
                                if (element.cuisines_list) {
                                    let list = [];
                                    element.cuisines_list.forEach(e2 => {
                                        list.push(e2.value);
                                    });
                                    element.cuisines_list = list.join(", ");
                                }

                                element.merchantIds = {};
                                element.merchantIds.mastercardMerchantID = element.mastercardMerchantID || "";
                                element.merchantIds.amexMerchantID = element.amexMerchantID || "";
                                element.merchantIds.zettleMerchantID = element.zettleMerchantID || "";
                                delete element.mastercardMerchantID;
                                delete element.amexMerchantID;
                                delete element.zettleMerchantID;

                                let whitelabelData = {};
                                element.selectedWhiteLabelPlatforms.forEach(element => {

                                    if (element.cuisines_list) {
                                        let list = [];
                                        element.cuisines_list.forEach(e2 => {
                                            list.push(e2.value);
                                        });
                                        element.cuisines_list = list.join(", ");
                                    }

                                    let key = element.partnerSerialNo;
                                    whitelabelData[key] = {};
                                    whitelabelData[key].branchName = element.branchName;
                                    whitelabelData[key].branchCopy = element.branchCopy;
                                    whitelabelData[key].displayAddress = element.displayAddress;
                                    whitelabelData[key].gmap_address = element.gmap_address;
                                    // whitelabelData[key].branchStartDate = new Date(element.branchStartDate);
                                    // whitelabelData[key].branchEndDate = new Date(element.branchEndDate);
                                    whitelabelData[key].bookingLink = element.bookingLink;
                                    whitelabelData[key].cuisines_list = element.cuisines_list || [];

                                    whitelabelData[key].isFeatured = element.isFeatured;
                                    whitelabelData[key].isBranchLive = element.isBranchLive;
                                    whitelabelData[key].branchAssets = element.branchAssets;
                                    // if (element.isBranchExperience) {
                                    //     whitelabelData[key].branchExperience = element.branchExperience;
                                    // }

                                    whitelabelData[key].branchPhase = [];
                                    if (element.branchPhase) {
                                        element.branchPhase.forEach(e2 => {
                                            whitelabelData[key].branchPhase.push(e2.value);
                                        });
                                    }
                                });
                                delete element.selectedWhiteLabelPlatforms;
                                element.whitelabel = whitelabelData;

                            });
                            dispatch(CreateBranchApiCall(selectedBranch, "branchCreate"));
                            setIsSubmitting(false);
                        } else {
                            createBranchOnNode(selectedBranch, index);
                        }
                    }

                    createBranchOnNode(selectedBranch, 0);

                } else {
                    setisValidate(true)
                }
            })
    }

    const handleDeleteBranch = () => {
        dispatch(deleteBranchByIdApiCall(deleteDialog.data._id))
        setdeleteDialog({ data: {}, isOpen: false })
    }

    const campaignApproved = () => {
        history.push("/user/dashboard");
        if (existingBrand.data.brands.length > 0) {
            let { brandId } = existingBrand.data.brands[0];
            dispatch(sendCampaignCreatedEmail({ brandId: brandId, period: approveModalData.data.period }, response => { }));
        }
        setApproveModalData({ showApproveModal: false, data: {} });
    }

    const customValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple cuisine selected" : "Select Cuisine"
        }
    };

    const whitelabelsRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Partner selected" : "Select Partner"
        }
    };

    const whiteLabelHandleInputChange = (e, index, w_index, type) => {
        const { value, checked } = e.target;
        const data1 = [...selectedBranchList];
        let data2 = {};
        if (type == "isFeatured" || type == "isBranchExperience" || type == "isBranchLive") {
            data2 = { ...data1[index].selectedWhiteLabelPlatforms[w_index], [type]: checked };
        } else {
            data2 = { ...data1[index].selectedWhiteLabelPlatforms[w_index], [type]: value };
        }
        data1[index].selectedWhiteLabelPlatforms[w_index] = data2;
        setSelectedBranchList(data1);
    };

    const whiteLabelDate = (value, index, w_index, type) => {
        const data1 = [...selectedBranchList];
        let data2 = { ...data1[index].selectedWhiteLabelPlatforms[w_index], [type]: value };
        data1[index].selectedWhiteLabelPlatforms[w_index] = data2;
        setSelectedBranchList(data1);
    };

    const whiteLabelInputField = (row, placeholder, type, index, w_index, required, controlType) => {

        let helperText = "";
        if (fieldError && fieldError[index] && fieldError[index].selectedWhiteLabelPlatforms &&
            fieldError[index].selectedWhiteLabelPlatforms[w_index] && fieldError[index].selectedWhiteLabelPlatforms[w_index][type]) {
            helperText = fieldError[index].selectedWhiteLabelPlatforms[w_index][type];
        }

        return <>
            <FormControl className='w-100' variant="standard">

                {controlType == "textbox" &&
                    <TextField
                        value={row[type]}
                        id={`brandid${type}${w_index}`}
                        name={`brandname${type}${w_index}`}
                        type="text" label={placeholder}
                        required={required}
                        error={helperText}
                        className={`form-control mb-3`}
                        onChange={e => whiteLabelHandleInputChange(e, index, w_index, type)}
                    />
                }

                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const whiteLabelPhase = (row, placeholder, type, index, w_index, list, required) => {
        row[type] = row[type] || [];
        list = list || [];
        list.sort((a, b) => (parseFloat(a.phaseNumber) > parseFloat(b.phaseNumber) ? -1 : 1))
        list.forEach(element => {
            element.value = element.phaseNumber;
            element.label = element.phaseNumber;
            if (new Date(element.phaseEndDate) < new Date()) {
                element.disabled = true;
            }
        });

        let helperText = "";
        if (fieldError && fieldError[index] && fieldError[index].selectedWhiteLabelPlatforms &&
            fieldError[index].selectedWhiteLabelPlatforms[w_index] && fieldError[index].selectedWhiteLabelPlatforms[w_index][type]) {
            helperText = fieldError[index].selectedWhiteLabelPlatforms[w_index][type];
        }

        return <>
            <FormControl className='w-100' variant="standard">
                <>
                    <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                    <MultiSelect
                        className='multi-select'
                        options={list}
                        value={row[type]}
                        onChange={(value) => {
                            whiteLabelDate(value, index, w_index, type);
                        }}
                        labelledBy={"Select Phase"} required={true}
                        overrideStrings={whitelabelsStrings}
                        valueRenderer={whitelabelsRenderer} />
                </>
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const whiteLabelDateField = (row, placeholder, type, index, w_index, required) => {

        let helperText = "";
        if (fieldError && fieldError[index] && fieldError[index].selectedWhiteLabelPlatforms &&
            fieldError[index].selectedWhiteLabelPlatforms[w_index] && fieldError[index].selectedWhiteLabelPlatforms[w_index][type]) {
            helperText = fieldError[index].selectedWhiteLabelPlatforms[w_index][type];
        }

        return <>
            <div className="form-group pl-0 mb-0 w-100">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label={placeholder}
                        value={row[type] ? new Date(row[type]) : ""}
                        slotProps={{ textField: { required: required } }}
                        onChange={(newValue) => whiteLabelDate(newValue, index, w_index, type)}
                        minDate={row.minStartDate ? new Date(row.minStartDate) : ""}
                        maxDate={row.maxEndDate ? new Date(row.maxEndDate) : ""}
                        format="dd-MM-yyyy" />
                </LocalizationProvider>
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
            </div>
        </>
    }

    const imageContainControls = (row, placeholder, type, index, w_index, i_index) => {
        return <>
            <FormControl className='w-100' variant="standard">
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`imagebranchid${type}${index}`}
                    name={`imagebranchname${type}${index}`}
                    type="text" label={placeholder}
                    className={`form-control mb-3`}
                    onChange={e => updateImageContainRowValue(e, type, index, w_index, i_index)}
                />
            </FormControl>
        </>
    }

    const updateImageContainRowValue = (e, type, index, w_index, i_index) => {
        const { value } = e.target;
        let list = [...selectedBranchList];
        let obj = { ...list[index].selectedWhiteLabelPlatforms[w_index].branchAssets[i_index], [type]: value };
        list[index].selectedWhiteLabelPlatforms[w_index].branchAssets[i_index] = obj;
        setSelectedBranchList(list);
    }

    const addImageContainRow = (index, w_index) => {
        let list = [...selectedBranchList];
        list[index].selectedWhiteLabelPlatforms[w_index].branchAssets.push({ branchImage: "", branchVideo: "" });
        setSelectedBranchList(list);
    }

    const removeImageContainRow = (index, w_index, image_index) => {
        let list = [...selectedBranchList];
        list[index].selectedWhiteLabelPlatforms[w_index].branchAssets.splice(image_index, 1);
        setSelectedBranchList(list);
    }


    const expControls = (row, placeholder, type, index, w_index, exp_index) => {
        return <>
            <FormControl className='w-100' variant="standard">
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`expid${type}${index}${exp_index}`}
                    name={`expname${type}${index}${exp_index}`}
                    type="text" label={placeholder}
                    className={`form-control mb-3`}
                    onChange={e => updateExpControlsValue(e, type, index, w_index, exp_index)}
                />
            </FormControl>
        </>
    }

    const updateExpControlsValue = (e, type, index, w_index, exp_index) => {
        const { value } = e.target;
        let list = [...selectedBranchList];
        if (exp_index === "") {
            let obj = { ...list[index].selectedWhiteLabelPlatforms[w_index].branchExperience, [type]: value };
            list[index].selectedWhiteLabelPlatforms[w_index].branchExperience = obj;
        } else {
            let obj = { ...list[index].selectedWhiteLabelPlatforms[w_index].branchExperience.expAssets[exp_index], [type]: value };
            list[index].selectedWhiteLabelPlatforms[w_index].branchExperience.expAssets[exp_index] = obj;
        }
        setSelectedBranchList(list);
    }

    const updateExpDateValue = (value, index, w_index, type) => {
        let list = [...selectedBranchList];
        let obj = { ...list[index].selectedWhiteLabelPlatforms[w_index].branchExperience, [type]: value };
        list[index].selectedWhiteLabelPlatforms[w_index].branchExperience = obj;
        setSelectedBranchList(list);
    }

    const addExpRow = (index, w_index) => {
        let list = [...selectedBranchList];
        list[index].selectedWhiteLabelPlatforms[w_index].branchExperience.expAssets.push({
            "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": ""
        });
        setSelectedBranchList(list);
    }

    const removeExpRow = (index, w_index, exp_index) => {
        let list = [...selectedBranchList];
        list[index].selectedWhiteLabelPlatforms[w_index].branchExperience.expAssets.splice(exp_index, 1);
        setSelectedBranchList(list);
    };

    const toggleRow = (index) => {
        const data1 = [...selectedBranchList];
        const data2 = { ...data1[index], isHideRow: !data1[index].isHideRow };
        data1[index] = data2;
        setSelectedBranchList(data1);
    }

    return (
        <>
            <ModalComponent
                close={() => {
                    setdeleteDialog({ data: {}, isOpen: false })
                }}
                callback={handleDeleteBranch}
                show={deleteDialog.isOpen}
                title={<span>Delete <b>Branch</b></span>}
                message={<span>Are you sure you want to delete the <b>Branch</b> ?</span>}
                action={"Delete"}
                actionType={'danger'}
            />

            <CampaignApproveByPublisherModal close={() => campaignApproved()}
                callback={() => campaignApproved()}
                data={approveModalData.data}
                show={approveModalData.showApproveModal} />

            <div className="card">

                {userDetails.role != "bank" &&
                    <BranchSelect selectedBrand={selectedBrand} selectedBranchList={selectedBranchList}
                        setSelectedBranchList={setSelectedBranchList} brandRowData={brandRowData} />
                }

                {isSubmitting &&
                    <div className="loading">Loading...</div>
                }

                <div className="newBranchList">
                    {selectedBranchList.length > 0 &&
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Branch Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedBranchList.map((row, index) =>
                                    <React.Fragment>

                                        <tr style={{ cursor: "pointer" }} onClick={() => { toggleRow(index) }}>
                                            <td style={{ display: "flex", paddingTop: "0px", paddingBottom: "0px" }}>
                                                <div style={{ width: "10%", paddingTop: "7px", fontWeight: "600", fontSize: "14px" }}>Branch {index + 1}</div>
                                                <div style={{ width: "90%", textAlign: "right", paddingRight: "10px" }}>
                                                    {!row.isHideRow &&
                                                        <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer', paddingTop: "3px" }}>
                                                            <Icon style={{ fontSize: "30px" }} className="text-info" data-toggle="tool-tip" title="Delete">keyboard_arrow_down</Icon>
                                                        </span>
                                                    }
                                                    {row.isHideRow &&
                                                        <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer', paddingTop: "3px" }}>
                                                            <Icon style={{ fontSize: "30px" }} className="text-info" data-toggle="tool-tip" title="Delete">keyboard_arrow_up</Icon>
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        {!row.isHideRow &&
                                            <tr >
                                                <td>
                                                    <div key={row._id + "cardDet"} ref={ref => { refList.current[index] = ref; }}
                                                        className="m-0 p-0 pt-1 col-12 row d-flex no-gutters">

                                                        <div className="row col-11 d-flex">

                                                            <div className="row col-12 d-flex">
                                                                {brand_id && row.kmid && 
                                                                    <div className="p-2 col-3  input-group justify-content-center">
                                                                        {inputField(row, 'KMID', 'kmid', "", index, false, true)}
                                                                    </div>
                                                                }
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'name', 'name', brandNameRegex, index, true, false)}
                                                                </div>

                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Amex Merchant ID', 'amexMerchantID', charandnumericRegex, index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Visa/MasterCard Merchant ID', 'mastercardMerchantID', charandnumericRegex, index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Zettle/square, etc. MID', 'zettleMerchantID', charandnumericRegex, index, false, false)}
                                                                </div>

                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Tripadvisor Url', 'tripadvisorUrl', "", index, false, false)}
                                                                </div>

                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Contact Name', 'contactName', onlycharRegex, index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center ">
                                                                    {inputField(row, 'Contact Phone', 'contactPhone', phoneRegex, index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center ">
                                                                    {inputField(row, 'Email', 'contactEmail', emailRegex, index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center ">
                                                                    {inputField(row, 'Address', 'address', "", index, true, false)}
                                                                </div>
                                                                <div className="p-2 col-3 input-group justify-content-center ">
                                                                    {inputField(row, 'Postal Town', 'postalTown', "", index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3 input-group justify-content-center ">
                                                                    {inputField(row, 'Post Code', 'postcode', postcodeRegex, index, true, false)}
                                                                </div>
                                                                <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
                                                                    <label>Select Cuisines <span className='MuiInputLabel-asterisk'>&nbsp;*</span> </label>
                                                                    <MultiSelect
                                                                        className='multi-select'
                                                                        options={allCuisinesList}
                                                                        value={row.cuisines_list}
                                                                        onChange={(value) => {
                                                                            handleCuisines(value, index, "cuisines_list")
                                                                        }}
                                                                        labelledBy={"Select Cuisines"} required={true}
                                                                        overrideStrings={overrideStrings}
                                                                        valueRenderer={customValueRenderer} />
                                                                    <FormHelperText className='text-danger'>
                                                                        {fieldError[index] && fieldError[index]["cuisines_list"] ?
                                                                            fieldError[index]["cuisines_list"] : ""}
                                                                    </FormHelperText>
                                                                </div>

                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Number of reviews', 'num_reviews', "", index, true, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Price range', 'price_range', "", index, true, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Restaurant rating', 'rating', "", index, true, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'TP Rank', 'tp_rank', "", index, false, false)}
                                                                </div>
                                                                <div className="p-2 col-3  input-group justify-content-center">
                                                                    {inputField(row, 'Copy', 'copy', "", index, false, false)}
                                                                </div>
                                                            </div>


                                                            {row && row.selectedWhiteLabelPlatforms && row.selectedWhiteLabelPlatforms.map((whiteLabel_row, w_index) => {
                                                                return (
                                                                    <div className="row d-flex col-12 m-t-10" key={"bc-w-l" + w_index}>
                                                                        <div className="d-flex row col-12 m-t-10 b-c-subtitle">
                                                                            {whiteLabel_row.partnerName}
                                                                        </div>
                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Branch Name', 'branchName', index, w_index, true, "textbox")}
                                                                        </div>
                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Branch Copy', 'branchCopy', index, w_index, true, "textbox")}
                                                                        </div>
                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Display address', 'displayAddress', index, w_index, true, "textbox")}
                                                                        </div>

                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Google Maps address', 'gmap_address', index, w_index, true, "textbox")}
                                                                        </div>

                                                                        {/* <div className="d-flex col-3 m-t-20">
                                                                            {whiteLabelDateField(whiteLabel_row, "Branch Start Date", 'branchStartDate', index, w_index, true)}
                                                                        </div>

                                                                        <div className="d-flex col-3 m-t-20">
                                                                            {whiteLabelDateField(whiteLabel_row, "Branch End Date", 'branchEndDate', index, w_index, true)}
                                                                        </div> */}

                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            <div className="form-group pl-0 mb-0 w-100 dropdown-1-1">
                                                                                {whiteLabelPhase(whiteLabel_row, 'Select Phase', 'branchPhase', index, w_index, whiteLabel_row.phases, true)}
                                                                            </div>
                                                                        </div>

                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Booking Link', 'bookingLink', index, w_index, false, "textbox")}
                                                                        </div>

                                                                        {/* <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Branch Banner Video', 'branchBannerVideo', index, w_index, true,"textbox")}
                                                                        </div>

                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Branch Banner Image', 'branchBannerImg', index, w_index, true,"textbox")}
                                                                        </div>

                                                                        <div className="p-2 col-3  input-group justify-content-center">
                                                                            {whiteLabelInputField(whiteLabel_row, 'Branch Banner Thumbnail', 'branchBannerThumbnail', index, w_index, true,"textbox")}
                                                                        </div> */}

                                                                        <div className="p-2 col-3 input-group justify-content-center dropdown-1-1">
                                                                            <label>Select Cuisines <span className='MuiInputLabel-asterisk'>&nbsp;*</span> </label>
                                                                            <MultiSelect
                                                                                className='multi-select'
                                                                                options={allCuisinesList}
                                                                                value={whiteLabel_row.cuisines_list}
                                                                                onChange={(value) => {
                                                                                    whiteLabelDate(value, index, w_index, "cuisines_list")
                                                                                }}
                                                                                labelledBy={"Select Cuisines"} required={true}
                                                                                overrideStrings={overrideStrings}
                                                                                valueRenderer={customValueRenderer} />
                                                                            <FormHelperText className='text-danger'>
                                                                                {fieldError && fieldError[index] && fieldError[index].selectedWhiteLabelPlatforms &&
                                                                                    fieldError[index].selectedWhiteLabelPlatforms[w_index] && fieldError[index].selectedWhiteLabelPlatforms[w_index]["cuisines_list"] ?
                                                                                    fieldError[index].selectedWhiteLabelPlatforms[w_index]["cuisines_list"] : ""}
                                                                            </FormHelperText>
                                                                        </div>

                                                                        <div className="row col-3 input-group m-t-10 b-checkbox-contain min-h">
                                                                            <div className="col-12 p-l-10">
                                                                                Is Featured
                                                                            </div>
                                                                            <input type='checkbox' name="isFeatured"
                                                                                defaultChecked={whiteLabel_row.isFeatured} className="b-checkbox"
                                                                                id="isFeatured"
                                                                                onChange={(e) => {
                                                                                    whiteLabelHandleInputChange(e, index, w_index, "isFeatured");
                                                                                }} />
                                                                        </div>

                                                                        {/* <div className="row col-3 input-group m-t-10 b-checkbox-contain min-h">
                                                                            <div className="col-12 p-l-10">
                                                                                Branch Live
                                                                            </div>
                                                                            <input type='checkbox' name="isBranchLive"
                                                                                defaultChecked={whiteLabel_row.isBranchLive} className="b-checkbox"
                                                                                id="isBranchLive"
                                                                                onChange={(e) => {
                                                                                    whiteLabelHandleInputChange(e, index, w_index, "isBranchLive");
                                                                                }} />
                                                                        </div> */}

                                                                        <div className="row d-flex col-12 branch-contain-list p-r-0 m-r-0">
                                                                            {whiteLabel_row.branchAssets && whiteLabel_row.branchAssets.map((image_row, image_index) => {
                                                                                return (
                                                                                    <div className="row d-flex col-12 p-r-0 m-r-0 branch-contain-row" key={"bc-w-l-image" + image_index}>

                                                                                        <div className="p-2 col-3  input-group">
                                                                                            {imageContainControls(image_row, 'Branch Image', 'branchImage', index, w_index, image_index)}
                                                                                        </div>

                                                                                        <div className="p-2 col-3  input-group">
                                                                                            {imageContainControls(image_row, 'Branch Video', 'branchVideo', index, w_index, image_index)}
                                                                                        </div>
                                                                                        <div className="p-2 col-2  input-group">
                                                                                            <span className="future-action p-t-15" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                                                                onClick={() => addImageContainRow(index, w_index)}  >
                                                                                                <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Edit">add_circle</Icon>
                                                                                            </span>
                                                                                            {whiteLabel_row.branchAssets.length != 1 &&
                                                                                                <span className="future-action p-t-15" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                                                                    onClick={() => removeImageContainRow(index, w_index, image_index)}>
                                                                                                    <Icon style={{ fontSize: "2rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                                                                </span>
                                                                                            }
                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                            }
                                                                            )}
                                                                        </div>

                                                                        {/* <div className="d-flex row col-12 m-t-10 b-c-sub-t-02">
                                                                            <input type='checkbox' name="isBranchExperience"
                                                                                defaultChecked={whiteLabel_row.isBranchExperience} className="distributor-checkbox"
                                                                                id="isBranchExperience"
                                                                                onChange={(e) => {
                                                                                    whiteLabelHandleInputChange(e, index, w_index, "isBranchExperience");
                                                                                }}
                                                                            /> &nbsp;&nbsp; Branch Experience
                                                                        </div>

                                                                        {whiteLabel_row.isBranchExperience &&
                                                                            <div className="row d-flex col-12 branch-contain-list p-r-0 m-r-0 m-t-5-i">
                                                                                <div className="p-2 col-3  input-group">
                                                                                    <div className="form-group pl-0 mb-0 w-100">
                                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                            <DatePicker label="Exp Start Date"
                                                                                                value={whiteLabel_row.branchExperience && whiteLabel_row.branchExperience.expStartDate ? new Date(whiteLabel_row.branchExperience.expStartDate) : ""}
                                                                                                onChange={(newValue) => updateExpDateValue(newValue, index, w_index, 'expStartDate')}
                                                                                                format="dd-MM-yyyy" />
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="p-2 col-3  input-group">
                                                                                    <div className="form-group pl-0 mb-0 w-100">
                                                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                            <DatePicker label="Exp End Date"
                                                                                                value={whiteLabel_row.branchExperience && whiteLabel_row.branchExperience.expEndDate ? new Date(whiteLabel_row.branchExperience.expEndDate) : ""}
                                                                                                onChange={(newValue) => updateExpDateValue(newValue, index, w_index, 'expEndDate')}
                                                                                                format="dd-MM-yyyy" />
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="p-2 col-3  input-group">
                                                                                    {expControls(whiteLabel_row.branchExperience, 'Exp Name', 'expName', index, w_index, "")}
                                                                                </div>
                                                                                <div className="p-2 col-3  input-group">
                                                                                    {expControls(whiteLabel_row.branchExperience, 'Exp Copy', 'expCopy', index, w_index, "")}
                                                                                </div>

                                                                                {whiteLabel_row.branchExperience && whiteLabel_row.branchExperience.expAssets
                                                                                    && whiteLabel_row.branchExperience.expAssets.map((exp_row, exp_index) => {
                                                                                        return (
                                                                                            <div className="d-flex row col-12">
                                                                                                <div className="p-2 col-3  input-group">
                                                                                                    {expControls(exp_row, 'Image Url', 'branchExpImage', index, w_index, exp_index)}
                                                                                                </div>
                                                                                                <div className="p-2 col-3  input-group">
                                                                                                    {expControls(exp_row, 'Thumbnail Url', 'branchExpThumbnail', index, w_index, exp_index)}
                                                                                                </div>
                                                                                                <div className="p-2 col-3  input-group">
                                                                                                    {expControls(exp_row, 'Video Url', 'branchExpVideo', index, w_index, exp_index)}
                                                                                                </div>
                                                                                                <div className="p-2 col-3  input-group">
                                                                                                    <span className="future-action p-t-15" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                                                                        onClick={() => addExpRow(index, w_index)}  >
                                                                                                        <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Edit">add_circle</Icon>
                                                                                                    </span>
                                                                                                    {whiteLabel_row.branchExperience.expAssets.length != 1 &&
                                                                                                        <span className="future-action p-t-15" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
                                                                                                            onClick={() => removeExpRow(index, w_index, exp_index)}>
                                                                                                            <Icon style={{ fontSize: "2rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                                                                        </span>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}

                                                                            </div>
                                                                        } */}

                                                                    </div>


                                                                )
                                                            }
                                                            )}

                                                        </div>

                                                        <div className="row col-1 row d-flex p-1 p-t-20 input-group justify-content-center">
                                                            {userDetails.role != "bank" && row.isExisting &&
                                                                <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer', height: "25px" }} onClick={() => { setdeleteDialog({ data: row, isOpen: true }) }}   >
                                                                    <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                                </span>
                                                            }
                                                        </div>


                                                    </div>
                                                </td>
                                            </tr>
                                        }


                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                    }
                    {selectedBranchList.length > 0 &&
                        <div className="row text-right no-gutters col-12 m-0 p-0 justify-content-end">
                            <button
                                disabled={isSubmitting}
                                className="btn btn-sm btn-primary newbrandBtn"
                                onClick={() => createEntity()}
                            >
                                Save
                            </button>
                        </div>
                    }
                </div>
            </div>


        </>
    )
};

export default React.memo(BranchCreate);