import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import CreatableSelect from 'react-select/lib/Creatable';
import { getEntityDataByFilterApicall } from '../../services/newBrandBranch/getEntityDataByFilterApiCall';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import { CreateEntityApicall } from '../../services/newBrandBranch/createEntityApiCall';
import { object as YupObject, string as YupString, number as YupNumber, boolean as YupBoolean, date, array as YupArray } from 'yup';
import * as yup from 'yup';
import { brandNameRegex, charandnumericRegex, websiteRegex } from '../../utils/constants/constant';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon'
import { getUniqueArray } from '../../utils/helpers';
import BranchCreate from './BranchCreate';
import { CreateBrandApiCall } from '../../services/newBrandBranch/createBrandApiCall';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import configMessages from '../../config/configMessages';
import { checkBrandExistApiCall, getGroups, getDistributors, getWhitelabels } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { CLEAR_BRANDBRANCH_DATA } from '../../redux/newBrandBranch/actionTypes';
import { useHistory, Link, useParams } from 'react-router-dom'
import UserCreate from './UserCreate';
import FormikImageUpload from '../../utils/components/FormikImageUpload';
import config from '../../config/config';
import moment from 'moment';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import { GetSessionValue } from '../../utils/sessionStorage';
import classNames from 'classnames'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MultiSelect from "react-multi-select-component";

export default function BrandAndBranchMain() {
    const dispatch = useDispatch();
    const history = useHistory()

    const { brand_id, brandname } = useParams();

    const Brandstate = useSelector(
        newBrandBranchSelectors.getBrandstate
    );

    const createBrandEntitystate = useSelector(
        newBrandBranchSelectors.createBrandEntitystate
    );
    const createBrandstate = useSelector(
        newBrandBranchSelectors.createBrandstate
    );
    const existingBrand = useSelector(
        newBrandBranchSelectors.getExistingBrandState
    );

    const createBranchstate = useSelector(
        newBrandBranchSelectors.createBranchstate
    );

    const getGroupState = useSelector(
        newBrandBranchSelectors.getGroupState
    );

    const getDistributorsState = useSelector(
        newBrandBranchSelectors.getDistributorsState
    );

    const [brandList, setbrandList] = useState([]);
    const [brandRowData, setbrandRowData] = useState([]);
    const [isValidate, setisValidate] = useState(false);
    const [fieldError, setfieldError] = useState({});
    const [whitelabelFieldError, setWhitelabelFieldError] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectBrandName, setSelectBrandName] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [distributorList, setDistributorList] = useState([]);
    const [m_sel_distributorList, set_M_sel_distributorList] = useState([]);
    const [whitelabels, setWhitelabels] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [showBranchSection, setShowBranchSection] = useState(true);
    const [amexPhaseList, setAmexPhaseList] = useState([
        { label: 'Amex Phase 3', value: 'amex_phase_3' },
        { label: 'Amex Phase 3.5', value: 'amex_phase_3_5' },
        { label: 'Amex Phase 4', value: 'amex_phase_4' }
    ]);
    const [overrideStrings, setOverrideStrings] = useState({
        "selectSomeItems": "Select Publisher",
        "allItemsAreSelected": "All Publisher are selected.",
        "selectAll": "All Publisher",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    const [whitelabelsStrings, setWhitelabelsStrings] = useState({
        "selectSomeItems": "Select Partner",
        "allItemsAreSelected": "All Partner are selected.",
        "selectAll": "All Partner",
        "search": "Search",
        "clearSearch": "Clear Search"
    });

    // const [schemaObj, setSchemaObj] = useState({
    //     name: YupString()
    //         .required(`Brand ${configMessages.name}`),
    //         // .min(3, `Brand ${configMessages.minName}`).typeError("Custom not a number message!")
    //         // .max(100, `Brand ${configMessages.maxName}`).typeError("Custom not a number message!")
    //         // .matches(brandNameRegex, 'Brand name can only contain [A-Za-z_-\s@\.]'),
    //     registeredAddress: YupString().required(configMessages.address).typeError(configMessages.address),
    //     //     .required(configMessages.registration).typeError("Custom not a number message!"),
    //     website: YupString()
    //         .required(configMessages.website).typeError(configMessages.website)
    //         .matches(websiteRegex, 'Please enter a valid url'),
    //     }), 
    //     isAutoDebit: YupString(),
    //     // description: YupString()
    //         //     .required(configMessages.description).typeError("Custom not a number message!")
    // });


    const DaysBasedOnMonth = {
        1: 31, 2: 61, 3: 92
    }
    useEffect(() => {
        if (brandname) {
            setSelectBrandName(brandname);
            getBrandData(brandname || "");
        }
        dispatch(getGroups());
        dispatch(getDistributors());
        let userDetails = GetSessionValue('user');
        setUserDetails(userDetails);
        dispatch(getWhitelabels(response => {
            // response.forEach(element => {
            //     element.label = element.partnerName;
            //     element.value = element.partnerSerialNo;
            // });
            setWhitelabels(response);
        }));
        return () => {
            dispatch({
                type: CLEAR_BRANDBRANCH_DATA,
                payload: {}
            })
        }
    }, []);

    useEffect(() => {
        if (Brandstate.data.length > 0) {
            let selectData = [];
            let uniqueBrand = getUniqueArray(Brandstate.data, 'brandname');

            uniqueBrand = uniqueBrand.sort(function (a, b) {
                let s_brandName = selectBrandName ? selectBrandName.toLowerCase().replace(/\s/g, '') : "";
                let a_brand = a.brandname ? a.brandname.toLowerCase().replace(/\s/g, '') : "";
                let b_brand = b.brandname ? b.brandname.toLowerCase().replace(/\s/g, '') : "";

                var keyA = a_brand.indexOf(s_brandName);
                var keyB = b_brand.indexOf(s_brandName);

                if (s_brandName == a_brand || s_brandName == b_brand) return 1;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

            uniqueBrand.forEach((item, index) => {
                if (item.brandname) {
                    selectData.push({ label: item.brandname, value: item.brand_id, item })
                }
            });

            let selectedBand = {};
            if (brandname) {
                // let a_brand = brandname.toLowerCase().replace(/\s/g, '');
                selectData.forEach(element => {
                    // let b_brand = element.label ? element.label.toLowerCase().replace(/\s/g, '') : "";
                    if (brand_id == element.value) {
                        selectedBand = element;
                    }
                });
            }

            setbrandList(selectData)
            if (brandname) {
                dispatch(checkBrandExistApiCall({ brandName: selectedBand.label, brandId: selectedBand.value }))
            }
        }
    }, [Brandstate.data]);


    useEffect(() => {
        console.log("createBrandstate", createBrandstate)
        if (createBrandstate.error.errorCode) {
            showMessage(createBrandstate.error.errorMessage, "error")
        }
        if (createBrandstate.data.respCode) {
            showMessage(createBrandstate.data.respMessage, "success")
            let { name, brandId, brand_id } = createBrandstate.data.details.brands
            dispatch(checkBrandExistApiCall({ brandName: name, brandId: brandId || brand_id }))
        }
    }, [createBrandstate]);

    useEffect(() => {
        if (createBrandEntitystate.error.errorCode) {
            showMessage(createBrandEntitystate.error.errorMessage, "error")
        }
        if (createBrandEntitystate.data.respCode) {
            showMessage(createBrandEntitystate.data.respMessage, "success")
            let { name, brandId, brand_id } = createBrandEntitystate.data.details.brands
            dispatch(checkBrandExistApiCall({ brandName: name, brandId: brandId || brand_id }))
        }
    }, [createBrandEntitystate]);

    useEffect(() => {
        if (existingBrand.data.brands.length > 0) {
            let { name } = existingBrand.data.brands[0];
            existingBrand.data.brands[0].brandname = name;
            existingBrand.data.brands[0].name = name;
            existingBrand.data.brands[0].isGroupBrand = existingBrand.data.brands[0].groupId ? "true" : "false";

            if (existingBrand.data.branch && existingBrand.data.branch.length > 0) {
                existingBrand.data.branch.forEach(element => {
                    if (element.merchantIds) {
                        element.mastercardMerchantID = element.merchantIds.mastercardMerchantID || "";
                        element.amexMerchantID = element.merchantIds.amexMerchantID || "";
                        element.zettleMerchantID = element.merchantIds.zettleMerchantID || "";
                        delete element.merchantIds;
                    }
                });
            }

            if (distributorList && existingBrand.data.brands[0].distributorDealSetWithBrand) {
                let temp = JSON.parse(JSON.stringify(distributorList));
                let m_list = [];
                existingBrand.data.brands[0].selectedPublisher = [];
                temp.forEach(element => {
                    existingBrand.data.brands[0].distributorDealSetWithBrand.forEach(e2 => {
                        if (element.distributors_name == e2.distributors_name) {
                            element.percentage = e2.percentage;
                            element.successFee = e2.successFee;
                            element.startDate = e2.startDate ? new Date(e2.startDate) : "";
                            existingBrand.data.brands[0].selectedPublisher.push({ label: element.distributors_display_name, value: element.distributors_name });
                        }
                    });
                    m_list.push({ label: element.distributors_display_name, value: element.distributors_name });
                });
                setDistributorList(temp);
                set_M_sel_distributorList(m_list);

                existingBrand.data.brands[0].selectedWhiteLabelPlatforms = [];
                if (existingBrand.data.brands[0].whitelabel) {
                    Object.keys(existingBrand.data.brands[0].whitelabel).forEach(element => {
                        let w_filter = whitelabels.filter(aa => aa.partnerSerialNo == element)[0] || {};
                        let whitelabelValues = existingBrand.data.brands[0].whitelabel[element];

                        let phase = [];
                        if (w_filter.phases) {
                            w_filter.phases.forEach(e2 => {
                                if (whitelabelValues.brandPhase && whitelabelValues.brandPhase.includes(e2.phaseNumber)) {
                                    phase.push({
                                        ...e2,
                                        value: e2.phaseNumber,
                                        label: e2.phaseNumber
                                    });
                                }
                            });
                        }

                        existingBrand.data.brands[0].selectedWhiteLabelPlatforms.push({
                            ...w_filter,
                            value: w_filter.partnerSerialNo,
                            label: w_filter.partnerName,
                            brandLogoUrl: whitelabelValues.brandLogoUrl,
                            brandMapLogo: whitelabelValues.brandMapLogo,
                            brandName: whitelabelValues.brandName,
                            forMap: whitelabelValues.forMap,
                            isGiftCardsAllowed: whitelabelValues.isGiftCardsAllowed,
                            isBrandLive: whitelabelValues.isBrandLive,
                            // offerCategory: whitelabelValues.offerCategory,
                            offerStartDate: whitelabelValues.offerStartDate ? new Date(whitelabelValues.offerStartDate) : "",
                            offerEndDate: whitelabelValues.offerEndDate ? new Date(whitelabelValues.offerEndDate) : "",
                            brandPhase: phase,
                            branchAssets: whitelabelValues.branchAssets,
                            // branchExperience: whitelabelValues.branchExperience
                        });
                    });
                }
            }

            setbrandRowData([existingBrand.data.brands[0]]);
            setShowBranchSection(true);
        }
    }, [existingBrand.data]);


    useEffect(() => {
        // console.log("getGroupState", getGroupState.data.groupList);
        setGroupList(getGroupState.data.groupList);
    }, [getGroupState.data]);

    useEffect(() => {
        setDistributorList(getDistributorsState.data.distributors);
        let m_list = [];
        getDistributorsState.data.distributors.forEach(element => {
            m_list.push({ label: element.distributors_display_name, value: element.distributors_name });
        });
        set_M_sel_distributorList(m_list);
    }, [getDistributorsState.data]);


    const getBrandData = (value) => {
        // let filterCriteria = {
        //     'criteria': [
        //         {
        //             'key': 'brandname',
        //             'value': value,
        //             'type': 'regexOr',

        //         },
        //         { "key": "active", "value": null, "type": "eq" }
        //     ]
        // }
        // dispatch(getEntityDataByFilterApicall(filterCriteria))
        setSelectBrandName(value);
        dispatch(getEntityDataByFilterApicall(value)) //the frenchie //"Loulou"
    }

    const handleInputChangeNew = (e, index, type) => {
        const { value, checked } = e.target;
        const data1 = [...brandRowData]
        let data2;
        if (type == "payment_terms_days") {
            const re = /^[0-9.\b]+$/;
            if (value === '' || (re.test(value) && parseInt(value) <= 100)) {
                data2 = { ...data1[index], [type]: value }
            } else {
                data2 = { ...data1[index], [type]: "" }
            }
        } else if (type == "two_invoice_per_month" || type == "is_two_customer") {
            data2 = { ...data1[index], [type]: checked }
        } else {
            data2 = { ...data1[index], [type]: value }
        }
        data1[index] = data2
        setbrandRowData(data1);
    };

    const handleDistributorValue = (e, index, filed) => {
        const { value } = e.target;
        const re = /^[0-9.\b]+$/;
        if (value === '' || (re.test(value) && parseInt(value) <= 100)) {
            const data1 = [...distributorList];
            let data2 = { ...data1[index], [filed]: value };
            data1[index] = data2;
            setDistributorList(data1);
        }
    };

    const updateFormData = (newValue, index, type) => {
        const data1 = [...brandRowData];
        if (type == "selectedWhiteLabelPlatforms") {
            newValue.forEach(element => {
                element.brandName = element.brandName || data1[0].name
            });
        }
        let data2 = { ...data1[index], [type]: newValue };
        data1[index] = data2;
        setbrandRowData(data1);
    };

    const handleDistributorStartDate = (value, index, filed) => {
        const data1 = [...distributorList];
        let data2 = { ...data1[index], [filed]: value };
        data1[index] = data2;
        setDistributorList(data1);
    };

    const checkPublisherSelected = (row) => {
        let flag = false;
        if (row.selectedPublisher && row.selectedPublisher.length > 0) {
            flag = true;
        }
        return flag;
    }

    const refList = useRef(brandRowData.map(() => createRef()));


    const scrollToBottom = (res, index) => {
        setTimeout(() => {
            refList.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }, 200);
    }

    const createEntity = async (photoUrl) => {

        setIsSubmitting(true);
        let formRowData = JSON.parse(JSON.stringify(brandRowData[0]));
        let schemaObj = {
            name: YupString().required(`Brand ${configMessages.name}`),
            registeredAddress: YupString().required(configMessages.address).typeError(configMessages.address),
            groupId: YupString().required("Please select group").typeError("Custom not a number message!"),
            isAutoDebit: YupString(),
            isGroupBrand: YupString(),
            invoice_preference: YupString(),
            invoice_preference_level_2: YupString(),
            separateInvoice: YupString(),
            two_invoice_per_month: YupBoolean(),
            is_two_customer: YupBoolean(),
            payment_terms_days: YupString().required("Payment terms is required").typeError("Payment terms is required"),
            // selectedWhiteLabelPlatforms: YupArray().required(`Please select Whitelabel Platforms`),
            selectedPublisher: YupArray().required(`Please select Publisher`)
        }

        if (formRowData.isGroupBrand == "false") {
            delete schemaObj.groupId;
        } else {
            delete schemaObj.payment_terms_days;
        }

        const validationSchema = YupObject(schemaObj);

        let distributorDealSetWithBrand = [];
        if (distributorList) {
            distributorList.forEach(element => {
                if (formRowData.selectedPublisher && formRowData.selectedPublisher.findIndex(a => a.value == element.distributors_name) != -1) {
                    distributorDealSetWithBrand.push({
                        distributors_name: element.distributors_name,
                        successFee: element.successFee,
                        percentage: element.percentage,
                        startDate: element.startDate ? new Date(element.startDate) : ""
                    });
                }
            });
        }

        let finalArr = {};
        brandRowData.forEach(async (res, index) => {
            let obj = {
                name: res.name,
                companyName: res.companyName,
                registeredAddress: res.registeredAddress,
                website: res.website,
                description: res.description,
                groupId: res.groupId || "",
                isAutoDebit: res.isAutoDebit,
                isGroupBrand: res.isGroupBrand,
                invoice_preference: res.invoice_preference || "",
                invoice_preference_level_2: res.invoice_preference_level_2 || "",
                separateInvoice: res.separateInvoice || "",
                two_invoice_per_month: res.two_invoice_per_month,
                is_two_customer: res.is_two_customer,
                distributorDealSetWithBrand: distributorDealSetWithBrand,
                payment_terms_days: res.payment_terms_days,
                selectedWhiteLabelPlatforms: res.selectedWhiteLabelPlatforms,
                selectedPublisher: res.selectedPublisher
            }
            finalArr = obj
            validationSchema.isValid(obj).then(async (res1) => {
                if (!res1) {
                    console.log(res1, res)
                    scrollToBottom(res, index)
                }
            })
        })

        async function checkWhiteLabelValidation(returnResult) {
            let schema = yup.object({
                items: yup.array().of(
                    yup.object().shape({
                        brandName: yup.string().required("Brand name is required"),
                        brandLogoUrl: yup.string().required("Brand Logo URL is required"),
                        brandMapLogo: yup.string().required("Brand Map Logo is required"),
                        // forMap: yup.string().required("Wet Venues is required"),
                        brandPhase: yup.array().required("Phase is required"),
                        // offerCategory: yup.string().required("Offer Category is required"),
                        offerStartDate: yup.date().typeError('Offer Start Date is required').required("Offer Start Date is required"),
                        offerEndDate: yup.date().typeError('Offer End Date is required').required("Offer End Date is required")
                    })
                )
            });

            let finalArr = []
            brandRowData[0].selectedWhiteLabelPlatforms.forEach(async (res, index) => {
                let obj = {
                    brandName: res.brandName || "",
                    brandLogoUrl: res.brandLogoUrl || "",
                    brandMapLogo: res.brandMapLogo || "",
                    forMap: res.forMap || "",
                    isBrandLive: res.isBrandLive || "",
                    isGiftCardsAllowed: res.isGiftCardsAllowed || "",
                    brandPhase: res.brandPhase || "",
                    // offerCategory: res.offerCategory || "",
                    offerStartDate: res.offerStartDate || "",
                    offerEndDate: res.offerEndDate || ""
                }
                finalArr.push(obj);
            });

            await schema.isValid({ items: finalArr }).then(async (res) => {
                if (res) {
                    returnResult(true);
                } else {
                    schema.validate({ items: finalArr }, { abortEarly: false }).then(res => {
                        setWhitelabelFieldError([]);
                        returnResult(true);
                    }, (e) => {
                        if (e.inner.length > 0) {
                            let errorMsgT = {}
                            e.inner.forEach(res => {
                                let fieldName = res.path.split(".");
                                let indexValue = fieldName[0];
                                ["items", "[", "]"].forEach(res => {
                                    indexValue = indexValue.replace(res, '')
                                })
                                if (errorMsgT[indexValue]) {
                                    if (!errorMsgT[indexValue][fieldName[1]]) {
                                        errorMsgT[indexValue][fieldName[1]] = res.message
                                    }
                                } else {
                                    errorMsgT[indexValue] = {}
                                    errorMsgT[indexValue][fieldName[1]] = res.message
                                }
                            })
                            console.log("errorMsgT", errorMsgT);
                            setWhitelabelFieldError(errorMsgT);
                        }
                        returnResult(false);
                    });
                }
            });

        }

        try {

            validationSchema.validate(finalArr, { abortEarly: false }).then(res => {
                setfieldError({})
            }, (e) => {
                console.log(JSON.stringify(e));
                if (e.inner.length > 0) {
                    let errorMsg = {}
                    e.inner.forEach(res => {
                        let fieldName = res.path;
                        let indexValue = fieldName;
                        ["items", "[", "]"].forEach(res => {
                            indexValue = indexValue.replace(res, '')
                        })
                        if (errorMsg[indexValue]) {
                            errorMsg[indexValue] = res.message;
                        } else {
                            errorMsg[indexValue] = res.message
                        }
                    })
                    setfieldError(errorMsg)
                    setIsSubmitting(false);
                }
            })
            checkWhiteLabelValidation(async (isValidData) => { });
        } catch (e) {
            console.log(JSON.stringify(e));

        }

        await validationSchema
            .isValid(finalArr).then(async (res) => {
                if (res) {

                    checkWhiteLabelValidation(async (isValidData) => {
                        if (!isValidData) {
                            setIsSubmitting(false);
                        } else {
                            setWhitelabelFieldError([]);
                            delete formRowData.selectedPublisher;
                            let whitelabelData = {};
                            formRowData.selectedWhiteLabelPlatforms.forEach(element => {

                                let key = element.partnerSerialNo;
                                whitelabelData[key] = {};
                                whitelabelData[key].brandLogoUrl = element.brandLogoUrl;
                                whitelabelData[key].brandMapLogo = element.brandMapLogo;
                                whitelabelData[key].brandName = element.brandName;
                                whitelabelData[key].forMap = element.forMap;
                                whitelabelData[key].isGiftCardsAllowed = element.isGiftCardsAllowed;
                                whitelabelData[key].isBrandLive = element.isBrandLive;
                                // whitelabelData[key].offerCategory = element.offerCategory;
                                whitelabelData[key].offerEndDate = new Date(element.offerEndDate);
                                whitelabelData[key].offerStartDate = new Date(element.offerStartDate);
                                whitelabelData[key].brandPhase = [];
                                if (element.brandPhase) {
                                    element.brandPhase.forEach(e2 => {
                                        whitelabelData[key].brandPhase.push(e2.value);
                                    });
                                }
                            });
                            delete formRowData.selectedWhiteLabelPlatforms;
                            formRowData.whitelabel = whitelabelData;

                            setShowBranchSection(false);
                            let url = "https://t2zociuop4.execute-api.eu-west-2.amazonaws.com/default/create_brand_node";
                            let x_api_key = "1t02ARoFco80EpL5r3MqQ1qypxwI8qMO7iRhFpi5";
                            if (formRowData.isNew) {
                                setIsSubmitting(true);
                                let body = {
                                    "brandname": formRowData.name || formRowData.brandname,
                                    "brand_id": formRowData.brand_id || "",
                                    "website": formRowData.website,
                                    "registeredAddress": formRowData.registeredAddress,
                                    "companyName": formRowData.companyName,
                                    "description": formRowData.description,
                                    "sector_id": '16',
                                    "cuisines_list": [],
                                    "groupId": formRowData.groupId,
                                    "invoice_preference": formRowData.invoice_preference,
                                    "invoice_preference_level_2": formRowData.invoice_preference_level_2,
                                    "separateInvoice": formRowData.separateInvoice,
                                    "two_invoice_per_month": formRowData.two_invoice_per_month,
                                    "is_two_customer": formRowData.is_two_customer,
                                    "distributorDealSetWithBrand": distributorDealSetWithBrand,
                                    "payment_terms_days": formRowData.payment_terms_days,
                                    "registrationId": ""
                                };

                                let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
                                if (response && response.data && response.data[0]) {
                                    formRowData.brandname = response.data[0].brandname;
                                    formRowData.brand_id = response.data[0]['brand_id'];
                                    formRowData.brandId = response.data[0]['brand_id'];
                                    formRowData.distributorDealSetWithBrand = distributorDealSetWithBrand;
                                    if (formRowData.brandId) {
                                        dispatch(CreateBrandApiCall(formRowData, 'create'));
                                    } else {
                                        let notification = { ...notificationOpts };
                                        notification.message = "Brand id not found from node graph api.";
                                        dispatch(errorNotificationAction(notification))
                                    }
                                }
                                setisValidate(false);
                                setIsSubmitting(false);
                            } else {
                                if (brandRowData.length > 0) {
                                    formRowData.name = formRowData.name || formRowData.brandname;
                                    formRowData.brandname = formRowData.name || formRowData.brandname;
                                }
                                let finalData = formRowData;
                                finalData.distributorDealSetWithBrand = distributorDealSetWithBrand;

                                if (existingBrand.data.brands.length > 0) {
                                    setIsSubmitting(true);
                                    let body = {
                                        "brandname": finalData.brandname,
                                        "brand_id": finalData.brand_id || "",
                                        "website": finalData.website,
                                        "registeredAddress": finalData.registeredAddress,
                                        "companyName": finalData.companyName,
                                        "description": finalData.description,
                                        "sector_id": '16',
                                        "cuisines_list": [],
                                        "groupId": finalData.groupId,
                                        "invoice_preference": finalData.invoice_preference,
                                        "invoice_preference_level_2": finalData.invoice_preference_level_2,
                                        "separateInvoice": finalData.separateInvoice,
                                        "two_invoice_per_month": finalData.two_invoice_per_month,
                                        "is_two_customer": finalData.is_two_customer,
                                        "distributorDealSetWithBrand": distributorDealSetWithBrand,
                                        "payment_terms_days": finalData.payment_terms_days,
                                        "registrationId": ""
                                        // "entity_url" : finalData.entity_url,
                                    };
                                    let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
                                    delete finalData.brandname;
                                    if (!finalData.brand_id) {
                                        finalData.brand_id = finalData.brandId;
                                    }
                                    if (response && response.data && response.data[0] && finalData.brand_id) {
                                        dispatch(CreateBrandApiCall(finalData, 'update'));
                                    } else {
                                        let notification = { ...notificationOpts };
                                        notification.message = "Brand id not found while update brand.";
                                        dispatch(errorNotificationAction(notification))
                                    }
                                    setIsSubmitting(false);
                                } else {
                                    setIsSubmitting(true);
                                    let body = {
                                        "brandname": finalData.brandname,
                                        "brand_id": finalData.brand_id || "",
                                        "website": finalData.website,
                                        "registeredAddress": finalData.registeredAddress,
                                        "companyName": finalData.companyName,
                                        "description": finalData.description,
                                        "sector_id": '16',
                                        "cuisines_list": [],
                                        "groupId": finalData.groupId,
                                        "invoice_preference": finalData.invoice_preference,
                                        "invoice_preference_level_2": finalData.invoice_preference_level_2,
                                        "separateInvoice": finalData.separateInvoice,
                                        "two_invoice_per_month": finalData.two_invoice_per_month,
                                        "is_two_customer": finalData.is_two_customer,
                                        "distributorDealSetWithBrand": distributorDealSetWithBrand,
                                        "payment_terms_days": finalData.payment_terms_days,
                                        "registrationId": ""
                                        // "entity_url" : finalData.entity_url
                                    };
                                    let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
                                    if (response && response.data && response.data[0]) {
                                        finalData.brandname = response.data[0].brandname;
                                        finalData.brand_id = response.data[0].brand_id;
                                        finalData.brandId = response.data[0].brand_id;
                                        if (finalData.brandId) {
                                            dispatch(CreateBrandApiCall(finalData, 'create'));
                                        } else {
                                            let notification = { ...notificationOpts };
                                            notification.message = "Brand id not found from node graph api.";
                                            dispatch(errorNotificationAction(notification))
                                        }
                                    }
                                    setIsSubmitting(false);
                                }
                                setisValidate(false)
                            }
                        }
                    });
                } else {
                    setisValidate(true)
                }
            })
    }

    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    const inputField = (row, placeholder, type, regex, index, isdisabled, required) => {
        let iserror = false
        let helperText = ""

        if (fieldError[type]) {
            iserror = true;
            helperText = fieldError[type]
        }

        if (type == "groupId" && brandRowData[0].isGroupBrand == "false") {
            helperText = "";
        }

        return <>
            <FormControl className='w-100' variant="standard">

                {type != "invoice_preference" && type != "invoice_preference_level_2" && type != "groupId" &&
                    type != "separateInvoice" && type != "selectedPublisher" && type != "selectedWhiteLabelPlatforms" &&
                    <TextField
                        disabled={isdisabled}
                        value={row[type]}
                        id={`brandid${type}${index}`}
                        name={`brandname${type}${index}`}
                        type="text" label={placeholder}
                        required={required}
                        // error={isValidate && (!row[type] || (regex && !regex.test(row[type])))}
                        error={isValidate && iserror}
                        className={`form-control mb-3`}
                        onChange={e => handleInputChangeNew(e, index, type)}
                    />
                }

                {type == "invoice_preference" &&
                    <>
                        <label>{placeholder}</label>
                        <select
                            name="invoice_preference"
                            className="form-control"
                            value={row.invoice_preference}
                            onChange={(e) => {
                                handleInputChangeNew(e, index, type);
                            }}
                        >
                            <option value="">{placeholder}</option>
                            {/* <option value={"group"} >Group</option> */}
                            <option value={"brand"}>Brand</option>
                        </select>
                    </>
                }

                {type == "invoice_preference_level_2" &&
                    <>
                        <label>{placeholder}</label>
                        <select
                            name="invoice_preference_level_2"
                            className="form-control"
                            value={row.invoice_preference_level_2}
                            onChange={(e) => {
                                handleInputChangeNew(e, index, type);
                            }}
                        >
                            <option value="">{placeholder}</option>
                            {brandRowData[0].invoice_preference == "group" &&
                                <>
                                    <option value={"brand"}>Brand</option>
                                    <option value={"publisher"} >Publisher</option>
                                </>
                            }
                            {brandRowData[0].invoice_preference == "brand" &&
                                <>
                                    <option value={"publisher"} >Publisher</option>
                                    <option value={"branch"} >Branch</option>
                                </>
                            }
                        </select>
                    </>
                }


                {type == "separateInvoice" &&
                    <>
                        <label>{placeholder}
                            {brandRowData[0].invoice_preference_level_2 == "brand" &&
                                " for each publisher"
                            }
                            {brandRowData[0].invoice_preference_level_2 == "branch" &&
                                " for each publisher"
                            }
                            {brandRowData[0].invoice_preference == "group" && brandRowData[0].invoice_preference_level_2 == "publisher" &&
                                " for each brand"
                            }
                            {brandRowData[0].invoice_preference == "brand" && brandRowData[0].invoice_preference_level_2 == "publisher" &&
                                " for each branch"
                            }
                        </label>
                        <select
                            name="separateInvoice"
                            className="form-control"
                            value={row.separateInvoice}
                            onChange={(e) => {
                                handleInputChangeNew(e, index, type);
                            }}
                        >
                            <option value="">{placeholder}</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"} >No</option>
                        </select>
                    </>
                }

                {type == "groupId" && brandRowData[0] && brandRowData[0].isGroupBrand == "true" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>} </label>
                        <select
                            name="groupId"
                            className="form-control"
                            value={row.groupId}
                            required={required}
                            onChange={(e) => {
                                handleInputChangeNew(e, index, type)
                            }}>
                            <option value="">{placeholder}</option>

                            {groupList.map((res, index) =>
                                <option key={"groupId" + index} value={res.groupId} > {res.group_name} </option>
                            )}
                        </select>
                    </>
                }

                {type == "selectedPublisher" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>} </label>
                        <MultiSelect
                            className='multi-select'
                            options={m_sel_distributorList}
                            value={row.selectedPublisher}
                            required={required}
                            onChange={(value) => {
                                updateFormData(value, index, "selectedPublisher")
                            }}
                            labelledBy={"Select Publisher"}
                            overrideStrings={overrideStrings}
                            valueRenderer={customValueRenderer} />
                    </>
                }

                {type == "selectedWhiteLabelPlatforms" &&
                    <>
                        {/* <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>} </label> */}
                        <label>{placeholder}</label>
                        <MultiSelect
                            className='multi-select'
                            options={whitelabels}
                            value={row.selectedWhiteLabelPlatforms}
                            // required={required}
                            onChange={(value) => {
                                updateFormData(value, index, "selectedWhiteLabelPlatforms")
                            }}
                            labelledBy={placeholder}
                            overrideStrings={whitelabelsStrings}
                            valueRenderer={whitelabelsRenderer} />
                    </>
                }


                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
            </FormControl>
        </>
    }

    const whiteLabelInputField = (row, placeholder, type, w_index, controlType, required) => {

        let helperText = "";
        if (whitelabelFieldError && whitelabelFieldError[w_index] && whitelabelFieldError[w_index][type]) {
            helperText = whitelabelFieldError[w_index][type];
        }

        return <>
            <FormControl className='w-100' variant="standard">

                {controlType == "textbox" &&
                    <TextField
                        value={row[type]}
                        id={`brandid${type}${w_index}`}
                        name={`brandname${type}${w_index}`}
                        type="text" label={placeholder}
                        required={required}
                        error={helperText}
                        className={`form-control mb-3`}
                        onChange={e => whiteLabelHandleInputChange(e, w_index, type)}
                    />
                }

                {/* {controlType == "dropdown" && type == "offerCategory" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                        <select
                            name="offerCategory"
                            className="form-control"
                            value={row.offerCategory}
                            onChange={(e) => {
                                whiteLabelHandleInputChange(e, w_index, type);
                            }}
                        >
                            <option value="">{placeholder}</option>
                            <option value={"dining"} >Dining</option>
                        </select>
                    </>
                } */}

                {/* {controlType == "dropdown" && type == "forMap" &&
                    <>
                        <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                        <select
                            name="forMap"
                            className="form-control"
                            value={row.forMap}
                            required={required}
                            onChange={(e) => {
                                whiteLabelHandleInputChange(e, w_index, type);
                            }}>
                            <option value="">{placeholder}</option>
                            <option value={"Yes"} >Yes</option>
                            <option value={"No"} >No</option>
                        </select>
                    </>
                } */}

                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const whiteLabelPhase = (row, placeholder, type, w_index, list, required) => {
        row[type] = row[type] || [];
        list = list || [];
        list.sort((a, b) => (parseFloat(a.phaseNumber) > parseFloat(b.phaseNumber) ? -1 : 1));
        list.forEach(element => {
            element.value = element.phaseNumber;
            element.label = element.phaseNumber;
            if (new Date(element.phaseEndDate) < new Date()) {
                element.disabled = true;
            } else {
                element.disabled = false;
            }
        });

        let helperText = "";
        if (whitelabelFieldError && whitelabelFieldError[w_index] && whitelabelFieldError[w_index][type]) {
            helperText = whitelabelFieldError[w_index][type];
        }

        return <>
            <FormControl className='w-100' variant="standard">
                <>
                    <label>{placeholder}{required && <span className='MuiInputLabel-asterisk'>&nbsp;*</span>}</label>
                    <MultiSelect
                        className='multi-select'
                        options={list}
                        value={row[type]}
                        onChange={(value) => {
                            whiteLabelDate(value, w_index, type);
                        }}
                        labelledBy={"Select Phase"} required={true}
                        overrideStrings={whitelabelsStrings}
                        valueRenderer={whitelabelsRenderer} />
                </>

                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>

            </FormControl>
        </>
    }

    const whiteLabelDateField = (row, placeholder, type, w_index, required) => {

        let helperText = "";
        if (whitelabelFieldError && whitelabelFieldError[w_index] && whitelabelFieldError[w_index][type]) {
            helperText = whitelabelFieldError[w_index][type];
        }

        let minDate = "";
        if (type == "offerEndDate" && row["offerStartDate"]) {
            minDate = new Date(row["offerStartDate"]);
        }

        return <>
            <div className="form-group pl-0 mb-0 w-100">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker label={placeholder}
                        value={row[type] ? new Date(row[type]) : ""}
                        slotProps={{ textField: { required: required } }}
                        minDate={minDate}
                        onChange={(newValue) => whiteLabelDate(newValue, w_index, type)}
                        format="dd-MM-yyyy" />
                </LocalizationProvider>
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
            </div>
        </>
    }

    const whiteLabelHandleInputChange = (e, w_index, type) => {
        const { value, checked } = e.target;
        const data1 = [...brandRowData];
        let data2 = {};
        if (type == "isGiftCardsAllowed" || type == "forMap" || type == "isBrandLive") {
            data2 = { ...data1[0].selectedWhiteLabelPlatforms[w_index], [type]: checked };
        } else {
            data2 = { ...data1[0].selectedWhiteLabelPlatforms[w_index], [type]: value };
        }
        data1[0].selectedWhiteLabelPlatforms[w_index] = data2;
        setbrandRowData(data1);
    };

    const whiteLabelDate = (value, w_index, type) => {
        const data1 = [...brandRowData];
        let data2 = { ...data1[0].selectedWhiteLabelPlatforms[w_index], [type]: value };
        data1[0].selectedWhiteLabelPlatforms[w_index] = data2;
        setbrandRowData(data1);
    };

    const checkBoxField = (filed, row, index, list) => {
        if (list) {
            let templist = JSON.parse(JSON.stringify(list));
            return <>
                {templist.map((res, index2) =>
                    <div key={"checkbox" + filed + index2} className="form-check form-check-inline custom-align mr-4">
                        <input
                            //  disabled={isBrandCreated}
                            onChange={e => handleInputChangeNew(e, index, res.field)}
                            checked={row.hasOwnProperty([res.field]) && row[res.field].toString() == res.value.toString()} name={res.field}
                            type="radio" id={res.lable} className="form-check-input mr-2" value={res.value}
                        />
                        <label className="form-check-label" htmlFor={res.lable}>
                            {res.lable}
                        </label>
                    </div>
                )}
            </>
        } else {
            <></>
        }
    }

    const selectField = (row, index, type) => {
        let iserror = false
        let helperText = ""
        if (fieldError[type]) {
            iserror = true;
            helperText = fieldError[type]
        }
        return <>
            <FormControl className='w-100' variant="standard">
                <InputLabel id="demo-simple-select-standard-label">Trial Month</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={row[type]}
                    name={type}
                    onChange={e => handleInputChangeNew(e, index, type)}
                    label="Trial Month"
                >
                    <MenuItem value={1}>1 Month</MenuItem>
                    <MenuItem value={2}>2 Months</MenuItem>
                    <MenuItem value={3}>3 Months</MenuItem>
                </Select>
                <FormHelperText className='text-danger'>
                    {helperText}
                </FormHelperText>
                {/* {(row[type] == true || row[type] == "true") &&
                    <div className='pt-2'>
                        {`Trial period ends on ${moment().add(DaysBasedOnMonth[row[type]], 'days').format('D/MM/YYYY')}`}
                    </div>
                } */}
            </FormControl>

        </>
    }

    let copyLink = (pageType, userData) => {
        // let urlLink = `${config.guestUrl}${userData.email}?type=${pageType}&token=${userData.guestLinkToken}`; 
        let urlLink = `${config.guestUrl}${userData.email}?type=${pageType}`;
        const el = document.createElement('textarea');
        el.value = urlLink;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        showMessage("Copied..!", "success");
    }

    let copyPublisherLink = () => {
        console.log("brandRowData", brandRowData);
        if (brandRowData && brandRowData[0] && brandRowData[0].name) {
            let urlLink = `${config.adminUrl}user/edit/brand/${brandRowData[0]._id}/${brandRowData[0].name}/${brandRowData[0].brandId}?from=publisher`;
            const el = document.createElement('textarea');
            el.value = urlLink;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            showMessage("Copied..!", "success");
        }
    }

    const customValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Publisher selected" : "Select Publisher"
        }
    };

    const whitelabelsRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Partner selected" : "Select Partner"
        }
    };

    return (
        <>
            <div>
                <div className="row">

                    {userDetails.role != "bank" &&
                        <div className="col-md-12">
                            <div className="heading">
                                <div className="row">
                                    <div className="col-md-8 d-flex align-items-center">
                                        <h2 className="m-0 ">
                                            <span>
                                                {/* <Icon className="heading-icon ">{icon}</Icon> */}
                                            </span>

                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="row justify-content-between">
                                    <div className="col-md-4">
                                        {!brandname &&
                                            <div className="form-group">
                                                <label><b>Select Brand</b></label>
                                                <CreatableSelect
                                                    defaultValue={brandname ? brandList[0] : ""}
                                                    // isDisabled={isBrandCreated}
                                                    isClearable={true}
                                                    options={brandList}
                                                    onChange={(value, action) => {
                                                        if (action.action == "clear") {
                                                            getBrandData("")
                                                            setbrandRowData([])
                                                            dispatch({
                                                                type: CLEAR_BRANDBRANCH_DATA
                                                            })
                                                            history.push('/user/create/brand')
                                                            return
                                                        }
                                                        if (!value) {
                                                            setbrandRowData([])
                                                            return
                                                        }
                                                        if (value.__isNew__) {
                                                            setbrandRowData([{
                                                                "name": value.value,
                                                                "website": "",
                                                                "brandname": value.value,
                                                                "registeredAddress": "",
                                                                "companyName": "",
                                                                description: "",
                                                                // "website": "",
                                                                "created_by": "ui",
                                                                isNew: true,
                                                                isAutoDebit: 'false',
                                                                isGroupBrand: 'false',
                                                                entity_url: "",
                                                                two_invoice_per_month: true,
                                                                is_two_customer: false,
                                                                payment_terms_days: "",
                                                                selectedPublisher: [],
                                                                selectedWhiteLabelPlatforms: []
                                                            }])
                                                        } else {
                                                            dispatch(checkBrandExistApiCall({ brandName: value.label, brandId: value.value }))
                                                            console.log(value)
                                                            value.item.description = "";
                                                            value.item.registeredAddress = value.item.address;
                                                            value.item.companyName = "";
                                                            delete value.item.address;
                                                            value.item.website = value.item.website_url;
                                                            value.item.isAutoDebit = 'false';
                                                            value.item.isGroupBrand = 'false';
                                                            value.item.brandId = value.item.brand_id;
                                                            value.item.name = value.item.brandname;
                                                            value.two_invoice_per_month = true;
                                                            value.is_two_customer = false;
                                                            value.payment_terms_days = "";
                                                            value.selectedPublisher = [];
                                                            value.selectedWhiteLabelPlatforms = [];
                                                            setbrandRowData([value.item]);
                                                            getBrandData(value.label);
                                                        }
                                                    }}
                                                    onInputChange={(value, actionMeta) => {
                                                        if (actionMeta.action == "input-change") {
                                                            if (value.length >= 3)
                                                                getBrandData(value)
                                                        }
                                                    }}

                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <div className="form-group">

                                            {existingBrand.data.userList.length > 0 &&
                                                existingBrand.data.userList.map((res, index) => {
                                                    if (!existingBrand.data.amexDiningSignup && res.guestUser && !res.proceesCompleted && index == 0) {
                                                        return <>
                                                            <div><label><b>Guest User Link</b></label></div>
                                                            <div>
                                                                {/* <div>
                                                                    <span>
                                                                        Insights - <a style={{ color: "blue" }} href={`${config.guestUrl}${res.email}?type=insights`} target='_blank'>  {`${res.email}`}</a></span>
                                                                    <span onClick={() => copyLink("insights", res)} > <Icon style={{ fontSize: "1.5rem", cursor: "pointer" }} className="text-info">content_copy</Icon></span>
                                                                </div> */}
                                                                {/* <br /> */}
                                                                <div style={{ marginTop: "5px" }}>
                                                                    <span>
                                                                        Onboarding - <a style={{ color: "blue" }} href={`${config.guestUrl}${res.email}?type=campaign`} target='_blank'>  {`${res.email}`}</a> </span>
                                                                    <span onClick={() => copyLink("campaign", res)} > <Icon style={{ fontSize: "1.5rem", cursor: "pointer" }} className="text-info">content_copy</Icon></span>
                                                                </div>
                                                                <div style={{ marginTop: "5px" }}>
                                                                    <span>
                                                                        AMEX - <a style={{ color: "blue" }} href={`${config.guestUrl}${res.email}?type=campaign&user=amex`} target='_blank'>  {`${res.email}`}</a> </span>
                                                                    <span onClick={() => copyLink("campaign&user=amex", res)} > <Icon style={{ fontSize: "1.5rem", cursor: "pointer" }} className="text-info">content_copy</Icon></span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    } else if (existingBrand.data.amexDiningSignup && res.guestUser && !res.proceesCompleted && index == 0) {
                                                        return <>
                                                            <div className='publisher-link'>
                                                                <div> Publisher Link : <a style={{ color: "blue" }} target='_blank'></a> </div>
                                                                <div className='m-l-5' onClick={() => copyPublisherLink()}>
                                                                    <Icon style={{ fontSize: "1.5rem", cursor: "pointer" }} className="text-info">content_copy</Icon>
                                                                </div>
                                                            </div>
                                                        </>
                                                    } else {
                                                        <div></div>
                                                    }
                                                })
                                            }


                                        </div>
                                    </div>
                                </div>
                                {brandRowData.length > 0 &&
                                    <>
                                        <div className="mt-3">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Brand Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {brandRowData.map((row, index) => (
                                                        <tr key={row._id + "brandDet"}>
                                                            <td >
                                                                <div className="m-0 p-0 row">
                                                                    <div ref={ref => { refList.current[index] = ref; }}
                                                                        className="m-0 p-0 pt-1 col-10 row d-flex align-items-center no-gutters">

                                                                        {brand_id &&
                                                                            <div className="p-2 col-lg-2  input-group justify-content-center">
                                                                                {inputField(row, 'Brand Id', 'brandId', "", index, true, false)} 
                                                                            </div>
                                                                        }
                                                                        <div className="p-2 col-lg-2  input-group justify-content-center">
                                                                            {inputField(row, 'Brand Name', 'name', brandNameRegex, index, row.isNew, false)}
                                                                        </div>
                                                                        <div className="p-2 col-lg-2  input-group justify-content-center">
                                                                            {inputField(row, 'Company Name', 'companyName', "", index, false, false)}
                                                                        </div>
                                                                        <div className="p-2 col-lg-4  input-group justify-content-center ">
                                                                            {inputField(row, 'Address', 'registeredAddress', "", index, false, true)}
                                                                        </div>

                                                                        <div className="p-2 col-lg-3  input-group justify-content-center ">
                                                                            {inputField(row, 'Website Url', 'website', websiteRegex, index, false, false)}
                                                                        </div>

                                                                        <div className="p-2 col-lg-4 input-group justify-content-center m-t-10">
                                                                            {inputField(row, 'Description', 'description', "", index, false, false)}
                                                                        </div>

                                                                        <div className="d-flex col-5 auto-debit-txt-box m-t-10">
                                                                            <div className="form-group pl-0 mb-0">
                                                                                <label>Auto Debit</label>
                                                                                <div className="d-block d-sm-block d-md-flex radio-container">
                                                                                    {checkBoxField("isAutoDebit", row, index,
                                                                                        [{ field: "isAutoDebit", value: true, lable: "Optional (1%)" },
                                                                                        { field: "isAutoDebit", value: false, lable: "Optional" },
                                                                                        { field: "isAutoDebit", value: "Mandatory", lable: "Mandatory" }])}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex col-2 auto-debit-txt-box m-t-10">
                                                                            <div className="form-group pl-0 mb-0">
                                                                                <label>Group</label>
                                                                                <div className="d-block d-sm-block d-md-flex radio-container">
                                                                                    {checkBoxField("isGroupBrand", row, index,
                                                                                        [{ field: "isGroupBrand", value: true, lable: "Yes" },
                                                                                        { field: "isGroupBrand", value: false, lable: "No" }])}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {brandRowData[0] && brandRowData[0].isGroupBrand == "true" &&
                                                                            <div className="d-flex col-3 m-t-10">
                                                                                <div className="form-group pl-0 mb-0 w-100">
                                                                                    {inputField(row, 'Select Group', 'groupId', "", index, false, true)}
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className="p-2 col-lg-3 input-group justify-content-center m-t-10">
                                                                            {inputField(row, 'Comments', 'comments', "", index, false, false)}
                                                                        </div>

                                                                        <div className="d-flex row col-12 m-t-10 b-c-subtitle">
                                                                            Invoice Details
                                                                        </div>

                                                                        {brandRowData[0] && brandRowData[0].isGroupBrand != "true" &&
                                                                            <div className="d-flex row col-12 m-t-10">
                                                                                <div className="d-flex col-4 m-t-10">
                                                                                    <div className="form-group pl-0 mb-0 w-100">
                                                                                        {inputField(row, 'Select Invoice Preference', 'invoice_preference', "", index, false, false)}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex col-4 m-t-10">
                                                                                    <div className="form-group pl-0 mb-0 w-100">
                                                                                        {inputField(row, 'Select Invoice Preference Level 2', 'invoice_preference_level_2', "", index, false, false)}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex col-4 m-t-10">
                                                                                    <div className="form-group pl-0 mb-0 w-100">
                                                                                        {inputField(row, 'Select Separate Invoice', 'separateInvoice', "", index, false, false)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className="d-flex row col-3 m-t-10">
                                                                            <div className="col-12 p-l-10">
                                                                                Two Invoice Per month
                                                                            </div>
                                                                            <div className="row col-12 m-l--5 m-t-10">
                                                                                <input type='checkbox' name="two_invoice_per_month"
                                                                                    defaultChecked={row.two_invoice_per_month} className="distributor-checkbox"
                                                                                    id="two_invoice_per_month"
                                                                                    onChange={(e) => {
                                                                                        handleInputChangeNew(e, index, "two_invoice_per_month");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex row col-3 m-t-10">
                                                                            <div className="col-12 p-l-10">
                                                                                Is Two Customers
                                                                            </div>
                                                                            <div className="row col-12 m-l--5 m-t-10">
                                                                                <input type='checkbox' name="is_two_customer"
                                                                                    defaultChecked={row.is_two_customer} className="distributor-checkbox"
                                                                                    id="is_two_customer"
                                                                                    onChange={(e) => {
                                                                                        handleInputChangeNew(e, index, "is_two_customer");
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        {brandRowData[0] && brandRowData[0].isGroupBrand == "false" ?
                                                                            <div className="d-flex col-3 m-t-10">
                                                                                {inputField(row, 'Payment Terms Days', 'payment_terms_days', "", index, false, true)}
                                                                            </div>
                                                                            : ""}

                                                                        <div className="d-flex col-3 m-t-10">
                                                                            <div className="form-group pl-0 mb-0 w-100 dropdown-1-1">
                                                                                {inputField(row, 'Select Publisher', 'selectedPublisher', "", index, false, true)}
                                                                            </div>
                                                                        </div>

                                                                        {checkPublisherSelected(row) &&
                                                                            <div className="row col-12 mt-3 brand-d-s">
                                                                                <table className="table table-bordered">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <td> Distributor name </td>
                                                                                            <td> Cashback % </td>
                                                                                            <td> Success Fee % </td>
                                                                                            <td> Start date </td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {distributorList.map((dis_row, index) => {

                                                                                            return (
                                                                                                row.selectedPublisher && row.selectedPublisher.findIndex(a => a.value == dis_row.distributors_name) != -1 ?
                                                                                                    (
                                                                                                        <tr key={index + "distributors"} className="distributors-row">
                                                                                                            <td className="p-t-10"> {dis_row.distributors_display_name}  </td>
                                                                                                            <td>
                                                                                                                <FormControl className='w-100' variant="standard">
                                                                                                                    <TextField
                                                                                                                        value={dis_row.percentage}
                                                                                                                        id={`distributors_name${index}`}
                                                                                                                        name={`distributors_name${index}`}
                                                                                                                        type="text"
                                                                                                                        className={`form-control mb-3`}
                                                                                                                        onChange={e => handleDistributorValue(e, index, 'percentage')}
                                                                                                                    />
                                                                                                                </FormControl>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <FormControl className='w-100' variant="standard">
                                                                                                                    <TextField
                                                                                                                        value={dis_row.successFee}
                                                                                                                        id={`successFee${index}`}
                                                                                                                        name={`successFee${index}`}
                                                                                                                        type="text"
                                                                                                                        className={`form-control mb-3`}
                                                                                                                        onChange={e => handleDistributorValue(e, index, 'successFee')}
                                                                                                                    />
                                                                                                                </FormControl>
                                                                                                            </td>
                                                                                                            <td className='date-rang-box'>
                                                                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                                                    <DatePicker label="From"
                                                                                                                        value={dis_row.startDate ? new Date(dis_row.startDate) : ""}
                                                                                                                        slotProps={{ textField: { required: false } }}
                                                                                                                        onChange={(newValue) => handleDistributorStartDate(newValue, index, 'startDate')}
                                                                                                                        format="dd-MM-yyyy"
                                                                                                                    // minDate={new Date()}
                                                                                                                    />
                                                                                                                </LocalizationProvider>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ) : ""
                                                                                            )

                                                                                        }
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        }

                                                                        <div className="d-flex row col-12 m-t-10 b-c-subtitle">
                                                                            Whitelabel Platforms
                                                                        </div>

                                                                        <div className="d-flex col-3 m-t-10">
                                                                            <div className="form-group pl-0 mb-0 w-100 dropdown-1-1">
                                                                                {inputField(row, 'Whitelabel Platforms', 'selectedWhiteLabelPlatforms', "", index, false, false)}
                                                                            </div>
                                                                        </div>

                                                                        {row.selectedWhiteLabelPlatforms && row.selectedWhiteLabelPlatforms.map((whiteLabel_row, w_index) => {
                                                                            return (
                                                                                <div className="row d-flex col-12 m-t-10" key={"b-w-l" + w_index}>
                                                                                    <div className="d-flex row col-12 m-t-10 b-c-subtitle">
                                                                                        {whiteLabel_row.partnerName}
                                                                                    </div>

                                                                                    <div className="row d-flex col-12 m-t-10 m-l-0">

                                                                                        <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            {whiteLabelInputField(whiteLabel_row, 'Brand Name', 'brandName', w_index, "textbox", true)}
                                                                                        </div>

                                                                                        <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            {whiteLabelInputField(whiteLabel_row, 'Brand Logo Url', 'brandLogoUrl', w_index, "textbox", true)}
                                                                                        </div>

                                                                                        <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            {whiteLabelInputField(whiteLabel_row, 'Brand Map Logo', 'brandMapLogo', w_index, "textbox", true)}
                                                                                        </div>

                                                                                        <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                                                                                            <div className="col-12 p-l-10">
                                                                                                Is Gift Cards Allowed
                                                                                            </div>
                                                                                            <input type='checkbox' name="isGiftCardsAllowed"
                                                                                                defaultChecked={whiteLabel_row.isGiftCardsAllowed} className="b-checkbox"
                                                                                                id="isGiftCardsAllowed"
                                                                                                onChange={(e) => {
                                                                                                    whiteLabelHandleInputChange(e, index, "isGiftCardsAllowed");
                                                                                                }} />
                                                                                        </div>

                                                                                        <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            <div className="form-group pl-0 mb-0 w-100 dropdown-1-1">
                                                                                                {whiteLabelPhase(whiteLabel_row, 'Select Phase', 'brandPhase', w_index, whiteLabel_row.phases, true)}
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            <div className="form-group pl-0 mb-0 w-100">
                                                                                                {whiteLabelInputField(whiteLabel_row, 'Select Offer Category', 'offerCategory', w_index, "dropdown", true)}
                                                                                            </div>
                                                                                        </div> */}

                                                                                        {/* <div className="p-2 col-lg-3  input-group justify-content-center">
                                                                                            <div className="form-group pl-0 mb-0 w-100">
                                                                                                {whiteLabelInputField(whiteLabel_row, 'Select Wet Venues', 'forMap', w_index, "dropdown", true)}
                                                                                            </div>
                                                                                        </div> */} 

                                                                                        {/* <div className="p-2 col-lg-3  input-group justify-content-center"></div> */}
                                                                                        <div className="d-flex col-3 m-t-20">
                                                                                            {whiteLabelDateField(whiteLabel_row, 'Offer Start date', 'offerStartDate', w_index, true)}
                                                                                        </div>

                                                                                        <div className="d-flex col-3 m-t-20">
                                                                                            {whiteLabelDateField(whiteLabel_row, 'Offer End date', 'offerEndDate', w_index, true)}
                                                                                        </div>

                                                                                        <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                                                                                            <div className="col-12 p-l-10">
                                                                                                Wet Venues
                                                                                            </div>
                                                                                            <input type='checkbox' name="forMap" defaultChecked={whiteLabel_row.forMap}
                                                                                                className="b-checkbox" id="forMap" onChange={(e) => {
                                                                                                    whiteLabelHandleInputChange(e, index, "forMap");
                                                                                                }} />
                                                                                        </div>

                                                                                        {/* <div className="d-flex row col-3 m-t-10 b-checkbox-contain min-h">
                                                                                            <div className="col-12 p-l-10">
                                                                                            Brand Live
                                                                                            </div>
                                                                                            <input type='checkbox' name="isBrandLive" defaultChecked={whiteLabel_row.isBrandLive}
                                                                                                className="b-checkbox" id="isBrandLive" onChange={(e) => {
                                                                                                    whiteLabelHandleInputChange(e, index, "isBrandLive");
                                                                                                }} />
                                                                                        </div> */}

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )}


                                                                    </div>
                                                                    <div className="m-0 p-0 pt-1 col-2 row d-flex align-items-center no-gutters justify-content-center">
                                                                        <FormikImageUpload
                                                                            image={row.photo}
                                                                            onChange={async (photoUrl) => {
                                                                                if (photoUrl) {
                                                                                    const data1 = [...brandRowData]
                                                                                    const data2 = { ...data1[index], "photo": photoUrl }
                                                                                    data1[index] = data2
                                                                                    setbrandRowData(data1)
                                                                                }
                                                                                // createEntity(photoUrl)

                                                                            }}
                                                                        // disabled={isSubmitting || isDisabled}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }
                                <div className="row text-right no-gutters col-12 m-0 p-0 justify-content-end">
                                    {brandRowData.length > 0 &&
                                        <button
                                            disabled={isSubmitting || existingBrand.data.brands.length > 0}
                                            className="btn btn-sm btn-primary newbrandBtn"
                                            onClick={() => createEntity()}
                                        >  Add  </button>
                                    }
                                    {brandRowData.length > 0 &&
                                        <button
                                            disabled={isSubmitting || existingBrand.data.brands.length == 0}
                                            className="btn btn-sm btn-primary newbrandBtn ml-2"
                                            onClick={() => createEntity()}
                                        >  Update  </button>
                                    }
                                </div>

                            </div>
                        </div>
                    }

                    {showBranchSection && (existingBrand.data.brands.length > 0 && brandRowData[0]) &&
                        <div className={classNames("col-md-12", { 'm-t-60': userDetails.role == "bank" })}>
                            <BranchCreate existingBrand={existingBrand.data} brandDetails={brandRowData[0]?.isNew ? createBrandEntitystate.data : createBrandstate.data}
                                brandRowData={brandRowData[0]} />
                        </div>
                    }
                    {userDetails.role != "bank" && (existingBrand.data.userList.length > 0 || existingBrand.data.branch.length > 0 || createBranchstate.data.respCode) &&
                        <div className="col-md-12">
                            <UserCreate brandDetails={brandRowData[0]?.isNew ? createBrandEntitystate.data : createBrandstate.data} />
                        </div>
                    }
                </div>
            </div>


        </>
    )
};
