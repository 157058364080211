// import axiosMethodRequest from '../../config/service';
import lambdaAxiosMethodRequest from '../../config/lambdaService';
import { GET_BRAND_ENTITY_LIST } from '../../redux/newBrandBranch/actionTypes';
import config from '../../config/config';

// export const getEntityDataByFilterApicall = (data, callback) => {
export const getEntityDataByFilterApicall = (brandName, callback) => {
    return dispatch => {
        // const url = data ? `entity?filter=${JSON.stringify(data)}` : "entity"
        // return axiosMethodRequest('GET', url).then((response) => {
        //     if (response && response.data && response.data.respCode) {
        //         dispatch({
        //             type: GET_BRAND_ENTITY_LIST,
        //             payload: { data: response.data.entity, loader: false }
        //         })
        //     }
        // })
        
        // let url = "https://ykkydvweug.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
        let url = "https://dnbfyn7ccl.execute-api.eu-west-2.amazonaws.com/default/find_brand_branch_nodes_in_graph";
        let body = {
            "name": brandName
        }
        // let x_api_key = "W5v8CrRCTL7rApej5f5yr6jM1LooFH0G6R0ny9Pc";
        let x_api_key = "lQKYGZEsmZ6gYvbs08C0J6v8oE41k9Fa5umPkbRh";
        return lambdaAxiosMethodRequest('POST', url, body, x_api_key).then((response) => {
            if (response && response.status == 200 && response.data) {
                dispatch({
                    type: GET_BRAND_ENTITY_LIST,
                    payload: { data: response.data, loader: false }
                })
            }
        })

    }
}