import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { useDispatch, useSelector } from 'react-redux'
import { BulkUploadBrandApicall } from './../services/newBrandBranch/createBrandApiCall';
import { CreateBranchApiCall } from './../services/newBrandBranch/createBranchApiCall';
import { createUserApiCall, createGroupUserAction, createGroupAction } from './../services/newBrandBranch/createUserApiCall';
import { newBrandBranchSelectors } from './../redux/newBrandBranch';
// import { CREATE_BRAND, CREATE_BRANCH, CREATE_USER, UPLOAD_BRAND } from './../redux/newBrandBranch/actionTypes';
import { UPLOAD_BRAND } from './../redux/newBrandBranch/actionTypes';
import lambdaAxiosMethodRequest from './../config/lambdaService';
import axiosMethodRequest from './../config/service';
import * as XLSX from "xlsx";
import config from './../config/config';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { generateId, groupColumnList, brandWithoutGroup, brandWithGroup, branchesColumnList, usersColumnList, 
    legendColumnForBrand, legendColumnForGroup, whiteLabelBrandColumnList, whiteLabelBranchColumnList } from './../utils/helpers';
import moment from 'moment';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    cancelText = "Cancel",
    centered = false,
    whitelabels,
    tempDistributorList
}) => {

    const [selectedFile, setSelectedFile] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [isUploadedData, setIsUploadedData] = useState(false);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [uploadFor, setUploadFor] = useState("group");

    const uploadBrandstate = useSelector(
        newBrandBranchSelectors.uploadBrandstate
    );

    const downloadTemplate = () => {

        const workbook = XLSX.utils.book_new();
        if (uploadFor == "group") {
            let groupColumn = groupColumnList;
            const sheet0 = XLSX.utils.json_to_sheet(groupColumn);
            XLSX.utils.book_append_sheet(workbook, sheet0, "Group");
        }

        let brandsColumn = [];
        if (uploadFor == "brand") {
            brandsColumn = brandWithoutGroup;
        } else {
            brandsColumn = brandWithGroup;
        }

        tempDistributorList.forEach(e3 => {
            brandsColumn[0][e3.distributors_display_name] = "No";
            brandsColumn[0][e3.distributors_display_name + " Cashback"] = "";
            brandsColumn[0][e3.distributors_display_name + " SuccessFee"] = "";
            brandsColumn[0][e3.distributors_display_name + " Start Date"] = "";
        });

        const sheet = XLSX.utils.json_to_sheet(brandsColumn);
        XLSX.utils.book_append_sheet(workbook, sheet, "Brand");
        console.log("XLSX.utils", XLSX.utils);

        const branchesColumn = branchesColumnList;
        const sheet2 = XLSX.utils.json_to_sheet(branchesColumn);
        XLSX.utils.book_append_sheet(workbook, sheet2, "Branch");

        if (whitelabels && whitelabels.length > 0) {
            let list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBrandColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4)
            });
            const sheet6 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            list1 = [];
            whitelabels.forEach(e3 => {
                let e4 = JSON.parse(JSON.stringify(whiteLabelBranchColumnList[0]));
                e4["White Label Name"] = e3.partnerName;
                list1.push(e4);
            });
            const sheet7 = XLSX.utils.json_to_sheet(list1);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");

        } else {
            const whiteLabelBrandColumn = whiteLabelBrandColumnList;
            const sheet6 = XLSX.utils.json_to_sheet(whiteLabelBrandColumn);
            XLSX.utils.book_append_sheet(workbook, sheet6, "White Label - Brand");

            const whiteLabelBranchColumn = whiteLabelBranchColumnList;
            const sheet7 = XLSX.utils.json_to_sheet(whiteLabelBranchColumn);
            XLSX.utils.book_append_sheet(workbook, sheet7, "White Label - Branch");
        }

        if (uploadFor == "brand") {
            const usersColumn = usersColumnList;
            const sheet3 = XLSX.utils.json_to_sheet(usersColumn);
            XLSX.utils.book_append_sheet(workbook, sheet3, "User");
        }

        let legendColumn = [];
        if (uploadFor == "brand") {
            legendColumn = legendColumnForBrand;
        } else {
            legendColumn = legendColumnForGroup;
        }

        const sheet4 = XLSX.utils.json_to_sheet(legendColumn);
        XLSX.utils.book_append_sheet(workbook, sheet4, "Legend");
        XLSX.writeFile(workbook, new Date().getTime() + "-" + uploadFor + "-BulkUploadSample.xlsx");
    }

    const downloadCAMLinks = () => {

        let brandsColumn = [];
        if (uploadFor == "brand" && uploadBrandstate.data.matchedData) {
            uploadBrandstate.data.matchedData.forEach(element => {
                if (element.users) {
                    let userDetails = element.users[0];
                    let userName = userDetails["First Name"];
                    userName = userDetails["Last Name"] ? userName + " " + userDetails["Last Name"] : userName;
                    let userEmail = userDetails["Email"];
                    brandsColumn.push({
                        "Brand Name": element.brandDetails["Brand Name"],
                        "User name": userName,
                        "Address": element.brandDetails["Address"],
                        "Onboarding": `${config.guestUrl}${userEmail}?type=campaign`,
                        "AMEX": `${config.guestUrl}${userEmail}?type=campaign&user=amex`,
                    });
                }
            });
        } else if(uploadFor == "group" && uploadBrandstate.data.groupDetails) {
            uploadBrandstate.data.groupDetails.forEach(element => {               
                let userName = element["First Name"];
                userName = element["Last Name"] ? userName + " " + element["Last Name"] : userName;
                let userEmail = element["Email"];
                brandsColumn.push({
                    "Group Name": element["Group Name"],
                    "User name": userName, 
                    "Onboarding": `${config.guestUrl}${userEmail}?type=campaign`,
                    "AMEX": `${config.guestUrl}${userEmail}?type=campaign&user=amex`,
                }); 
        });
        }

        const sheet = XLSX.utils.json_to_sheet(brandsColumn);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Links");

        XLSX.writeFile(workbook, new Date().getTime() + "-CAMLinks.xlsx");

    }

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0]);
        // e.target.value = null;
        dispatch({ type: UPLOAD_BRAND, payload: { data: {}, error: {}, loader: false } })
    }

    const clearErrorValue = e => {
        dispatch({ type: UPLOAD_BRAND, payload: { data: {}, error: {}, loader: false } });
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const removeSelectedFile = () => {
        // id="bulk-upload"
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
        setSelectedFile({});
    }

    const uploadBrandData = () => {
        var formdata = new FormData();
        formdata.append('excelData', selectedFile);
        formdata.append('uploadFor', uploadFor);
        dispatch(BulkUploadBrandApicall(formdata));
    }

    useEffect(() => {
        dispatch({ type: UPLOAD_BRAND, payload: { data: {}, error: {}, loader: false } })
        return () => {
            dispatch({ type: UPLOAD_BRAND, payload: { data: {}, error: {}, loader: false } });
            // dispatch({ type: CREATE_BRAND, payload: { data: {}, error: {}, loader: false } });
            // dispatch({ type: CREATE_BRANCH, payload: { data: {}, error: {}, loader: false } });
            // dispatch({ type: CREATE_USER, payload: { data: {}, error: {}, loader: false } });
        };
    }, []);

    useEffect(() => {
        if (uploadBrandstate.data.matchedData) {
            createBrand(uploadBrandstate.data.matchedData, 0);
        }
    }, [uploadBrandstate.data]);


    const createBrand = async (data, createBrandIndex) => {
        setIsLoader(true);
        let element = uploadBrandstate.data.matchedData[createBrandIndex];

        if (typeof element.brandDetails["Brand Name"] == "number") {
            element.brandDetails["Brand Name"] = element.brandDetails["Brand Name"].toString();
        }

        let body = {
            "brandname": element.brandDetails["Brand Name"] || "",
            "name": element.brandDetails["Brand Name"] || "",
            "companyName": element.brandDetails["Company Name"] || "",
            "website": element.brandDetails["Website URL"] || "",
            "registrationId": "",
            "registeredAddress": element.brandDetails["Address"] || "",
            "description": element.brandDetails["Description"] || "",
            "sector_id": '16',
            "cuisines_list": [],
            "brand_id": "",
            "created_by": "ui",
            "isNew": true,
            "entity_url": "",
            "comments": element.brandDetails["Comments"],
            "payment_terms_days" : element.brandDetails["Payment Terms Days"]
        }; 

        if (element.brandDetails["Invoice Preference Level 1"] == "Group") {
            body.invoice_preference = "group";
        } else if (element.brandDetails["Invoice Preference Level 1"] == "Brand") {
            body.invoice_preference = "brand";
        }

        if (element.brandDetails["Invoice Preference Level 2"] == "Brand") {
            body.invoice_preference_level_2 = "brand";
        } else if (element.brandDetails["Invoice Preference Level 2"] == "Branch") {
            body.invoice_preference_level_2 = "branch";
        } else if (element.brandDetails["Invoice Preference Level 2"] == "Publisher") {
            body.invoice_preference_level_2 = "publisher";
        }


        if (element.brandDetails["Separate Invoice"] == "Brand" || element.brandDetails["Separate Invoice"] == "Publisher") {
            body.separateInvoice = "Yes";
        } else {
            body.separateInvoice = "No";
        }

        if (element.brandDetails["Auto Debit"] == "Optional (1%)") {
            body.isAutoDebit = "true";
        } else if (element.brandDetails["Auto Debit"] == "Optional") {
            body.isAutoDebit = "false";
        } else if (element.brandDetails["Auto Debit"] == "Mandatory") {
            body.isAutoDebit = "Mandatory";
        }

        let selectedPublisher = [];
        let distributorDealSetWithBrand = [];
        tempDistributorList.forEach(e8 => {
            if (element.brandDetails[e8.distributors_display_name] == "Yes" && !selectedPublisher.includes(e8.distributors_name)) {
                selectedPublisher.push(e8.distributors_name);
                distributorDealSetWithBrand.push({
                    "distributors_name": e8.distributors_name,
                    "successFee": element.brandDetails[e8.distributors_display_name + " SuccessFee"],
                    "percentage": element.brandDetails[e8.distributors_display_name + " Cashback"],
                    "startDate": element.brandDetails[e8.distributors_display_name + " Start Date"]
                });
            }
        });

        body.selectedPublisher = selectedPublisher.join(",");
        body.distributorDealSetWithBrand = distributorDealSetWithBrand;

        let x_api_key = "1t02ARoFco80EpL5r3MqQ1qypxwI8qMO7iRhFpi5";
        let url = "https://t2zociuop4.execute-api.eu-west-2.amazonaws.com/default/create_brand_node";
        let response = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);

        createBrandIndex++;
        if (response && response.data && response.data[0]) {
            body.brand_id = response.data[0].brand_id;
            body.brandId = response.data[0].brand_id;

            body.whitelabel = {};
            whitelabels.forEach(w_e => {
                let whiteLabelBrand = element.whiteLabelBrand || [];
                let filter = whiteLabelBrand.filter(a => a["White Label Name"] == w_e.partnerName);
                if (filter && filter.length > 0) {
                    body.whitelabel[w_e.partnerSerialNo] = {
                        brandLogoUrl: filter[0]["Brand Logo Url"],
                        brandMapLogo: filter[0]["Brand Map Logo"],
                        brandName: filter[0]["Brand Name"],
                        forMap: filter[0]["Wet Venues"] == "Yes" ? true : false,
                        isGiftCardsAllowed: filter[0]["Gift Cards Allowed"] == "Yes" ? true : false,
                        offerCategory: filter[0]["Offer Category"],
                        offerStartDate: new Date(filter[0]["Offer Start date"]),
                        offerEndDate: new Date(filter[0]["Offer End date"]),
                        brandPhase: [
                            filter[0]["Phase"] ? filter[0]["Phase"].toString() : ""
                        ]
                    }
                }
            });

            axiosMethodRequest("POST", "brands", body).then((response) => {
                // if (response && response.data && response.data.respCode) {
                if (response && response.data) {
                    body.brandObjId = response.data.brandId;
                    createBranch(element, body, createBrandIndex);
                }
            })

        }

        // if (uploadBrandstate.data.matchedData.length !== createBrandIndex) {
        //     createBrand(uploadBrandstate.data.matchedData, createBrandIndex);
        // }
    }

    const createBranch = async (element, createBrandBody, createBrandIndex) => {

        let url = "https://au3jnfb458.execute-api.eu-west-2.amazonaws.com/default/create_branch_kmid_node";
        let x_api_key = "ObZGuHtrxX8ib2iIU1Pbh3N240RMoI3y5jHw7HWB";
        let createBranchIndex = 0;

        let branchDetails = [];

        async function createBranchOnNode(branches, index) {

            let e2 = branches[index];
         
            let body = {
                "branch_name": e2["Branch Name"] || "",
                "entity_url": e2["Tripadvisor Url"] || "",
                "brandname": createBrandBody.brandname || "",
                "brand_id": createBrandBody.brandId || "",
                "city": "",
                "description": "",
                "address": e2["Address"] || "",
                "merchantId": e2["Visa/Mastercard Merchant ID"] || "",
                "amexMerchantID": e2["Amex Merchant ID"] || "",
                "zettleMerchantID": e2["Zettle/Square Merchant ID"] || "",
                "postalTown": e2["Postal Town"] || "",
                "postcode": e2["Post code"] || "",
                "tripadvisorUrl": e2["Tripadvisor Url"] || "",
                "sector_id": "16" || "",
                "cuisines_list": [] || "",
                "kmid": "",
                "tp_rank": e2["TP Rank"] || "",
                "copy": e2["Copy"] || ""
            };

            let nodeBody = {
                "name": e2["Branch Name"],
                "brandName": createBrandBody.brandname,
                "brandId": createBrandBody.brandId,
                "created_by": "ui",
                "datasource": "ui",
                "email": e2["Email"],
                "contactEmail": e2["Email"],
                "entity_id": "",
                "latlong": "",
                "logo_url": "",
                "merchant_name": "",
                "phone_no": e2["Contact Phone"],
                "website_url": "",
                "isNew": true,
                "contactName": e2["Contact Name"],
                "contactPhone": e2["Contact Phone"],
                "created_date": new Date(),
                "brandObjId": createBrandBody.brandObjId,
                "cuisines_list": e2["Cuisine list"] || "",
                "num_reviews": e2["Number of reviews"] || "",
                "price_range": e2["Price range"] || "",
                "rating": e2["Restaurant rating"] || ""
            };
             
            let res = await lambdaAxiosMethodRequest('POST', url, body, x_api_key);
            createBranchIndex++;
            body.kmid = res.data && res.data[0] && res.data[0].kmid ? res.data[0].kmid : "";
            body.cuisines_list = e2["Cuisine list"];
            nodeBody = Object.assign(nodeBody, body);

            nodeBody.whitelabel = {};
            whitelabels.forEach(w_e => {
                let whiteLabelBranch = element.whiteLabelBranch || [];
                let filter = whiteLabelBranch.filter(a => a["White Label Name"] == w_e.partnerName && a["CAM Branch Name"] == e2["Branch Name"]);
                if (filter && filter.length > 0) {
                    nodeBody.whitelabel[w_e.partnerSerialNo] = {
                        "branchName": filter[0]["Branch Name"],
                        "branchCopy": filter[0]["Branch Copy"],
                        "displayAddress": filter[0]["Display address"],
                        "gmap_address": filter[0]["Google Maps address"] || "",
                        // "branchStartDate": new Date(filter[0]["Branch Start Date"]),
                        // "branchEndDate": new Date(filter[0]["Branch End Date"]),
                        "bookingLink": filter[0]["Booking Link"],
                        "cuisines_list": filter[0]["Cuisine list"],
                        "isFeatured": filter[0]["Is Featured"] == "Yes" ? true : false,
                        "branchPhase": [
                            filter[0]["Phase"] ? filter[0]["Phase"].toString() : ""
                        ]
                    }

                    let branchImage = filter[0]["Branch Image"] ? filter[0]["Branch Image"].split(",") : "";
                    let branchVideo = filter[0]["Branch Video"] ? filter[0]["Branch Video"].split(",") : "";

                    nodeBody.whitelabel[w_e.partnerSerialNo]["branchAssets"] = [];
                    let aa_length = [branchImage.length, branchVideo.length];
                    aa_length = Math.max(...aa_length);
                    if (aa_length > 0) {
                        for (let index = 0; index < aa_length; index++) {
                            nodeBody.whitelabel[w_e.partnerSerialNo]["branchAssets"].push({
                                "branchImage": branchImage[index] || "",
                                "branchVideo": branchVideo[index] || ""
                            });
                        }
                    }


                    // if (filter[0]["Branch Experience"] == "Yes") {
                    //     nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"] = {
                    //         "expStartDate": filter[0]["Exp Start Date"],
                    //         "expEndDate": filter[0]["Exp End Date"],
                    //         "expName": filter[0]["Exp Name"],
                    //         "expCopy": filter[0]["Exp Copy"]
                    //     }

                    //     let expImageUrl = filter[0]["Exp Image Url"] ? filter[0]["Exp Image Url"].split(",") : "";
                    //     let expThumbnailUrl = filter[0]["Exp Thumbnail Url"] ? filter[0]["Exp Thumbnail Url"].split(",") : "";
                    //     let expVideoUrl = filter[0]["Exp Video Url"] ? filter[0]["Exp Video Url"].split(",") : "";
                    //     nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"]["expAssets"] = [];
                    //     let aa_length = [expImageUrl.length, expThumbnailUrl.length, expVideoUrl.length];
                    //     aa_length = Math.max(...aa_length);

                    //     if (aa_length > 0) {
                    //         for (let index = 0; index < aa_length; index++) {
                    //             nodeBody.whitelabel[w_e.partnerSerialNo]["branchExperience"]["expAssets"].push({
                    //                 "branchExpImage": expImageUrl[index] || "",
                    //                 "branchExpThumbnail": expThumbnailUrl[index] || "",
                    //                 "branchExpVideo": expVideoUrl[index] || "",
                    //             });
                    //         }
                    //     }

                    // }
                }
            });
            
            nodeBody.merchantIds = {
                mastercardMerchantID : nodeBody.merchantId,
                amexMerchantID : nodeBody.amexMerchantID,
                zettleMerchantID : nodeBody.zettleMerchantID
            };
            delete nodeBody.merchantId;
            delete nodeBody.amexMerchantID;
            delete nodeBody.zettleMerchantID;

            branchDetails.push(nodeBody);
            if (createBranchIndex == branches.length) {
                dispatch(CreateBranchApiCall(branchDetails, "bulkUpload"));
                if (uploadFor == "brand") {
                    createUser(element, createBrandBody, createBrandIndex);
                }  else if (uploadFor == "group") {
                    createGroup(element, createBrandBody, createBrandIndex);
                }
            } else {
                createBranchOnNode(branches, createBranchIndex);
            }
        }
        createBranchOnNode(element.branches, 0);

    }

    const createUser = (element, createBrandBody, createBrandIndex) => {
        let bodyList = [];
        element.users.forEach(e2 => {
            bodyList.push({
                brandId: createBrandBody.brandId,
                contactPhone: e2["Phone"] || "",
                email: e2["Email"] || "",
                firstName: e2["First Name"] || "",
                guestUser: true,
                isNew: true,
                lastName: e2["Last Name"] || "",
                phone: e2["Phone"] || "",
                revenueCount: 0,
                role: "companyAdmin"
            });
        });
        dispatch(createUserApiCall(bodyList, "bulkUpload"));
        if (uploadBrandstate.data.matchedData.length !== createBrandIndex) {
            createBrand(uploadBrandstate.data.matchedData, createBrandIndex);
        } else if (uploadBrandstate.data.matchedData.length == createBrandIndex) {
            setIsLoader(false);
            setIsUploadedData(true);
            callback("Uploaded successfully");
        }
    }

    let brandDetailsForTheGroup = [];
    const createGroup = (element, createBrandBody, createBrandIndex, matchedData) => {

        brandDetailsForTheGroup.push({
            value: createBrandBody.brandId,
            _id: createBrandBody.brandObjId,
            label: createBrandBody.brandname,
            group_name: element.brandDetails["Group Name"]
        });

        if (uploadBrandstate.data.matchedData.length !== createBrandIndex) {
            createBrand(uploadBrandstate.data.matchedData, createBrandIndex);
        } else if (uploadBrandstate.data.matchedData.length == createBrandIndex) {
            if (uploadBrandstate.data.groupDetails) {
                let bodyList = [];
                uploadBrandstate.data.groupDetails.forEach((e2, index) => {
                    e2.brandId = generateId(9);
                    bodyList.push({
                        "firstName": e2["First Name"] || "",
                        "lastName": e2["Last Name"] || "",
                        "email": e2["Email"] || "",
                        "phone": e2["Phone"] || "",
                        "brandName": e2["Group Name"] || "",
                        "guestUser": true,
                        "isNew": true,
                        "revenueCount": "85",
                        "role": "groupAdmin",
                        brandId: e2.brandId,
                        "from": "Add"
                    });

                    dispatch(createGroupUserAction(bodyList, response => {

                        let brandListOfGroup = [];
                        brandDetailsForTheGroup.forEach(element => {
                            if (e2["Group Name"] == element.group_name) {
                                brandListOfGroup.push({
                                    value: element.value,
                                    _id: element._id,
                                    label: element.label,
                                });
                            }
                        });

                        if (e2["Invoice Preference Level 1"] == "Group") {
                            e2["Invoice Preference Level 1"] = "group";
                        } else if (e2["Invoice Preference Level 1"] == "Brand") {
                            e2["Invoice Preference Level 1"] = "brand";
                        }

                        if (e2["Invoice Preference Level 2"] == "Brand") {
                            e2["Invoice Preference Level 2"] = "brand";
                        } else if (e2["Invoice Preference Level 2"] == "Branch") {
                            e2["Invoice Preference Level 2"] = "branch";
                        } else if (e2["Invoice Preference Level 2"] == "Publisher") {
                            e2["Invoice Preference Level 2"] = "publisher";
                        }

                        if (e2["Separate Invoice"] == "Brand" || e2["Separate Invoice"] == "Publisher") {
                            e2["Separate Invoice"] = "Yes";
                        } else {
                            e2["Separate Invoice"] = "No";
                        }

                        let groupDetail = {
                            email: e2["Email"] || "",
                            firstName: e2["First Name"] || "",
                            groupId: e2.brandId,
                            group_brand_ids: brandListOfGroup,
                            group_name: e2["Group Name"] || "",
                            group_type: "ownBrands",
                            invoice_preference: e2["Invoice Preference Level 1"] || "",
                            invoice_preference_level_2: e2["Invoice Preference Level 2"] || "",
                            lastName: e2["Last Name"] || "",
                            phone: e2["Phone"] || "",
                            role: "groupAdmin",
                            separateInvoice: e2["Separate Invoice"] || "",
                            userObjId: response.saved_userId
                        } 

                        dispatch(createGroupAction(groupDetail, response => { 
                            if (uploadBrandstate.data.groupDetails.length == (index + 1)) {  
                                setIsLoader(false);
                                setIsUploadedData(true);
                                callback("Uploaded successfully");
                            }
                        }));

                    }));
                });

            }
        }
    }


    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title>
                    {isUploadedData && "Download"}
                    {!isUploadedData && "Upload"}
                </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>


                <div className="row col-12 form bulk-brand-upload-modal">
                    {((uploadBrandstate && uploadBrandstate.loader) || isLoader) &&
                        <div className="loading">Loading...</div>}


                    {!isUploadedData &&
                        <div className="row col-12 form">
                            <div className="col-12">
                                <FormControl>
                                    <RadioGroup row
                                        defaultValue={uploadFor}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group" onChange={(e) => { setUploadFor(e.target.value) }} >
                                        <FormControlLabel value="group" control={<Radio />} label="Group" />
                                        <FormControlLabel value="brand" control={<Radio />} label="Brand" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-4">
                                <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={downloadTemplate} >
                                    Download {uploadFor == 'group' ? 'Group' : 'Brand'} Template
                                    <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                </button>
                            </div>

                            <div className="col-2">
                                <div class="bulk-upload-btn-wrapper">
                                    <button className="btn btn-primary mb-3" >
                                        Upload
                                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                                    </button>
                                    <input
                                        name="bulk-upload"
                                        id="bulk-upload"
                                        ref={inputRef}
                                        // onBlur={onBlur}
                                        accept=".xls,.xlsx"
                                        type='file'
                                        onClick={clearErrorValue}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                            <div className="col-6 file-name">
                                {selectedFile && selectedFile.name &&
                                    <>
                                        <div className='file-label'> <span>{selectedFile.name}</span> </div>
                                        <div className='file-delete' onClick={removeSelectedFile} >
                                            <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                                        </div>
                                    </>
                                }
                            </div>

                            {uploadBrandstate.error && uploadBrandstate.error.errorCode && uploadBrandstate.error.isInValid ?
                                <>
                                    <div className="col-12 invalid-title">
                                        Invalid details
                                    </div>

                                    {Object.keys(uploadBrandstate.error.data).map((a, index) => {

                                        return (
                                            <div className="col-12 invalid-file" key={index + "invalidData"}>
                                                {uploadBrandstate.error.data[a]}
                                            </div>
                                        )
                                    })}

                                    {/* {Object.keys(uploadBrandstate.error.data).map((a, index) => {
                                    return (
                                        {
                                            uploadBrandstate.error.data[a] &&
                                                <div className="col-12 invalid-file" key={index + "invalidData"}>
                                                    Please add branch details
                                                </div>
                                        }
                                    )
                                })} */}

                                </>
                                : uploadBrandstate.error && uploadBrandstate.error.errorCode &&
                                <div className="col-12 invalid-file">
                                    {uploadBrandstate.error.errorMessage}
                                </div>
                            }




                        </div>
                    }

                    {isUploadedData &&
                        <div className="row col-12 form">

                            <div className="col-4">
                                <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={downloadCAMLinks} >
                                    Download CAM Links
                                    <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">download</Icon>
                                </button>
                            </div>

                        </div>
                    }
                </div>


            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    {cancelText}
                </Button>
                {!isUploadedData &&
                    <Button variant="success" onClick={(e) => { uploadBrandData() }} disabled={!selectedFile.name}>
                        Save
                    </Button>
                }
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal