export const getBrandstate = (state) => {
  return state.newBrandBranch.brandList
};

export const createBrandEntitystate = (state) => {
  return state.newBrandBranch.createBrandEntity
};

export const createBrandstate = (state) => {
  return state.newBrandBranch.createBrand
};

export const getBranchstate = (state) => {
  return state.newBrandBranch.branchList
};

export const getExistingBrandState = (state) => {
  return state.newBrandBranch.existingBrand
};

export const createBranchstate = (state) => {
  return state.newBrandBranch.createBranch
};

export const createUserstate = (state) => {
  return state.newBrandBranch.createUser
};

export const deleteUserstate = (state) => {
  return state.newBrandBranch.deleteUser
};

export const deleteBranchstate = (state) => {
  return state.newBrandBranch.deleteBranch
};

export const getGroupState = (state) => {
  return state.newBrandBranch.groupList
};

export const getDistributorsState = (state) => {
  return state.newBrandBranch.distributors
};

export const uploadBrandstate = (state) => {
  return state.newBrandBranch.uploadBrand
};

export const getUploadedDatestate = (state) => {
  return state.newBrandBranch.ingestionLogDate
};