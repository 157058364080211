import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getCampaignAction,
  deleteCampaignAction,
  setCampaignForEditAction,
  setCampaignsPageAction,
  setCampaignsSortAction,
  setCampaignsSearchAction,
  setCampaignsFiltersAction
} from '../../redux/actions/campaignActions'
import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
//session expiry modal
import SessionExpiryModal from './../../components/sessionExpiryModal'
import { GetSessionValue } from "../../utils/sessionStorage";
import { FutureCampaignRole, Roles } from "../../utils/constants/constant";
import { MerchantCommentsForm } from "../../components/Forms/MerchantComments";
import { confirmMerchantAction } from "../../redux/actions";
import CampaignAssets from "../BrandsForBank/campaignAssets";
import BankPartialUserModal from '../../components/BankPartialUserModal';
import DistributorModal from '../../components/DistributorModal';
import { getDistributorAction, updateDistributorAction, getSettingDetailAction } from "../../redux/actions";
import CampaignSettings from "../CampaignSettings/CampaignSettings";
import PuaseCampaignGlobal from "../CampaignSettings/PuaseCampaignGlobal";
import { getAllCampaignAction } from '../../services/newBrandBranch/createUserApiCall';
import * as XLSX from "xlsx";

const getCurrency = () => {
  return 'AED'
}

class FutureCampaign extends Component {

  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    start_date: '',
    end_date: '',
    sortCount: 0,
    sessionExpiryModal: false,
    assetsModel: { data: {}, isOpen: false },
    bankPartialUserModal: { showBankPartialUserModal: false, selectedEntity: null },
    distributorModalData: { isOpenDistributorModal: false, selectedEntity: null },
    allDistributors: [],
    showCampaignModal: false,
    selectedCampaign: {},
    showPauseCampaignModal: false,
    pausedDateWithPublisher: [],
    campaignList: [],
    disableExport : true
  };

  pagination = {
    limit: 10,
    page: 1
  }

  fetchData = () => {
    let {
      getFutureCampaignData,
      filters
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField } = this.state
    if (searchField) {
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    let customerType = this.props.user.customerType
    let status = ""
    if (this.props.user.role == Roles.bank) {
      status = FutureCampaignRole
    }

    getFutureCampaignData(page, limit, filters, 'abc', customerType, status, (res, pagination) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal })
    })
  }

  componentDidMount() {
    this.fetchData(this.props.page || this.pagination.page);
    this.getDistributor();
    this.getSettings();
  }

  getSettings() {
    let { getSettingDetail, getAllCampaign } = this.props;
    getSettingDetail(callback => {
      if (callback && callback[0]) {
        this.setState({ pausedDateWithPublisher: callback[0].pausedDateWithPublisher });
      }
    })

    let query = "?campaignType=future";
    getAllCampaign(query, response => {
      if (response.campaigns) {
        let list = [];
        response.campaigns.forEach(element => {
          let aa = {
            brandName: element.brandName,
            campaignName: element.campaignName,
            campaignStart: moment(element.openingTime).format('DD-MM-YYYY').toString(),
            campaignEnd: moment(element.closingTime).format('DD-MM-YYYY').toString(),
            discountPercent: element.discountPercent,
            successFee: element.successFee
          };
          if (element.distributors) {
            element.distributors.forEach(e2 => {
              aa[e2.distributors_name + " Discount"] = e2.percentage;
              aa[e2.distributors_name + " SuccessFee"] = e2.successFee;
            });
          }
          list.push(aa);
        });
        this.setState({ disableExport : false, campaignList : list });
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      this.fetchData();
    }
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getMonthText = last_transaction => {
    return last_transaction <= 1 ? `${last_transaction} Month` : `${last_transaction} Months`
  }

  setShowCampaignModal = (campaign) => {
    this.setState({ showCampaignModal: true, selectedCampaign: campaign });
  }

  setCloseCampaignModal = () => {
    this.setState({ showCampaignModal: false, selectedCampaign: {} });
  }

  setPauseCamapignModal = (value) => {
    this.setState({ showPauseCampaignModal: value });
    if (!value) {
      this.getSettings();
    }
  }

  columns = [{
    dataField: 'actions',
    isDummyField: true,
    text: 'Actions',
    // style: { whiteSpace: 'nowrap' },
    classes: 'vhalign',
    formatter: (cell, row) => {
      return (
        <React.Fragment>
          {/* <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-danger">delete</Icon>
          </span> */}
          {/* <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.handleEditCampaign(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-info">open_in_new</Icon>
          </span> */}
          <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }}
            onClick={() => this.setShowCampaignModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-primary" title="Pause Campaign">pause_circle_outline</Icon>
          </span>
          {row.status == "Pending" &&
            <>
              <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Approve')}  >
                <Icon style={{ fontSize: "1.5rem" }} className="text-success" data-toggle="tool-tip" title="Approve">done</Icon>
              </span>
              <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Reject')} >
                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Reject">clear</Icon>
              </span>
            </>
          }
        </React.Fragment>
      )
    }
  },
  {
    dataField: 'name',
    sort: true,
    text: 'Campaign Name',
    classes: 'vhalign'
  },
  {
    dataField: '',
    text: 'Campaign Assets',
    classes: 'vhalign',
    formatter: (col, row) => {
      return <a onClick={() => this.openAssetsModal(row, true)} style={{ textDecoration: 'underline', color: 'blue' }}> View</a >
    }
  },
  {
    dataField: '',
    text: 'Campaign Parameters',
    classes: 'vhalign',
    formatter: (col, row) => {
      // let todayDate = new Date();
      // if(todayDate.getDate() >= 25) {
      //   return <a style={{ textDecoration: 'underline', color: 'blue', cursor: 'not-allowed' }}>Edit</a >
      // } else {
      return <a onClick={() => this.showHideDistributorModal(row, true)} style={{ textDecoration: 'underline', color: 'blue' }}>Edit</a >
      // }      
    }
  },
  {
    dataField: 'status',
    sort: true,
    text: 'Status',
    classes: 'vhalign',
    formatter: (col, row) => {
      if (row.status === "Bank Approved" && row.bank_status_approvedby && this.props.bankEmployees &&
        row.bank_status_approvedby.length < this.props.bankEmployees.length) {
        return <a onClick={() => this.showBankPartialUser(row)} style={{ textDecoration: 'underline', color: 'blue' }}> Bank Approved (Partial)</a >
      } else {
        return row.status;
      }
    }
  },
  {
    dataField: 'suggestions',
    sort: true,
    text: 'Description',
    classes: 'vhalign',
    formatter: cell => {
      return cell.toLocaleString()
    }
  },
  {
    dataField: 'brandName',
    sort: true,
    text: 'Brand Name',
    classes: 'vhalign',
    formatter: cell => {
      if (cell) {
        return cell.toLocaleString()
      }
    }
  },
  {
    dataField: 'merchantNames',
    sort: true,
    text: 'Branch Names',
    classes: 'vhalign',
    formatter: cell => {
      if (cell) {
        return cell.toLocaleString()
      }
    }
  },
  {
    dataField: 'customerType',
    sort: true,
    text: 'Customer Type',
    classes: 'vhalign',
    formatter: (col, row) => {
      return col === 'new' ? 'New Customer' : `Existing Customer (${this.getMonthText(row.lastTransaction)})`
    }
  },
  {
    dataField: 'discountType',
    sort: true,
    text: 'Discount Type',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      switch (cell) {
        case 'dp': {
          return 'Flat Percentage'
        }
        case 'dv': {
          return 'Discount Value'
        }
        case 'ss': {
          return 'Stretch Spend'
        }
        default: return ''
      }
    }
  },
  {
    dataField: 'discountMinAmount',
    sort: true,
    isDummyField: true,
    text: 'Minimum Spend',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      switch (row.discount_type) {
        case 'dp': {
          return 'none'
        }
        case 'dv': {
          return <span> {row.discount_amount} {getCurrency()}</span>
        }
        case 'ss': {
          return (
            <span> {row.discount_spread_amount}% <br /> <small>Avg. Spend : {row.spread_avg_spend} {getCurrency()}</small></span>
          )
        }
        default: return 'none'
      }
    }
  },
  // {
  //   dataField: 'discountPercent',
  //   sort: true,
  //   isDummyField: true,
  //   text: 'Discount',
  //   classes: 'vhalign',
  //   formatter: (cell, row, rowIndex) => {
  //     switch (row.discountType) {
  //       case 'dp': {
  //         return row.discountPercent && <span>{row.discountPercent}%</span>
  //       }
  //       case 'dv': {
  //         return <span> {row.discountValue} {getCurrency()}</span>
  //       }
  //       case 'ss': {
  //         return (
  //           <span> {row.discount_spread_value}% </span>
  //         )
  //       }
  //       default: return 'none'
  //     }
  //   }
  // },
  // {
  //   dataField: 'successFee',
  //   sort: true,
  //   text: 'Success Fee',
  //   classes: 'vhalign',
  //   formatter: (cell, row) => {
  //     if (cell)
  //       return cell + '%'
  //     return cell
  //   }
  // },
  {
    dataField: 'openingTime',
    sort: true,
    text: 'Start Date',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      return moment(cell).format('DD/MM/YYYY')
    }
  },
  {
    dataField: 'closingTime',
    sort: true,
    text: 'End Date',
    classes: 'vhalign',
    formatter: (cell, row, rowIndex) => {
      return moment(cell).subtract(1, 'days').format('DD/MM/YYYY')
    }
  }
  ]

  openAssetsModal = (row, status) => {
    this.setState({
      assetsModel: {
        data: row, isOpen: status
      }
    })
  }
  closeAssetsModal = () => {
    this.setState({
      assetsModel: {
        data: {}, isOpen: false
      }
    })
  }

  setShowDeleteModal = (campaign) => {
    this.setState({ showDeleteModal: true, selectedCampaign: campaign });
  }

  showBankPartialUser = (row) => {
    this.setState({
      bankPartialUserModal: {
        showBankPartialUserModal: true, selectedEntity: row
      }
    })
  }

  onCloseBankPartialUser = () => {
    this.setState({
      bankPartialUserModal: {
        selectedEntity: null, showBankPartialUserModal: false
      }
    })
  }

  handleDeleteCampaign = () => {
    let { deleteCampaign, successNotification } = this.props;
    let { selectedCampaign: campaign } = this.state;
    deleteCampaign(campaign, (err) => {
      if (err) {
        return
      }
      this.setState({ showDeleteModal: false, selectedCampaign: null }, () => {
        // show notification
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = 'Campaign deleted successfully'
          successNotification(notification)
        })
      });
      this.fetchData(this.props.page)
    })
  }

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  }

  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { sizePerPage } = this.pagination;
    return parseInt(totalSize / sizePerPage) + (totalSize % sizePerPage ? 1 : 0)
  }

  handleEditCampaign = (campaign) => {
    let { setCampaignForEdit } = this.props;
    setCampaignForEdit(campaign)
    this.props.history.push(`/user/campaigns/edit`)
  }

  handleEditCampaignOverview = campaign => {
    this.props.history.push(`/user/campaign-overview/edit`)
  }

  showHideDistributorModal = (row, modelState) => {
    this.setState({
      distributorModalData: {
        isOpenDistributorModal: modelState, selectedEntity: modelState ? row : {}
      }
    })
  }

  getDistributor = () => {
    let url = "entity/getDistributor";
    let { getDistributor } = this.props;
    getDistributor(url, (res) => {
      let list = [];
      res.forEach(element => {
        list.push({ _id: element._id, distributors_name: element.distributors_name, distributors_display_name: element.distributors_display_name });
      });
      this.setState({ allDistributors: list })
    })
  }

  saveDistributor = (data) => {
    let { updateDistributor, page, successNotification } = this.props;
    // let { selectedEntity, approvalAction } = this.state;
    let body = {
      _id: data.rowData.selectedEntity._id,
      distributors: data.distibutorList,
      updataParam: data.updataParam
    };
    let url = "campaigns/updateDistributor";
    updateDistributor(body, url, (res, type) => {
      this.showHideDistributorModal({}, false);
      this.fetchData(page);
      setTimeout(() => {
        let notification = { ...notificationOpts }
        if (type == 'error') {
          notification.message = res
        } else {
          notification.message = `Campaign Parameters updated successfully`;
        }
        successNotification(notification)
      })
    })
  }

  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters }) => {
    let {
      setFutureCampaignsPage,
      setFutureCampaignsSort,
      setFutureCampaignsSearch,
      setFutureCampaignsFilters,
      getFutureCampaignData
    } = this.props;
    let { page, limit } = this.pagination
    let { searchField } = this.state;
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    let customerType = this.props.user.customerType
    let status = ""
    if (this.props.user.role == Roles.bank) {
      status = FutureCampaignRole
    }
    switch (type) {
      case 'pagination': {
        setFutureCampaignsPage(page, sizePerPage)
        break;
      }
      case 'sort': {
        let sortCount = this.state.sortCount
        sortCount = sortCount == 0 ? sortCount + 1 : 0;
        filterCriteria.sortField = {
          direction: sortCount == 0 ? "desc" : 'asc',
          sortfield: sortField,
        }
        this.setState({ sortCount: sortCount })
        let customerType = this.props.user.customerType
        getFutureCampaignData(page, limit, filterCriteria, 'sort', customerType, status, (res, pagination) => {
        })
        break
      }
      case 'search': {
        filterCriteria.globalSearch = {
          value: searchText,
          type: 'user'
        }
        getFutureCampaignData(page, limit, filterCriteria, 'globalsearch', customerType, status, (res, pagination) => {
        })
        break
      }
      case 'filter': {
        setFutureCampaignsFilters({ ...filters })
        setFutureCampaignsPage(1, sizePerPage)
        break
      }
      default:
        return
    }
  }

  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  handleDateChange = (name, value) => {
    this.handleTableChange('filter', { filters: { [`${name}`]: value ? value.toISOString() : '' } })
  }

  getCurrentPageTotalPage = paginationProps => {
    let { page } = paginationProps
    let totalPages = this.getPageCount(paginationProps)
    if (!totalPages)
      return `0/0`;
    return `${page}/${totalPages}`
  }

  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }

  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  onPageChange = async (page) => {
    this.pagination.page = page
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-1" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content">
          {
            options.map((option) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )

  onCloseApproveModal = () => {
    this.setState({ showApproveModal: false, selectedEntity: null, approvalAction: null })
  }

  formikRef = async (e) => {
    console.log(e)
    this.formik = e
    if (this.formik) {
      if (this.formik.uploadProps) {
        await this.setState({
          isSubmitDisabled: this.formik.uploadProps.isDisabled
        })
        console.log(this.formik.isDisabled)
      }
    }
  }

  handleConfirmMerchant = (values, actions) => {
    // console.log(values, actions)
    let { confirmMerchant, page, successNotification, entityType } = this.props;
    let { selectedEntity, approvalAction } = this.state
    values.status = approvalAction == 'Approve' ? "Bank Approval Pending" : "Krowd Rejected"
    values._id = selectedEntity._id
    // let url = approvalAction == 'Approve' ? `users/approveMerchantId` : approvalAction == 'Reject' ? `users/approveMerchantId` : ''
    let url = "campaigns/updateStatus"
    confirmMerchant(values, url, (res, type) => {
      // notification
      this.onCloseApproveModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        if (type == 'error') {
          notification.message = res
        } else {
          notification.message = `Campaign ${approvalAction == 'Approve' ? 'approved' : 'rejected'} successfully`
        }
        successNotification(notification)
      })
    })
  }

  setApproveModal = (selectedEntity, type) => {
    if (type == "Approve" && selectedEntity && (!selectedEntity.distributors || selectedEntity.distributors.length == 0)) {
      let { errorNotification } = this.props;
      let notification = { ...notificationOpts }
      notification.message = "Please update discount percentage for each distributor before approval.";
      errorNotification(notification);
      return;
    }
    this.setState({ showApproveModal: true, selectedEntity, approvalAction: type })
  }

  submitForm = () => {
    if (this.formik)
      this.formik.submitForm()
  }

  exportCampaigns = () => {

    const workbook = XLSX.utils.book_new();

    let exportData = [];
    this.state.campaignList.forEach(element => {

      exportData.push({
        "Brand Name": element.brandName,
        "Campaign Date": element.campaignStart,
        // "Campaign End": element.campaignEnd,
        "Discount Percent": element.discountPercent,
        "SuccessFee": element.successFee,
        "ATR Discount": element["ATR Discount"],
        "ATR SuccessFee": element["ATR SuccessFee"],
        "Amex Discount": element["Amex Discount"],
        "Amex SuccessFee": element["Amex SuccessFee"],
        "BA Discount": element["BA Discount"],
        "BA SuccessFee": element["BA SuccessFee"],
        "Curve Discount": element["Curve Discount"],
        "Curve SuccessFee": element["Curve SuccessFee"],
        "Fidel Discount": element["Fidel Discount"],
        "Fidel SuccessFee": element["Fidel SuccessFee"],
        "LUX Discount": element["LUX Discount"],
        "LUX SuccessFee": element["LUX SuccessFee"],
        "Revolut Discount": element["Revolut Discount"],
        "Revolut SuccessFee": element["Revolut SuccessFee"],
        "Zilch Discount": element["Zilch Discount"],
        "Zilch SuccessFee": element["Zilch SuccessFee"]
      });

    });
    const sheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, sheet, "Campaign");
    XLSX.writeFile(workbook, "futureCampaignDetails.xlsx");
  }

  render() {
    const { showDeleteModal, selectedCampaign, approvalAction, assetsModel, bankPartialUserModal, distributorModalData, allDistributors, pausedDateWithPublisher } = this.state;
    const { totalCount, filters, campaigns, isLoading, bankEmployees } = this.props;
    let thisMonth = moment().startOf('month')
    let { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    if (this.props.user.role == Roles.bank) {
      this.columns = this.columns.filter(res => !["actions"].includes(res.dataField))
    }
    return (
      <div>
        <Loader loader={isLoading} />
        <ModalComponent
          close={this.onCloseDeleteModal}
          callback={this.handleDeleteCampaign}
          show={showDeleteModal}
          title={"Delete Camapaign"}
          message={<span>Are you sure you want to delete the <b>"{selectedCampaign ? selectedCampaign.name : ''}"</b> campaign ?</span>}
          action={"Delete"}
          actionType='danger'
        />
        <ModalComponent close={() => this.closeAssetsModal()}
          callback={() => this.closeAssetsModal()}
          show={assetsModel.isOpen}
          title={<span><b>Campaign Assets </b></span>}
          message={<CampaignAssets data={assetsModel.data} />}
          hideCancelBtn={true}
          action={"Close"}
          actionType={'danger'}
          size={"lg"}
          key="assets"
        />

        <BankPartialUserModal close={() => this.onCloseBankPartialUser()}
          show={bankPartialUserModal.showBankPartialUserModal}
          bankPartialUserModal={bankPartialUserModal}
          bankEmployees={bankEmployees}
          key="bankPartialUser"
        />

        <DistributorModal close={() => this.showHideDistributorModal({}, false)}
          show={distributorModalData.isOpenDistributorModal}
          callback={this.saveDistributor}
          distributorModalData={distributorModalData}
          allDistributors={allDistributors}
          key="distributor"
        />

        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">fast_forward</Icon>
                    </span> Future Campaigns
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder="Search Campaign Name" />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 offset-7 d-none">
                    <button className="btn pull-right btn-outline-primary" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <i className="fa fa-filter"></i>
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Collapse
                      in={this.state.showFilter}
                    >
                      <div className="card">
                        <div className="content">
                          <div className="form-group mb-3">
                            <label>Date Range </label>
                            <br />
                            <div className="row">
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrBefore(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('start_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.start_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrAfter(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('end_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.end_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
              <div className="content mb-3">

                <div className="row">
                  <div className="col-md-4">
                    <button className="btn btn-primary mb-3 m-l-5" style={{ verticalAlign: 'bottom' }}
                      onClick={() => { this.setPauseCamapignModal(true) }} >
                      Pause All Campaign
                    </button>

                    <button className={`btn btn-primary mb-3 m-l-5`} disabled={this.state.disableExport} onClick={() => { this.exportCampaigns() }} >
                      Export
                    </button>
                  </div>
                </div>

                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={campaigns}
                  columns={this.columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />
              </div>
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />
              <ModalComponent close={this.onCloseApproveModal}
                callback={this.submitForm}
                show={this.state.showApproveModal}
                title={`${approvalAction == 'Approve' ? "Approve Campaign" : "Reject Campaign"}`}
                message={<MerchantCommentsForm
                  formikRef={this.formikRef}
                  onSubmit={this.handleConfirmMerchant}
                  prefix={"Campaign"}
                />}
                // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                action={"Confirm"}
                actionType={approvalAction == 'Approve' ? 'success' : 'danger'}
              />
              <ModalComponent
                centered={false}
                close={this.setCloseCampaignModal}
                show={this.state.showCampaignModal}
                size="xl"
                title={`Edit details of child campaign (${selectedCampaign.name})`}
                message={
                  <CampaignSettings campaign={selectedCampaign} allDistributors={allDistributors} close={this.setCloseCampaignModal} />
                }
                actionType={null}
                hideCancelBtn={true}
              />

              {this.state.showPauseCampaignModal &&
                <ModalComponent
                  centered={false}
                  close={() => { this.setPauseCamapignModal(false); }}
                  show={this.state.showPauseCampaignModal}
                  size="lg"
                  title={`Pause Campaign`}
                  message={
                    <PuaseCampaignGlobal pausedDateWithPublisher={pausedDateWithPublisher} allDistributors={allDistributors} close={() => { this.setPauseCamapignModal(false) }} />
                  }
                  actionType={null}
                  hideCancelBtn={true}
                />
              }


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    campaigns: state.campaigns.futureCampaigns.campaigns,
    sample: state,
    isLoading: state.campaigns.futureCampaigns.isLoading,
    page: state.campaigns.futureCampaigns.page,
    totalCount: state.campaigns.futureCampaigns.totalCount,
    sizePerPage: state.campaigns.futureCampaigns.sizePerPage,
    filters: state.campaigns.futureCampaigns.filters,
    user: state.user,
    bankEmployees: state.campaigns.futureCampaigns.bankEmployees,
  }),
  dispatch => ({
    getFutureCampaignData: bindActionCreators(getCampaignAction('future'), dispatch),
    deleteCampaign: bindActionCreators(deleteCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    setFutureCampaignsPage: bindActionCreators(setCampaignsPageAction('future'), dispatch),
    setFutureCampaignsSort: bindActionCreators(setCampaignsSortAction('future'), dispatch),
    setFutureCampaignsSearch: bindActionCreators(setCampaignsSearchAction('future'), dispatch),
    setFutureCampaignsFilters: bindActionCreators(setCampaignsFiltersAction('future'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    confirmMerchant: bindActionCreators(confirmMerchantAction, dispatch),
    getDistributor: bindActionCreators(getDistributorAction, dispatch),
    updateDistributor: bindActionCreators(updateDistributorAction, dispatch),
    getSettingDetail: bindActionCreators(getSettingDetailAction, dispatch),
    getAllCampaign: bindActionCreators(getAllCampaignAction, dispatch),
  })
)(FutureCampaign);
